.news-view {
    &__header {

    }

    &__title {
        margin-top: rem-calc(60);

        .section__title {
            color: $secondary-color;
            margin-bottom: rem-calc(15);
        }
    }

    &__date {
        display: block;
    }

    &__tags {
        display: block;

        &--item {
            background: $blue-dark;
            color: $white;
            margin: rem-calc(10 10 10 0);
            padding: rem-calc(2 10);
            text-transform: uppercase;
        }
    }

    &__share {
        float: right;
        margin-top: rem-calc(30);

        a {
            color: $blue-dark;
            font-size: rem-calc(45);
            padding-left: rem-calc(10);
            padding-right: rem-calc(10);

            &:hover {
                color: $secondary-color;
            }
        }
    }

    &__picture {
        padding: 0;

        img {
            width: 100%;
        }

        &::after,
        &::before {
            background: $white;
        }
    }


    &__content {

    }

    &__back {
        padding: rem-calc(60 0);

        a {
            color: $primary-color;
            font-weight: bold;
            text-transform: uppercase;

            &:hover {
                color: $secondary-color;
            }

        }
    }
}
