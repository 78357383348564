form {
    @include autofill-color($black);

    [type='text'],
    [type='password'],
    [type='date'],
    [type='datetime'],
    [type='datetime-local'],
    [type='month'],
    [type='week'],
    [type='email'],
    [type='number'],
    [type='search'],
    [type='tel'],
    [type='time'],
    [type='url'],
    [type='color'],
    select,
    textarea {
        box-shadow: none;
        display: block;
        font-size: rem-calc(14);
        margin-bottom: 0;
    }

    .custom-radio,
    .custom-checkbox {
        display: inline-block;
        margin-left: rem-calc(15);

        .outer {
            background: $white;
            border: 1px solid $light-gray;
            display: inline-block;
            height: rem-calc(34);
            margin-right: rem-calc(10);
            padding: rem-calc(3);
            vertical-align: middle;
            width: rem-calc(34);
        }

        .inner {
            background: $primary-color;
            display: inline-block;
            height: rem-calc(26);
            opacity: 0;
            transition: opacity 300ms ease;
            width: rem-calc(26);
        }

        input {
            opacity: 0;
            position: absolute;
            text-indent: -9999px;

            &:checked ~ label .outer .inner {
                opacity: 1;
            }

            &:focus ~ label .outer {
                box-shadow: 0 0 10px $primary-color;
            }

        }

        label {
            display: inline-block;
            margin: 0;
            vertical-align: middle;
        }


        &.small {
            .outer {
                height: rem-calc(24);
                padding: rem-calc(1 3);
                width: rem-calc(24);

                .inner {
                    height: rem-calc(16);
                    width: rem-calc(16);
                }
            }
        }
    }

    .custom-radio {
        .outer,
        .inner {
            border-radius: rem-calc(30);
        }

        .outer {
            height: rem-calc(24);
            padding: rem-calc(2);
            width: rem-calc(24);
        }

        .inner {
            height: rem-calc(18);
            width: rem-calc(18);
        }
    }

    .form-row {
        margin-bottom: rem-calc(10);

        & > div > .text-right {
            @include breakpoint(small down) {
                text-align: left;
            }
        }

        input {
            &[type='text'],
            &[type='email'],
            &[type='tel'],
            &[type='password'] {
                height: rem-calc(32);
            }
        }

        label {
            margin-right: rem-calc(10);
        }

        & > .custom-checkbox {
            margin: rem-calc(10 0);
        }

        .custom-select {
            position: relative;

            &::after {
                background: $primary-color no-repeat 50% 50%;
                background-image: url('../images/layout/arrow-down-white.svg');
                background-size: rem-calc(20);
                content: "";
                height: rem-calc(32);
                position: absolute;
                right: 0;
                top: 0;
                width: rem-calc(32);
                pointer-events: none;
            }

            select {
                background: $white;
                border-radius: 0;
                height: rem-calc(32);
                margin-bottom: 0;
                padding: rem-calc(0 36 0 10);
            }

            dt {
                border: 1px solid $gray;
                font-weight: normal;
                height: rem-calc(32);
                line-height: rem-calc(32);
                margin-bottom: 0;
                padding-left: rem-calc(10);

                .multiSel {
                    color: $black;
                }
            }

            .dropdown {
                margin-bottom: 0;
            }

            .dropdown dd {

                .slide {
                    background-color: $white;
                    border-bottom: 1px solid $gray;
                    border-left: 1px solid $gray;
                    border-right: 1px solid $gray;
                    display: none;
                    overflow: auto;
                    padding: 2px 15px 2px 5px;
                    position: absolute;
                    width: 100%;
                    z-index: 10;

                    .custom-checkbox {
                        display: block;
                        margin-left: 0;
                    }
                }
            }

            .custom-checkbox {
                margin-left: 0;
            }
        }

        textarea {
            box-shadow: none;
            margin-bottom: 0;
        }
    }

    .form-filters select {
        background-image: url('../images/layout/arrow-down.svg');
        background-position: calc(100% + 26px)  55%;
        background-size: rem-calc(20);
        height: rem-calc(50);
        padding: rem-calc(10 36 10 20);
    }

    .button-group {
        display: table;
        margin: 0;
        table-layout: fixed;
        width: calc(100%);

        &::after,
        &::before {
            content: none;
        }

        input {
            display: none;
        }

        & > div {
            display: table-cell;
            vertical-align: middle;

            &:first-child {
                padding-right: rem-calc(2);
            }

            &:last-child {
                padding-left: rem-calc(2);
            }
        }

        label {
            border: 1px solid $gray;
            color: $black;
            font-weight: normal;
            letter-spacing: 0;
            margin: 0;
            padding: rem-calc(9 10);
            text-transform: none;
            width: 100%;

            &:last-of-type {
                margin-right: 0;
            }
        }

        input {
            &:checked {
                & + label {

                    &,
                    &:active {
                        background-color: $primary-color;
                        color: $white;
                    }
                }
            }
        }
    }

    .form-recaptcha {
        margin: rem-calc(30 0 15);
        text-align: center;

        .g-recaptcha {
            display: inline-block;
        }
    }

    .form-submit {
        text-align: center;

        .button {
            width: rem-calc(304);
        }
    }

    .form-error {
        display: block;
        margin-bottom: 0;

        ul {
            list-style: none;
            margin: 0;
            padding: rem-calc(10 0);
        }
    }
}
