body {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        border: 0;
        font: inherit;
        font-size: 100%;
        margin: 0;
        padding: 0;
        vertical-align: baseline;
    }
}

.h1,
.h2,
.h3,
.h4 ,
.h5,
.h6 {
    font-family: $header-font-family;
    font-weight: $header-font-weight;
}

.wysiwyg-content {
    figure,
    ul,
    p {
        margin-bottom: rem-calc(30);
    }

    h2 {
        font-size: rem-calc(40);
        font-weight: bold;
        line-height: 1em;
        margin: rem-calc(40 0);
    }

    h3 {
        font-size: rem-calc(30);
        font-weight: bold;
        line-height: 1em;
        margin: rem-calc(30 0);
    }

    h4 {
        font-size: rem-calc(20);
        font-weight: bold;
        line-height: 1em;
        margin: rem-calc(20 0);
    }

    .aligncenter {
        display: block;
        margin: auto;
    }

    .alignleft {
        float: left;
        margin-bottom: rem-calc(3px);
        margin-right: rem-calc(10px);
    }

    blockquote p {
        color: $white;
    }

    .wp-caption-text {
        color: $primary-color;
        font-size: rem-calc(14);
        font-style: italic;
        margin: rem-calc(25 0);
    }
}
