.institution {
    &__slideshow {
        padding-bottom: 0;
    }

    &__title {
        color: $secondary-color;
        font-size: rem-calc(50);
        font-weight: 300;
        margin-bottom: rem-calc(30);
    }

    &__header {
        background: $blue-light;
        font-size: rem-calc(15);
    }

    &__contact {
        background: $white;

        .button.margin {
            margin-bottom: rem-calc(60);
        }


        .tabs {
            border: 0;
            margin: rem-calc(30 0);
            text-align: center;

            &-title {
                display: inline-block;
                float: none;
                margin: rem-calc(0 15);
            }

            &-content {
                border: 0;
            }

            &-panel {
                padding: rem-calc(30 0);
            }
        }

        .tabs-title {
            & > a {
                &:focus,
                &[aria-selected='true'] {
                    background: $primary-color;
                    color: $white;
                }
            }
        }
    }

    &__news {
        background: $blue-light;
        padding-bottom: rem-calc(60);

        .odd {
            margin-top: rem-calc(30);
        }

        &__cta {
            margin-top: rem-calc(30);
            text-align: center;
        }
    }
}
