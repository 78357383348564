@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.4.4-rc1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
@media print, screen and (min-width: 40em) {
  .reveal, .reveal.tiny, .reveal.small, .reveal.large {
    right: auto;
    left: auto;
    margin: 0 auto; } }

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-down.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-up.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-right.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-down.mui-leave.mui-leave-active {
  transform: translateY(100%); }

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%); }

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-up.mui-leave.mui-leave-active {
  transform: translateY(-100%); }

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-left.mui-leave.mui-leave-active {
  transform: translateX(-100%); }

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity; }

.fade-in.mui-enter.mui-enter-active {
  opacity: 1; }

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity; }

.fade-out.mui-leave.mui-leave-active {
  opacity: 0; }

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-up.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-down.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-up.mui-leave.mui-leave-active {
  transform: scale(1.5);
  opacity: 0; }

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-down.mui-leave.mui-leave-active {
  transform: scale(0.5);
  opacity: 0; }

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out.mui-leave.mui-leave-active {
  transform: rotate(0.75turn);
  opacity: 0; }

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in-ccw.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out-ccw.mui-leave.mui-leave-active {
  transform: rotate(-0.75turn);
  opacity: 0; }

.slow {
  transition-duration: 750ms !important; }

.fast {
  transition-duration: 250ms !important; }

.linear {
  transition-timing-function: linear !important; }

.ease {
  transition-timing-function: ease !important; }

.ease-in {
  transition-timing-function: ease-in !important; }

.ease-out {
  transition-timing-function: ease-out !important; }

.ease-in-out {
  transition-timing-function: ease-in-out !important; }

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  transition-delay: 300ms !important; }

.long-delay {
  transition-delay: 700ms !important; }

.shake {
  animation-name: shake-7; }

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%); } }

.spin-cw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn); }
  100% {
    transform: rotate(0); } }

.spin-ccw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(1turn); } }

.wiggle {
  animation-name: wiggle-7deg; }

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg); }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg); }
  0%, 30%, 70%, 100% {
    transform: rotate(0); } }

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  animation-duration: 500ms; }

.infinite {
  animation-iteration-count: infinite; }

.slow {
  animation-duration: 750ms !important; }

.fast {
  animation-duration: 250ms !important; }

.linear {
  animation-timing-function: linear !important; }

.ease {
  animation-timing-function: ease !important; }

.ease-in {
  animation-timing-function: ease-in !important; }

.ease-out {
  animation-timing-function: ease-out !important; }

.ease-in-out {
  animation-timing-function: ease-in-out !important; }

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  animation-delay: 300ms !important; }

.long-delay {
  animation-delay: 700ms !important; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/* Works with the icon sass file generated by gulp font icon */
/* Shadows */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 0.9375rem; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "Open Sans", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.grid-container {
  padding-right: 0.66667rem;
  padding-left: 0.66667rem;
  max-width: 80rem;
  margin: 0 auto; }
  @media print, screen and (min-width: 40em) {
    .grid-container {
      padding-right: 1rem;
      padding-left: 1rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-container {
      padding-right: 1.66667rem;
      padding-left: 1.66667rem; } }
  .grid-container.fluid {
    padding-right: 0.66667rem;
    padding-left: 0.66667rem;
    max-width: 100%;
    margin: 0 auto; }
    @media print, screen and (min-width: 40em) {
      .grid-container.fluid {
        padding-right: 1rem;
        padding-left: 1rem; } }
    @media print, screen and (min-width: 64em) {
      .grid-container.fluid {
        padding-right: 1.66667rem;
        padding-left: 1.66667rem; } }
  .grid-container.full {
    padding-right: 0;
    padding-left: 0;
    max-width: 100%;
    margin: 0 auto; }

.grid-x {
  display: flex;
  flex-flow: row wrap; }

.cell {
  flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%; }
  .cell.auto {
    flex: 1 1 0px; }
  .cell.shrink {
    flex: 0 0 auto; }

.grid-x > .auto {
  width: auto; }

.grid-x > .shrink {
  width: auto; }

.grid-x > .small-shrink, .grid-x > .small-full, .grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  flex-basis: auto; }

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex-basis: auto; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex-basis: auto; } }

.grid-x > .small-1 {
  width: 8.33333%; }

.grid-x > .small-2 {
  width: 16.66667%; }

.grid-x > .small-3 {
  width: 25%; }

.grid-x > .small-4 {
  width: 33.33333%; }

.grid-x > .small-5 {
  width: 41.66667%; }

.grid-x > .small-6 {
  width: 50%; }

.grid-x > .small-7 {
  width: 58.33333%; }

.grid-x > .small-8 {
  width: 66.66667%; }

.grid-x > .small-9 {
  width: 75%; }

.grid-x > .small-10 {
  width: 83.33333%; }

.grid-x > .small-11 {
  width: 91.66667%; }

.grid-x > .small-12 {
  width: 100%; }

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .medium-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .medium-1 {
    width: 8.33333%; }
  .grid-x > .medium-2 {
    width: 16.66667%; }
  .grid-x > .medium-3 {
    width: 25%; }
  .grid-x > .medium-4 {
    width: 33.33333%; }
  .grid-x > .medium-5 {
    width: 41.66667%; }
  .grid-x > .medium-6 {
    width: 50%; }
  .grid-x > .medium-7 {
    width: 58.33333%; }
  .grid-x > .medium-8 {
    width: 66.66667%; }
  .grid-x > .medium-9 {
    width: 75%; }
  .grid-x > .medium-10 {
    width: 83.33333%; }
  .grid-x > .medium-11 {
    width: 91.66667%; }
  .grid-x > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .large-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .large-1 {
    width: 8.33333%; }
  .grid-x > .large-2 {
    width: 16.66667%; }
  .grid-x > .large-3 {
    width: 25%; }
  .grid-x > .large-4 {
    width: 33.33333%; }
  .grid-x > .large-5 {
    width: 41.66667%; }
  .grid-x > .large-6 {
    width: 50%; }
  .grid-x > .large-7 {
    width: 58.33333%; }
  .grid-x > .large-8 {
    width: 66.66667%; }
  .grid-x > .large-9 {
    width: 75%; }
  .grid-x > .large-10 {
    width: 83.33333%; }
  .grid-x > .large-11 {
    width: 91.66667%; }
  .grid-x > .large-12 {
    width: 100%; } }

.grid-margin-x:not(.grid-x) > .cell {
  width: auto; }

.grid-margin-y:not(.grid-y) > .cell {
  height: auto; }

.grid-margin-x {
  margin-left: -0.66667rem;
  margin-right: -0.66667rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x {
      margin-left: -1rem;
      margin-right: -1rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-x {
      margin-left: -1.66667rem;
      margin-right: -1.66667rem; } }
  .grid-margin-x > .cell {
    width: calc(100% - 1.33333rem);
    margin-left: 0.66667rem;
    margin-right: 0.66667rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .cell {
      width: calc(100% - 2rem);
      margin-left: 1rem;
      margin-right: 1rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-x > .cell {
      width: calc(100% - 3.33333rem);
      margin-left: 1.66667rem;
      margin-right: 1.66667rem; } }
  .grid-margin-x > .auto {
    width: auto; }
  .grid-margin-x > .shrink {
    width: auto; }
  .grid-margin-x > .small-1 {
    width: calc(8.33333% - 1.33333rem); }
  .grid-margin-x > .small-2 {
    width: calc(16.66667% - 1.33333rem); }
  .grid-margin-x > .small-3 {
    width: calc(25% - 1.33333rem); }
  .grid-margin-x > .small-4 {
    width: calc(33.33333% - 1.33333rem); }
  .grid-margin-x > .small-5 {
    width: calc(41.66667% - 1.33333rem); }
  .grid-margin-x > .small-6 {
    width: calc(50% - 1.33333rem); }
  .grid-margin-x > .small-7 {
    width: calc(58.33333% - 1.33333rem); }
  .grid-margin-x > .small-8 {
    width: calc(66.66667% - 1.33333rem); }
  .grid-margin-x > .small-9 {
    width: calc(75% - 1.33333rem); }
  .grid-margin-x > .small-10 {
    width: calc(83.33333% - 1.33333rem); }
  .grid-margin-x > .small-11 {
    width: calc(91.66667% - 1.33333rem); }
  .grid-margin-x > .small-12 {
    width: calc(100% - 1.33333rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .auto {
      width: auto; }
    .grid-margin-x > .shrink {
      width: auto; }
    .grid-margin-x > .small-1 {
      width: calc(8.33333% - 2rem); }
    .grid-margin-x > .small-2 {
      width: calc(16.66667% - 2rem); }
    .grid-margin-x > .small-3 {
      width: calc(25% - 2rem); }
    .grid-margin-x > .small-4 {
      width: calc(33.33333% - 2rem); }
    .grid-margin-x > .small-5 {
      width: calc(41.66667% - 2rem); }
    .grid-margin-x > .small-6 {
      width: calc(50% - 2rem); }
    .grid-margin-x > .small-7 {
      width: calc(58.33333% - 2rem); }
    .grid-margin-x > .small-8 {
      width: calc(66.66667% - 2rem); }
    .grid-margin-x > .small-9 {
      width: calc(75% - 2rem); }
    .grid-margin-x > .small-10 {
      width: calc(83.33333% - 2rem); }
    .grid-margin-x > .small-11 {
      width: calc(91.66667% - 2rem); }
    .grid-margin-x > .small-12 {
      width: calc(100% - 2rem); }
    .grid-margin-x > .medium-auto {
      width: auto; }
    .grid-margin-x > .medium-shrink {
      width: auto; }
    .grid-margin-x > .medium-1 {
      width: calc(8.33333% - 2rem); }
    .grid-margin-x > .medium-2 {
      width: calc(16.66667% - 2rem); }
    .grid-margin-x > .medium-3 {
      width: calc(25% - 2rem); }
    .grid-margin-x > .medium-4 {
      width: calc(33.33333% - 2rem); }
    .grid-margin-x > .medium-5 {
      width: calc(41.66667% - 2rem); }
    .grid-margin-x > .medium-6 {
      width: calc(50% - 2rem); }
    .grid-margin-x > .medium-7 {
      width: calc(58.33333% - 2rem); }
    .grid-margin-x > .medium-8 {
      width: calc(66.66667% - 2rem); }
    .grid-margin-x > .medium-9 {
      width: calc(75% - 2rem); }
    .grid-margin-x > .medium-10 {
      width: calc(83.33333% - 2rem); }
    .grid-margin-x > .medium-11 {
      width: calc(91.66667% - 2rem); }
    .grid-margin-x > .medium-12 {
      width: calc(100% - 2rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-x > .auto {
      width: auto; }
    .grid-margin-x > .shrink {
      width: auto; }
    .grid-margin-x > .small-1 {
      width: calc(8.33333% - 3.33333rem); }
    .grid-margin-x > .small-2 {
      width: calc(16.66667% - 3.33333rem); }
    .grid-margin-x > .small-3 {
      width: calc(25% - 3.33333rem); }
    .grid-margin-x > .small-4 {
      width: calc(33.33333% - 3.33333rem); }
    .grid-margin-x > .small-5 {
      width: calc(41.66667% - 3.33333rem); }
    .grid-margin-x > .small-6 {
      width: calc(50% - 3.33333rem); }
    .grid-margin-x > .small-7 {
      width: calc(58.33333% - 3.33333rem); }
    .grid-margin-x > .small-8 {
      width: calc(66.66667% - 3.33333rem); }
    .grid-margin-x > .small-9 {
      width: calc(75% - 3.33333rem); }
    .grid-margin-x > .small-10 {
      width: calc(83.33333% - 3.33333rem); }
    .grid-margin-x > .small-11 {
      width: calc(91.66667% - 3.33333rem); }
    .grid-margin-x > .small-12 {
      width: calc(100% - 3.33333rem); }
    .grid-margin-x > .medium-auto {
      width: auto; }
    .grid-margin-x > .medium-shrink {
      width: auto; }
    .grid-margin-x > .medium-1 {
      width: calc(8.33333% - 3.33333rem); }
    .grid-margin-x > .medium-2 {
      width: calc(16.66667% - 3.33333rem); }
    .grid-margin-x > .medium-3 {
      width: calc(25% - 3.33333rem); }
    .grid-margin-x > .medium-4 {
      width: calc(33.33333% - 3.33333rem); }
    .grid-margin-x > .medium-5 {
      width: calc(41.66667% - 3.33333rem); }
    .grid-margin-x > .medium-6 {
      width: calc(50% - 3.33333rem); }
    .grid-margin-x > .medium-7 {
      width: calc(58.33333% - 3.33333rem); }
    .grid-margin-x > .medium-8 {
      width: calc(66.66667% - 3.33333rem); }
    .grid-margin-x > .medium-9 {
      width: calc(75% - 3.33333rem); }
    .grid-margin-x > .medium-10 {
      width: calc(83.33333% - 3.33333rem); }
    .grid-margin-x > .medium-11 {
      width: calc(91.66667% - 3.33333rem); }
    .grid-margin-x > .medium-12 {
      width: calc(100% - 3.33333rem); }
    .grid-margin-x > .large-auto {
      width: auto; }
    .grid-margin-x > .large-shrink {
      width: auto; }
    .grid-margin-x > .large-1 {
      width: calc(8.33333% - 3.33333rem); }
    .grid-margin-x > .large-2 {
      width: calc(16.66667% - 3.33333rem); }
    .grid-margin-x > .large-3 {
      width: calc(25% - 3.33333rem); }
    .grid-margin-x > .large-4 {
      width: calc(33.33333% - 3.33333rem); }
    .grid-margin-x > .large-5 {
      width: calc(41.66667% - 3.33333rem); }
    .grid-margin-x > .large-6 {
      width: calc(50% - 3.33333rem); }
    .grid-margin-x > .large-7 {
      width: calc(58.33333% - 3.33333rem); }
    .grid-margin-x > .large-8 {
      width: calc(66.66667% - 3.33333rem); }
    .grid-margin-x > .large-9 {
      width: calc(75% - 3.33333rem); }
    .grid-margin-x > .large-10 {
      width: calc(83.33333% - 3.33333rem); }
    .grid-margin-x > .large-11 {
      width: calc(91.66667% - 3.33333rem); }
    .grid-margin-x > .large-12 {
      width: calc(100% - 3.33333rem); } }

.grid-padding-x .grid-padding-x {
  margin-right: -0.66667rem;
  margin-left: -0.66667rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-x .grid-padding-x {
      margin-right: -1rem;
      margin-left: -1rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-padding-x .grid-padding-x {
      margin-right: -1.66667rem;
      margin-left: -1.66667rem; } }

.grid-container:not(.full) > .grid-padding-x {
  margin-right: -0.66667rem;
  margin-left: -0.66667rem; }
  @media print, screen and (min-width: 40em) {
    .grid-container:not(.full) > .grid-padding-x {
      margin-right: -1rem;
      margin-left: -1rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-container:not(.full) > .grid-padding-x {
      margin-right: -1.66667rem;
      margin-left: -1.66667rem; } }

.grid-padding-x > .cell {
  padding-right: 0.66667rem;
  padding-left: 0.66667rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-x > .cell {
      padding-right: 1rem;
      padding-left: 1rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-padding-x > .cell {
      padding-right: 1.66667rem;
      padding-left: 1.66667rem; } }

.small-up-1 > .cell {
  width: 100%; }

.small-up-2 > .cell {
  width: 50%; }

.small-up-3 > .cell {
  width: 33.33333%; }

.small-up-4 > .cell {
  width: 25%; }

.small-up-5 > .cell {
  width: 20%; }

.small-up-6 > .cell {
  width: 16.66667%; }

.small-up-7 > .cell {
  width: 14.28571%; }

.small-up-8 > .cell {
  width: 12.5%; }

@media print, screen and (min-width: 40em) {
  .medium-up-1 > .cell {
    width: 100%; }
  .medium-up-2 > .cell {
    width: 50%; }
  .medium-up-3 > .cell {
    width: 33.33333%; }
  .medium-up-4 > .cell {
    width: 25%; }
  .medium-up-5 > .cell {
    width: 20%; }
  .medium-up-6 > .cell {
    width: 16.66667%; }
  .medium-up-7 > .cell {
    width: 14.28571%; }
  .medium-up-8 > .cell {
    width: 12.5%; } }

@media print, screen and (min-width: 64em) {
  .large-up-1 > .cell {
    width: 100%; }
  .large-up-2 > .cell {
    width: 50%; }
  .large-up-3 > .cell {
    width: 33.33333%; }
  .large-up-4 > .cell {
    width: 25%; }
  .large-up-5 > .cell {
    width: 20%; }
  .large-up-6 > .cell {
    width: 16.66667%; }
  .large-up-7 > .cell {
    width: 14.28571%; }
  .large-up-8 > .cell {
    width: 12.5%; } }

.grid-margin-x.small-up-1 > .cell {
  width: calc(100% - 1.33333rem); }

.grid-margin-x.small-up-2 > .cell {
  width: calc(50% - 1.33333rem); }

.grid-margin-x.small-up-3 > .cell {
  width: calc(33.33333% - 1.33333rem); }

.grid-margin-x.small-up-4 > .cell {
  width: calc(25% - 1.33333rem); }

.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 1.33333rem); }

.grid-margin-x.small-up-6 > .cell {
  width: calc(16.66667% - 1.33333rem); }

.grid-margin-x.small-up-7 > .cell {
  width: calc(14.28571% - 1.33333rem); }

.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 1.33333rem); }

@media print, screen and (min-width: 40em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.33333rem); }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.33333rem); }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 1.33333rem); }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.33333rem); }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.33333rem); }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 1.33333rem); }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 1.33333rem); }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.33333rem); }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 2rem); }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 2rem); }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.33333% - 2rem); }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 2rem); }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 2rem); }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.66667% - 2rem); }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.28571% - 2rem); }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 2rem); } }

@media print, screen and (min-width: 64em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.33333rem); }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 2rem); }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.33333rem); }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 2rem); }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 1.33333rem); }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.33333% - 2rem); }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.33333rem); }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 2rem); }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.33333rem); }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 2rem); }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 1.33333rem); }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.66667% - 2rem); }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 1.33333rem); }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.28571% - 2rem); }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.33333rem); }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 2rem); }
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 3.33333rem); }
  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 3.33333rem); }
  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.33333% - 3.33333rem); }
  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 3.33333rem); }
  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 3.33333rem); }
  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.66667% - 3.33333rem); }
  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.28571% - 3.33333rem); }
  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 3.33333rem); } }

.small-margin-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0; }
  .small-margin-collapse > .small-1 {
    width: 8.33333%; }
  .small-margin-collapse > .small-2 {
    width: 16.66667%; }
  .small-margin-collapse > .small-3 {
    width: 25%; }
  .small-margin-collapse > .small-4 {
    width: 33.33333%; }
  .small-margin-collapse > .small-5 {
    width: 41.66667%; }
  .small-margin-collapse > .small-6 {
    width: 50%; }
  .small-margin-collapse > .small-7 {
    width: 58.33333%; }
  .small-margin-collapse > .small-8 {
    width: 66.66667%; }
  .small-margin-collapse > .small-9 {
    width: 75%; }
  .small-margin-collapse > .small-10 {
    width: 83.33333%; }
  .small-margin-collapse > .small-11 {
    width: 91.66667%; }
  .small-margin-collapse > .small-12 {
    width: 100%; }
  @media print, screen and (min-width: 40em) {
    .small-margin-collapse > .medium-1 {
      width: 8.33333%; }
    .small-margin-collapse > .medium-2 {
      width: 16.66667%; }
    .small-margin-collapse > .medium-3 {
      width: 25%; }
    .small-margin-collapse > .medium-4 {
      width: 33.33333%; }
    .small-margin-collapse > .medium-5 {
      width: 41.66667%; }
    .small-margin-collapse > .medium-6 {
      width: 50%; }
    .small-margin-collapse > .medium-7 {
      width: 58.33333%; }
    .small-margin-collapse > .medium-8 {
      width: 66.66667%; }
    .small-margin-collapse > .medium-9 {
      width: 75%; }
    .small-margin-collapse > .medium-10 {
      width: 83.33333%; }
    .small-margin-collapse > .medium-11 {
      width: 91.66667%; }
    .small-margin-collapse > .medium-12 {
      width: 100%; } }
  @media print, screen and (min-width: 64em) {
    .small-margin-collapse > .large-1 {
      width: 8.33333%; }
    .small-margin-collapse > .large-2 {
      width: 16.66667%; }
    .small-margin-collapse > .large-3 {
      width: 25%; }
    .small-margin-collapse > .large-4 {
      width: 33.33333%; }
    .small-margin-collapse > .large-5 {
      width: 41.66667%; }
    .small-margin-collapse > .large-6 {
      width: 50%; }
    .small-margin-collapse > .large-7 {
      width: 58.33333%; }
    .small-margin-collapse > .large-8 {
      width: 66.66667%; }
    .small-margin-collapse > .large-9 {
      width: 75%; }
    .small-margin-collapse > .large-10 {
      width: 83.33333%; }
    .small-margin-collapse > .large-11 {
      width: 91.66667%; }
    .small-margin-collapse > .large-12 {
      width: 100%; } }

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .small-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .small-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .small-3 {
    width: 25%; }
  .medium-margin-collapse > .small-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .small-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .small-6 {
    width: 50%; }
  .medium-margin-collapse > .small-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .small-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .small-9 {
    width: 75%; }
  .medium-margin-collapse > .small-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .small-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .medium-3 {
    width: 25%; }
  .medium-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .medium-6 {
    width: 50%; }
  .medium-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .medium-9 {
    width: 75%; }
  .medium-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .medium-margin-collapse > .large-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .large-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .large-3 {
    width: 25%; }
  .medium-margin-collapse > .large-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .large-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .large-6 {
    width: 50%; }
  .medium-margin-collapse > .large-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .large-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .large-9 {
    width: 75%; }
  .medium-margin-collapse > .large-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .large-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .large-12 {
    width: 100%; } }

@media print, screen and (min-width: 40em) {
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .small-1 {
    width: 8.33333%; }
  .large-margin-collapse > .small-2 {
    width: 16.66667%; }
  .large-margin-collapse > .small-3 {
    width: 25%; }
  .large-margin-collapse > .small-4 {
    width: 33.33333%; }
  .large-margin-collapse > .small-5 {
    width: 41.66667%; }
  .large-margin-collapse > .small-6 {
    width: 50%; }
  .large-margin-collapse > .small-7 {
    width: 58.33333%; }
  .large-margin-collapse > .small-8 {
    width: 66.66667%; }
  .large-margin-collapse > .small-9 {
    width: 75%; }
  .large-margin-collapse > .small-10 {
    width: 83.33333%; }
  .large-margin-collapse > .small-11 {
    width: 91.66667%; }
  .large-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .large-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .large-margin-collapse > .medium-3 {
    width: 25%; }
  .large-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .large-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .large-margin-collapse > .medium-6 {
    width: 50%; }
  .large-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .large-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .large-margin-collapse > .medium-9 {
    width: 75%; }
  .large-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .large-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .large-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .large-1 {
    width: 8.33333%; }
  .large-margin-collapse > .large-2 {
    width: 16.66667%; }
  .large-margin-collapse > .large-3 {
    width: 25%; }
  .large-margin-collapse > .large-4 {
    width: 33.33333%; }
  .large-margin-collapse > .large-5 {
    width: 41.66667%; }
  .large-margin-collapse > .large-6 {
    width: 50%; }
  .large-margin-collapse > .large-7 {
    width: 58.33333%; }
  .large-margin-collapse > .large-8 {
    width: 66.66667%; }
  .large-margin-collapse > .large-9 {
    width: 75%; }
  .large-margin-collapse > .large-10 {
    width: 83.33333%; }
  .large-margin-collapse > .large-11 {
    width: 91.66667%; }
  .large-margin-collapse > .large-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

.small-offset-0 {
  margin-left: 0%; }

.grid-margin-x > .small-offset-0 {
  margin-left: calc(0% + 0.66667rem); }

.small-offset-1 {
  margin-left: 8.33333%; }

.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.33333% + 0.66667rem); }

.small-offset-2 {
  margin-left: 16.66667%; }

.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.66667% + 0.66667rem); }

.small-offset-3 {
  margin-left: 25%; }

.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + 0.66667rem); }

.small-offset-4 {
  margin-left: 33.33333%; }

.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.33333% + 0.66667rem); }

.small-offset-5 {
  margin-left: 41.66667%; }

.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.66667% + 0.66667rem); }

.small-offset-6 {
  margin-left: 50%; }

.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + 0.66667rem); }

.small-offset-7 {
  margin-left: 58.33333%; }

.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.33333% + 0.66667rem); }

.small-offset-8 {
  margin-left: 66.66667%; }

.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.66667% + 0.66667rem); }

.small-offset-9 {
  margin-left: 75%; }

.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + 0.66667rem); }

.small-offset-10 {
  margin-left: 83.33333%; }

.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.33333% + 0.66667rem); }

.small-offset-11 {
  margin-left: 91.66667%; }

.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.66667% + 0.66667rem); }

@media print, screen and (min-width: 40em) {
  .medium-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 1rem); }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.33333% + 1rem); }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.66667% + 1rem); }
  .medium-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 1rem); }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.33333% + 1rem); }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.66667% + 1rem); }
  .medium-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 1rem); }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.33333% + 1rem); }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.66667% + 1rem); }
  .medium-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 1rem); }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.33333% + 1rem); }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.66667% + 1rem); } }

@media print, screen and (min-width: 64em) {
  .large-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 1.66667rem); }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.33333% + 1.66667rem); }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.66667% + 1.66667rem); }
  .large-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 1.66667rem); }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.33333% + 1.66667rem); }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.66667% + 1.66667rem); }
  .large-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 1.66667rem); }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.33333% + 1.66667rem); }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.66667% + 1.66667rem); }
  .large-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 1.66667rem); }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.33333% + 1.66667rem); }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.66667% + 1.66667rem); } }

.grid-y {
  display: flex;
  flex-flow: column nowrap; }
  .grid-y > .cell {
    width: auto;
    max-width: none; }
  .grid-y > .auto {
    height: auto; }
  .grid-y > .shrink {
    height: auto; }
  .grid-y > .small-shrink, .grid-y > .small-full, .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
    flex-basis: auto; }
  @media print, screen and (min-width: 40em) {
    .grid-y > .medium-shrink, .grid-y > .medium-full, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
      flex-basis: auto; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > .large-shrink, .grid-y > .large-full, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
      flex-basis: auto; } }
  .grid-y > .small-1 {
    height: 8.33333%; }
  .grid-y > .small-2 {
    height: 16.66667%; }
  .grid-y > .small-3 {
    height: 25%; }
  .grid-y > .small-4 {
    height: 33.33333%; }
  .grid-y > .small-5 {
    height: 41.66667%; }
  .grid-y > .small-6 {
    height: 50%; }
  .grid-y > .small-7 {
    height: 58.33333%; }
  .grid-y > .small-8 {
    height: 66.66667%; }
  .grid-y > .small-9 {
    height: 75%; }
  .grid-y > .small-10 {
    height: 83.33333%; }
  .grid-y > .small-11 {
    height: 91.66667%; }
  .grid-y > .small-12 {
    height: 100%; }
  @media print, screen and (min-width: 40em) {
    .grid-y > .medium-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .medium-shrink {
      height: auto; }
    .grid-y > .medium-1 {
      height: 8.33333%; }
    .grid-y > .medium-2 {
      height: 16.66667%; }
    .grid-y > .medium-3 {
      height: 25%; }
    .grid-y > .medium-4 {
      height: 33.33333%; }
    .grid-y > .medium-5 {
      height: 41.66667%; }
    .grid-y > .medium-6 {
      height: 50%; }
    .grid-y > .medium-7 {
      height: 58.33333%; }
    .grid-y > .medium-8 {
      height: 66.66667%; }
    .grid-y > .medium-9 {
      height: 75%; }
    .grid-y > .medium-10 {
      height: 83.33333%; }
    .grid-y > .medium-11 {
      height: 91.66667%; }
    .grid-y > .medium-12 {
      height: 100%; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > .large-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .large-shrink {
      height: auto; }
    .grid-y > .large-1 {
      height: 8.33333%; }
    .grid-y > .large-2 {
      height: 16.66667%; }
    .grid-y > .large-3 {
      height: 25%; }
    .grid-y > .large-4 {
      height: 33.33333%; }
    .grid-y > .large-5 {
      height: 41.66667%; }
    .grid-y > .large-6 {
      height: 50%; }
    .grid-y > .large-7 {
      height: 58.33333%; }
    .grid-y > .large-8 {
      height: 66.66667%; }
    .grid-y > .large-9 {
      height: 75%; }
    .grid-y > .large-10 {
      height: 83.33333%; }
    .grid-y > .large-11 {
      height: 91.66667%; }
    .grid-y > .large-12 {
      height: 100%; } }

.grid-padding-y .grid-padding-y {
  margin-top: -0.66667rem;
  margin-bottom: -0.66667rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-y .grid-padding-y {
      margin-top: -1rem;
      margin-bottom: -1rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-padding-y .grid-padding-y {
      margin-top: -1.66667rem;
      margin-bottom: -1.66667rem; } }

.grid-padding-y > .cell {
  padding-top: 0.66667rem;
  padding-bottom: 0.66667rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-y > .cell {
      padding-top: 1rem;
      padding-bottom: 1rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-padding-y > .cell {
      padding-top: 1.66667rem;
      padding-bottom: 1.66667rem; } }

.grid-margin-y {
  margin-top: -0.66667rem;
  margin-bottom: -0.66667rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y {
      margin-top: -1rem;
      margin-bottom: -1rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y {
      margin-top: -1.66667rem;
      margin-bottom: -1.66667rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 1.33333rem);
    margin-top: 0.66667rem;
    margin-bottom: 0.66667rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .cell {
      height: calc(100% - 2rem);
      margin-top: 1rem;
      margin-bottom: 1rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .cell {
      height: calc(100% - 3.33333rem);
      margin-top: 1.66667rem;
      margin-bottom: 1.66667rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.33333rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.33333rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.33333rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.33333rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.33333rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.33333rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.33333rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.33333rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.33333rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.33333rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.33333rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.33333rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 2rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 2rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 2rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 2rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 2rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 2rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 2rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 2rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 2rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 2rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 2rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 2rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 2rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 2rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 2rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 2rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 2rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 2rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 2rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 2rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 2rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 2rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 2rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 2rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 3.33333rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 3.33333rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 3.33333rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 3.33333rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 3.33333rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 3.33333rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 3.33333rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 3.33333rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 3.33333rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 3.33333rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 3.33333rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 3.33333rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 3.33333rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 3.33333rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 3.33333rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 3.33333rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 3.33333rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 3.33333rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 3.33333rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 3.33333rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 3.33333rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 3.33333rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 3.33333rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 3.33333rem); }
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 3.33333rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 3.33333rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 3.33333rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 3.33333rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 3.33333rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 3.33333rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 3.33333rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 3.33333rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 3.33333rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 3.33333rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 3.33333rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 3.33333rem); } }

.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100vw; }

.cell .grid-frame {
  width: 100%; }

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-container {
  display: flex;
  flex-direction: column;
  max-height: 100%; }
  .cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap; }

@media print, screen and (min-width: 40em) {
  .medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .medium-grid-frame {
    width: 100%; }
  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .medium-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .medium-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media print, screen and (min-width: 64em) {
  .large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .large-grid-frame {
    width: 100%; }
  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .large-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .large-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

.grid-y.grid-frame {
  width: auto;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100vh; }

@media print, screen and (min-width: 40em) {
  .grid-y.medium-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

@media print, screen and (min-width: 64em) {
  .grid-y.large-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

.cell .grid-y.grid-frame {
  height: 100%; }

@media print, screen and (min-width: 40em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%; } }

@media print, screen and (min-width: 64em) {
  .cell .grid-y.large-grid-frame {
    height: 100%; } }

.grid-margin-y {
  margin-top: -0.66667rem;
  margin-bottom: -0.66667rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y {
      margin-top: -1rem;
      margin-bottom: -1rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y {
      margin-top: -1.66667rem;
      margin-bottom: -1.66667rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 1.33333rem);
    margin-top: 0.66667rem;
    margin-bottom: 0.66667rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .cell {
      height: calc(100% - 2rem);
      margin-top: 1rem;
      margin-bottom: 1rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .cell {
      height: calc(100% - 3.33333rem);
      margin-top: 1.66667rem;
      margin-bottom: 1.66667rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.33333rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.33333rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.33333rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.33333rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.33333rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.33333rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.33333rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.33333rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.33333rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.33333rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.33333rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.33333rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 2rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 2rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 2rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 2rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 2rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 2rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 2rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 2rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 2rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 2rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 2rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 2rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 2rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 2rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 2rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 2rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 2rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 2rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 2rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 2rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 2rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 2rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 2rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 2rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 3.33333rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 3.33333rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 3.33333rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 3.33333rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 3.33333rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 3.33333rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 3.33333rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 3.33333rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 3.33333rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 3.33333rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 3.33333rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 3.33333rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 3.33333rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 3.33333rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 3.33333rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 3.33333rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 3.33333rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 3.33333rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 3.33333rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 3.33333rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 3.33333rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 3.33333rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 3.33333rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 3.33333rem); }
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 3.33333rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 3.33333rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 3.33333rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 3.33333rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 3.33333rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 3.33333rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 3.33333rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 3.33333rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 3.33333rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 3.33333rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 3.33333rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 3.33333rem); } }

.grid-frame.grid-margin-y {
  height: calc(100vh + 1.33333rem); }
  @media print, screen and (min-width: 40em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 2rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 3.33333rem); } }

@media print, screen and (min-width: 40em) {
  .grid-margin-y.medium-grid-frame {
    height: calc(100vh + 2rem); } }

@media print, screen and (min-width: 64em) {
  .grid-margin-y.large-grid-frame {
    height: calc(100vh + 3.33333rem); } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1.33333rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Open Sans", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small, .h1 small,
  h2 small, .h2 small,
  h3 small, .h3 small,
  h4 small, .h4 small,
  h5 small, .h5 small,
  h6 small, .h6 small {
    line-height: 0;
    color: #cacaca; }

h1, .h1 {
  font-size: 1.6rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2, .h2 {
  font-size: 1.33333rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3, .h3 {
  font-size: 1.26667rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4, .h4 {
  font-size: 1.2rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5, .h5 {
  font-size: 1.13333rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6, .h6 {
  font-size: 1.06667rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1, .h1 {
    font-size: 3.33333rem; }
  h2, .h2 {
    font-size: 2.66667rem; }
  h3, .h3 {
    font-size: 2.06667rem; }
  h4, .h4 {
    font-size: 1.66667rem; }
  h5, .h5 {
    font-size: 1.33333rem; }
  h6, .h6 {
    font-size: 1.06667rem; } }

a {
  line-height: inherit;
  color: #3999b4;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #31849b; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 80rem;
  height: 0;
  margin: 1.33333rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1.33333rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1.33333rem;
  padding: 0.6rem 1.33333rem 0 1.26667rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.86667rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #0a0a0a;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.13333rem 0.33333rem 0.06667rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0a0a0a; }

kbd {
  margin: 0;
  padding: 0.13333rem 0.26667rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 1.17188rem;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.6rem;
  margin: 0 0 1.06667rem;
  padding: 0.53333rem;
  border: 1px solid #e1e1e1;
  border-radius: 0;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  font-family: inherit;
  font-size: 1.06667rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #cacaca; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1.06667rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.53333rem;
  margin-right: 1.06667rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.53333rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.93333rem;
  font-weight: normal;
  line-height: 1.8;
  color: #0a0a0a; }
  label.middle {
    margin: 0 0 1.06667rem;
    padding: 0.6rem 0; }

.help-text {
  margin-top: -0.53333rem;
  font-size: 0.86667rem;
  font-style: italic;
  color: #0a0a0a; }

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1.06667rem;
  align-items: stretch; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  min-width: 0; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: auto;
    align-self: stretch;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1.06667rem; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.53333rem; }

.fieldset {
  margin: 1.2rem 0;
  padding: 1.33333rem;
  border: 1px solid #cacaca; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.2rem;
    padding: 0 0.2rem; }

select {
  height: 2.6rem;
  margin: 0 0 1.06667rem;
  padding: 0.53333rem;
  appearance: none;
  border: 1px solid #e1e1e1;
  border-radius: 1.66667rem;
  background-color: #e1e1e1;
  font-family: inherit;
  font-size: 1.06667rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%2857, 153, 180%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1.06667rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.6rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #f9ecea; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.53333rem;
  margin-bottom: 1.06667rem;
  font-size: 0.8rem;
  font-weight: bold;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

.button, .header-menu__item {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 1.33333rem;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  color: #3999b4; }
  [data-whatinput='mouse'] .button, [data-whatinput='mouse'] .header-menu__item {
    outline: 0; }
  .button:hover, .header-menu__item:hover, .button:focus, .header-menu__item:focus {
    background-color: #3999b4;
    color: #3999b4; }
  .button.tiny, .tiny.header-menu__item {
    font-size: 0.6rem; }
  .button.small, .small.header-menu__item {
    font-size: 0.75rem; }
  .button.large, .large.header-menu__item {
    font-size: 1.25rem; }
  .button.expanded, .expanded.header-menu__item {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary, .primary.header-menu__item {
    background-color: #3999b4;
    color: #0a0a0a; }
    .button.primary:hover, .primary.header-menu__item:hover, .button.primary:focus, .primary.header-menu__item:focus {
      background-color: #2e7a90;
      color: #0a0a0a; }
  .button.secondary, .secondary.header-menu__item {
    background-color: #d85a31;
    color: #0a0a0a; }
    .button.secondary:hover, .secondary.header-menu__item:hover, .button.secondary:focus, .secondary.header-menu__item:focus {
      background-color: #b24522;
      color: #0a0a0a; }
  .button.success, .success.header-menu__item {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button.success:hover, .success.header-menu__item:hover, .button.success:focus, .success.header-menu__item:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button.warning, .warning.header-menu__item {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button.warning:hover, .warning.header-menu__item:hover, .button.warning:focus, .warning.header-menu__item:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button.alert, .alert.header-menu__item {
    background-color: #cc4b37;
    color: #0a0a0a; }
    .button.alert:hover, .alert.header-menu__item:hover, .button.alert:focus, .alert.header-menu__item:focus {
      background-color: #a53b2a;
      color: #0a0a0a; }
  .button.disabled, .disabled.header-menu__item, .button[disabled], .header-menu__item[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .disabled.header-menu__item, .button.disabled:hover, .disabled.header-menu__item:hover, .button.disabled:focus, .disabled.header-menu__item:focus, .button[disabled], .header-menu__item[disabled], .button[disabled]:hover, .header-menu__item[disabled]:hover, .button[disabled]:focus, .header-menu__item[disabled]:focus {
      background-color: transparent;
      color: #3999b4; }
    .button.disabled.primary, .disabled.primary.header-menu__item, .button[disabled].primary, .header-menu__item[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .disabled.primary.header-menu__item, .button.disabled.primary:hover, .disabled.primary.header-menu__item:hover, .button.disabled.primary:focus, .disabled.primary.header-menu__item:focus, .button[disabled].primary, .header-menu__item[disabled].primary, .button[disabled].primary:hover, .header-menu__item[disabled].primary:hover, .button[disabled].primary:focus, .header-menu__item[disabled].primary:focus {
        background-color: #3999b4;
        color: #0a0a0a; }
    .button.disabled.secondary, .disabled.secondary.header-menu__item, .button[disabled].secondary, .header-menu__item[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .disabled.secondary.header-menu__item, .button.disabled.secondary:hover, .disabled.secondary.header-menu__item:hover, .button.disabled.secondary:focus, .disabled.secondary.header-menu__item:focus, .button[disabled].secondary, .header-menu__item[disabled].secondary, .button[disabled].secondary:hover, .header-menu__item[disabled].secondary:hover, .button[disabled].secondary:focus, .header-menu__item[disabled].secondary:focus {
        background-color: #d85a31;
        color: #0a0a0a; }
    .button.disabled.success, .disabled.success.header-menu__item, .button[disabled].success, .header-menu__item[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .disabled.success.header-menu__item, .button.disabled.success:hover, .disabled.success.header-menu__item:hover, .button.disabled.success:focus, .disabled.success.header-menu__item:focus, .button[disabled].success, .header-menu__item[disabled].success, .button[disabled].success:hover, .header-menu__item[disabled].success:hover, .button[disabled].success:focus, .header-menu__item[disabled].success:focus {
        background-color: #3adb76;
        color: #0a0a0a; }
    .button.disabled.warning, .disabled.warning.header-menu__item, .button[disabled].warning, .header-menu__item[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .disabled.warning.header-menu__item, .button.disabled.warning:hover, .disabled.warning.header-menu__item:hover, .button.disabled.warning:focus, .disabled.warning.header-menu__item:focus, .button[disabled].warning, .header-menu__item[disabled].warning, .button[disabled].warning:hover, .header-menu__item[disabled].warning:hover, .button[disabled].warning:focus, .header-menu__item[disabled].warning:focus {
        background-color: #ffae00;
        color: #0a0a0a; }
    .button.disabled.alert, .disabled.alert.header-menu__item, .button[disabled].alert, .header-menu__item[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .disabled.alert.header-menu__item, .button.disabled.alert:hover, .disabled.alert.header-menu__item:hover, .button.disabled.alert:focus, .disabled.alert.header-menu__item:focus, .button[disabled].alert, .header-menu__item[disabled].alert, .button[disabled].alert:hover, .header-menu__item[disabled].alert:hover, .button[disabled].alert:focus, .header-menu__item[disabled].alert:focus {
        background-color: #cc4b37;
        color: #0a0a0a; }
  .button.hollow, .hollow.header-menu__item {
    border: 1px solid transparent;
    color: transparent; }
    .button.hollow, .hollow.header-menu__item, .button.hollow:hover, .hollow.header-menu__item:hover, .button.hollow:focus, .hollow.header-menu__item:focus {
      background-color: transparent; }
    .button.hollow.disabled, .hollow.disabled.header-menu__item, .button.hollow.disabled:hover, .hollow.disabled.header-menu__item:hover, .button.hollow.disabled:focus, .hollow.disabled.header-menu__item:focus, .button.hollow[disabled], .hollow.header-menu__item[disabled], .button.hollow[disabled]:hover, .hollow.header-menu__item[disabled]:hover, .button.hollow[disabled]:focus, .hollow.header-menu__item[disabled]:focus {
      background-color: transparent; }
    .button.hollow:hover, .hollow.header-menu__item:hover, .button.hollow:focus, .hollow.header-menu__item:focus {
      border-color: transparent;
      color: transparent; }
      .button.hollow:hover.disabled, .hollow.header-menu__item:hover.disabled, .button.hollow:hover[disabled], .hollow.header-menu__item:hover[disabled], .button.hollow:focus.disabled, .hollow.header-menu__item:focus.disabled, .button.hollow:focus[disabled], .hollow.header-menu__item:focus[disabled] {
        border: 1px solid transparent;
        color: transparent; }
    .button.hollow.primary, .hollow.primary.header-menu__item {
      border: 1px solid #3999b4;
      color: #3999b4; }
      .button.hollow.primary:hover, .hollow.primary.header-menu__item:hover, .button.hollow.primary:focus, .hollow.primary.header-menu__item:focus {
        border-color: #1d4d5a;
        color: #1d4d5a; }
        .button.hollow.primary:hover.disabled, .hollow.primary.header-menu__item:hover.disabled, .button.hollow.primary:hover[disabled], .hollow.primary.header-menu__item:hover[disabled], .button.hollow.primary:focus.disabled, .hollow.primary.header-menu__item:focus.disabled, .button.hollow.primary:focus[disabled], .hollow.primary.header-menu__item:focus[disabled] {
          border: 1px solid #3999b4;
          color: #3999b4; }
    .button.hollow.secondary, .hollow.secondary.header-menu__item {
      border: 1px solid #d85a31;
      color: #d85a31; }
      .button.hollow.secondary:hover, .hollow.secondary.header-menu__item:hover, .button.hollow.secondary:focus, .hollow.secondary.header-menu__item:focus {
        border-color: #6f2b15;
        color: #6f2b15; }
        .button.hollow.secondary:hover.disabled, .hollow.secondary.header-menu__item:hover.disabled, .button.hollow.secondary:hover[disabled], .hollow.secondary.header-menu__item:hover[disabled], .button.hollow.secondary:focus.disabled, .hollow.secondary.header-menu__item:focus.disabled, .button.hollow.secondary:focus[disabled], .hollow.secondary.header-menu__item:focus[disabled] {
          border: 1px solid #d85a31;
          color: #d85a31; }
    .button.hollow.success, .hollow.success.header-menu__item {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.hollow.success:hover, .hollow.success.header-menu__item:hover, .button.hollow.success:focus, .hollow.success.header-menu__item:focus {
        border-color: #157539;
        color: #157539; }
        .button.hollow.success:hover.disabled, .hollow.success.header-menu__item:hover.disabled, .button.hollow.success:hover[disabled], .hollow.success.header-menu__item:hover[disabled], .button.hollow.success:focus.disabled, .hollow.success.header-menu__item:focus.disabled, .button.hollow.success:focus[disabled], .hollow.success.header-menu__item:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
    .button.hollow.warning, .hollow.warning.header-menu__item {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .hollow.warning.header-menu__item:hover, .button.hollow.warning:focus, .hollow.warning.header-menu__item:focus {
        border-color: #805700;
        color: #805700; }
        .button.hollow.warning:hover.disabled, .hollow.warning.header-menu__item:hover.disabled, .button.hollow.warning:hover[disabled], .hollow.warning.header-menu__item:hover[disabled], .button.hollow.warning:focus.disabled, .hollow.warning.header-menu__item:focus.disabled, .button.hollow.warning:focus[disabled], .hollow.warning.header-menu__item:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
    .button.hollow.alert, .hollow.alert.header-menu__item {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.hollow.alert:hover, .hollow.alert.header-menu__item:hover, .button.hollow.alert:focus, .hollow.alert.header-menu__item:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.hollow.alert:hover.disabled, .hollow.alert.header-menu__item:hover.disabled, .button.hollow.alert:hover[disabled], .hollow.alert.header-menu__item:hover[disabled], .button.hollow.alert:focus.disabled, .hollow.alert.header-menu__item:focus.disabled, .button.hollow.alert:focus[disabled], .hollow.alert.header-menu__item:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
  .button.clear, .clear.header-menu__item {
    border: 1px solid transparent;
    color: transparent; }
    .button.clear, .clear.header-menu__item, .button.clear:hover, .clear.header-menu__item:hover, .button.clear:focus, .clear.header-menu__item:focus {
      background-color: transparent; }
    .button.clear.disabled, .clear.disabled.header-menu__item, .button.clear.disabled:hover, .clear.disabled.header-menu__item:hover, .button.clear.disabled:focus, .clear.disabled.header-menu__item:focus, .button.clear[disabled], .clear.header-menu__item[disabled], .button.clear[disabled]:hover, .clear.header-menu__item[disabled]:hover, .button.clear[disabled]:focus, .clear.header-menu__item[disabled]:focus {
      background-color: transparent; }
    .button.clear:hover, .clear.header-menu__item:hover, .button.clear:focus, .clear.header-menu__item:focus {
      border-color: transparent;
      color: transparent; }
      .button.clear:hover.disabled, .clear.header-menu__item:hover.disabled, .button.clear:hover[disabled], .clear.header-menu__item:hover[disabled], .button.clear:focus.disabled, .clear.header-menu__item:focus.disabled, .button.clear:focus[disabled], .clear.header-menu__item:focus[disabled] {
        border: 1px solid transparent;
        color: transparent; }
    .button.clear, .clear.header-menu__item, .button.clear.disabled, .clear.disabled.header-menu__item, .button.clear[disabled], .clear.header-menu__item[disabled], .button.clear:hover, .clear.header-menu__item:hover, .button.clear:hover.disabled, .clear.header-menu__item:hover.disabled, .button.clear:hover[disabled], .clear.header-menu__item:hover[disabled], .button.clear:focus, .clear.header-menu__item:focus, .button.clear:focus.disabled, .clear.header-menu__item:focus.disabled, .button.clear:focus[disabled], .clear.header-menu__item:focus[disabled] {
      border-color: transparent; }
    .button.clear.primary, .clear.primary.header-menu__item {
      border: 1px solid #3999b4;
      color: #3999b4; }
      .button.clear.primary:hover, .clear.primary.header-menu__item:hover, .button.clear.primary:focus, .clear.primary.header-menu__item:focus {
        border-color: #1d4d5a;
        color: #1d4d5a; }
        .button.clear.primary:hover.disabled, .clear.primary.header-menu__item:hover.disabled, .button.clear.primary:hover[disabled], .clear.primary.header-menu__item:hover[disabled], .button.clear.primary:focus.disabled, .clear.primary.header-menu__item:focus.disabled, .button.clear.primary:focus[disabled], .clear.primary.header-menu__item:focus[disabled] {
          border: 1px solid #3999b4;
          color: #3999b4; }
      .button.clear.primary, .clear.primary.header-menu__item, .button.clear.primary.disabled, .clear.primary.disabled.header-menu__item, .button.clear.primary[disabled], .clear.primary.header-menu__item[disabled], .button.clear.primary:hover, .clear.primary.header-menu__item:hover, .button.clear.primary:hover.disabled, .clear.primary.header-menu__item:hover.disabled, .button.clear.primary:hover[disabled], .clear.primary.header-menu__item:hover[disabled], .button.clear.primary:focus, .clear.primary.header-menu__item:focus, .button.clear.primary:focus.disabled, .clear.primary.header-menu__item:focus.disabled, .button.clear.primary:focus[disabled], .clear.primary.header-menu__item:focus[disabled] {
        border-color: transparent; }
    .button.clear.secondary, .clear.secondary.header-menu__item {
      border: 1px solid #d85a31;
      color: #d85a31; }
      .button.clear.secondary:hover, .clear.secondary.header-menu__item:hover, .button.clear.secondary:focus, .clear.secondary.header-menu__item:focus {
        border-color: #6f2b15;
        color: #6f2b15; }
        .button.clear.secondary:hover.disabled, .clear.secondary.header-menu__item:hover.disabled, .button.clear.secondary:hover[disabled], .clear.secondary.header-menu__item:hover[disabled], .button.clear.secondary:focus.disabled, .clear.secondary.header-menu__item:focus.disabled, .button.clear.secondary:focus[disabled], .clear.secondary.header-menu__item:focus[disabled] {
          border: 1px solid #d85a31;
          color: #d85a31; }
      .button.clear.secondary, .clear.secondary.header-menu__item, .button.clear.secondary.disabled, .clear.secondary.disabled.header-menu__item, .button.clear.secondary[disabled], .clear.secondary.header-menu__item[disabled], .button.clear.secondary:hover, .clear.secondary.header-menu__item:hover, .button.clear.secondary:hover.disabled, .clear.secondary.header-menu__item:hover.disabled, .button.clear.secondary:hover[disabled], .clear.secondary.header-menu__item:hover[disabled], .button.clear.secondary:focus, .clear.secondary.header-menu__item:focus, .button.clear.secondary:focus.disabled, .clear.secondary.header-menu__item:focus.disabled, .button.clear.secondary:focus[disabled], .clear.secondary.header-menu__item:focus[disabled] {
        border-color: transparent; }
    .button.clear.success, .clear.success.header-menu__item {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.clear.success:hover, .clear.success.header-menu__item:hover, .button.clear.success:focus, .clear.success.header-menu__item:focus {
        border-color: #157539;
        color: #157539; }
        .button.clear.success:hover.disabled, .clear.success.header-menu__item:hover.disabled, .button.clear.success:hover[disabled], .clear.success.header-menu__item:hover[disabled], .button.clear.success:focus.disabled, .clear.success.header-menu__item:focus.disabled, .button.clear.success:focus[disabled], .clear.success.header-menu__item:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
      .button.clear.success, .clear.success.header-menu__item, .button.clear.success.disabled, .clear.success.disabled.header-menu__item, .button.clear.success[disabled], .clear.success.header-menu__item[disabled], .button.clear.success:hover, .clear.success.header-menu__item:hover, .button.clear.success:hover.disabled, .clear.success.header-menu__item:hover.disabled, .button.clear.success:hover[disabled], .clear.success.header-menu__item:hover[disabled], .button.clear.success:focus, .clear.success.header-menu__item:focus, .button.clear.success:focus.disabled, .clear.success.header-menu__item:focus.disabled, .button.clear.success:focus[disabled], .clear.success.header-menu__item:focus[disabled] {
        border-color: transparent; }
    .button.clear.warning, .clear.warning.header-menu__item {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.clear.warning:hover, .clear.warning.header-menu__item:hover, .button.clear.warning:focus, .clear.warning.header-menu__item:focus {
        border-color: #805700;
        color: #805700; }
        .button.clear.warning:hover.disabled, .clear.warning.header-menu__item:hover.disabled, .button.clear.warning:hover[disabled], .clear.warning.header-menu__item:hover[disabled], .button.clear.warning:focus.disabled, .clear.warning.header-menu__item:focus.disabled, .button.clear.warning:focus[disabled], .clear.warning.header-menu__item:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
      .button.clear.warning, .clear.warning.header-menu__item, .button.clear.warning.disabled, .clear.warning.disabled.header-menu__item, .button.clear.warning[disabled], .clear.warning.header-menu__item[disabled], .button.clear.warning:hover, .clear.warning.header-menu__item:hover, .button.clear.warning:hover.disabled, .clear.warning.header-menu__item:hover.disabled, .button.clear.warning:hover[disabled], .clear.warning.header-menu__item:hover[disabled], .button.clear.warning:focus, .clear.warning.header-menu__item:focus, .button.clear.warning:focus.disabled, .clear.warning.header-menu__item:focus.disabled, .button.clear.warning:focus[disabled], .clear.warning.header-menu__item:focus[disabled] {
        border-color: transparent; }
    .button.clear.alert, .clear.alert.header-menu__item {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.clear.alert:hover, .clear.alert.header-menu__item:hover, .button.clear.alert:focus, .clear.alert.header-menu__item:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.clear.alert:hover.disabled, .clear.alert.header-menu__item:hover.disabled, .button.clear.alert:hover[disabled], .clear.alert.header-menu__item:hover[disabled], .button.clear.alert:focus.disabled, .clear.alert.header-menu__item:focus.disabled, .button.clear.alert:focus[disabled], .clear.alert.header-menu__item:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
      .button.clear.alert, .clear.alert.header-menu__item, .button.clear.alert.disabled, .clear.alert.disabled.header-menu__item, .button.clear.alert[disabled], .clear.alert.header-menu__item[disabled], .button.clear.alert:hover, .clear.alert.header-menu__item:hover, .button.clear.alert:hover.disabled, .clear.alert.header-menu__item:hover.disabled, .button.clear.alert:hover[disabled], .clear.alert.header-menu__item:hover[disabled], .button.clear.alert:focus, .clear.alert.header-menu__item:focus, .button.clear.alert:focus.disabled, .clear.alert.header-menu__item:focus.disabled, .button.clear.alert:focus[disabled], .clear.alert.header-menu__item:focus[disabled] {
        border-color: transparent; }
  .button.dropdown::after, .dropdown.header-menu__item::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fefefe transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1.33333rem; }
  .button.dropdown.hollow::after, .dropdown.hollow.header-menu__item::after {
    border-top-color: transparent; }
  .button.dropdown.hollow.primary::after, .dropdown.hollow.primary.header-menu__item::after {
    border-top-color: #3999b4; }
  .button.dropdown.hollow.secondary::after, .dropdown.hollow.secondary.header-menu__item::after {
    border-top-color: #d85a31; }
  .button.dropdown.hollow.success::after, .dropdown.hollow.success.header-menu__item::after {
    border-top-color: #3adb76; }
  .button.dropdown.hollow.warning::after, .dropdown.hollow.warning.header-menu__item::after {
    border-top-color: #ffae00; }
  .button.dropdown.hollow.alert::after, .dropdown.hollow.alert.header-menu__item::after {
    border-top-color: #cc4b37; }
  .button.arrow-only::after, .arrow-only.header-menu__item::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

a.button:hover, a.header-menu__item:hover, a.button:focus, a.header-menu__item:focus {
  text-decoration: none; }

.accordion {
  margin-left: 0;
  background: #fefefe;
  list-style-type: none; }
  .accordion[disabled] .accordion-title {
    cursor: not-allowed; }

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0; }

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.8rem;
  line-height: 1;
  color: #3999b4; }
  :last-child:not(.is-active) > .accordion-title {
    border-bottom: 1px solid #e6e6e6;
    border-radius: 0 0 0 0; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: #e6e6e6; }
  .accordion-title::before {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -0.5rem;
    content: '+'; }
  .is-active > .accordion-title::before {
    content: '\2013'; }

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #fefefe;
  color: #0a0a0a; }
  :last-child > .accordion-content:last-child {
    border-bottom: 1px solid #e6e6e6; }

.accordion-menu {
  border-bottom: 1px solid #e6e6e6; }
  .accordion-menu li {
    border-top: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;
    width: 100%; }
  .accordion-menu a {
    padding: 1.33333rem 1rem; }
  .accordion-menu .is-accordion-submenu a {
    padding: 1.33333rem 1rem; }
  .accordion-menu .nested.is-accordion-submenu {
    margin-right: 0;
    margin-left: 0; }
  .accordion-menu.align-right .nested.is-accordion-submenu {
    margin-right: 0;
    margin-left: 0; }
  .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a {
    position: relative; }
    .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
      display: block;
      width: 0;
      height: 0;
      border: inset 6px;
      content: '';
      border-bottom-width: 0;
      border-top-style: solid;
      border-color: #3999b4 transparent transparent;
      position: absolute;
      top: 50%;
      margin-top: -3px;
      right: 1rem; }
  .accordion-menu.align-left .is-accordion-submenu-parent > a::after {
    left: auto;
    right: 1rem; }
  .accordion-menu.align-right .is-accordion-submenu-parent > a::after {
    right: auto;
    left: 1rem; }
  .accordion-menu .is-accordion-submenu-parent[aria-expanded='true'] > a::after {
    transform: rotate(180deg);
    transform-origin: 50% 50%; }

.is-accordion-submenu li {
  border-right: 0;
  border-left: 0; }

.is-accordion-submenu-parent {
  position: relative; }

.has-submenu-toggle > a {
  margin-right: 40px; }

.submenu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-left: 1px solid #e6e6e6;
  background: #e6e6e6; }
  .submenu-toggle::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #3999b4 transparent transparent;
    top: 0;
    bottom: 0;
    margin: auto; }

.submenu-toggle[aria-expanded='true']::after {
  transform: scaleY(-1);
  transform-origin: 50% 50%; }

.submenu-toggle-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  background: #3999b4;
  color: #fefefe; }
  .badge.primary {
    background: #3999b4;
    color: #0a0a0a; }
  .badge.secondary {
    background: #d85a31;
    color: #0a0a0a; }
  .badge.success {
    background: #3adb76;
    color: #0a0a0a; }
  .badge.warning {
    background: #ffae00;
    color: #0a0a0a; }
  .badge.alert {
    background: #cc4b37;
    color: #fefefe; }

.breadcrumbs {
  margin: 0;
  list-style: none; }
  .breadcrumbs::before, .breadcrumbs::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .breadcrumbs::after {
    clear: both; }
  .breadcrumbs li {
    float: left;
    font-size: 0.86667rem;
    color: #3999b4;
    cursor: default; }
    .breadcrumbs li:not(:last-child)::after {
      position: relative;
      margin: 0 0.75rem;
      opacity: 1;
      content: ">";
      color: #0a0a0a; }
  .breadcrumbs a {
    color: #0a0a0a; }
    .breadcrumbs a:hover {
      text-decoration: underline; }
  .breadcrumbs .disabled {
    color: #cacaca;
    cursor: not-allowed; }

.button-group {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .button-group::after {
    clear: both; }
  .button-group .button, .button-group .header-menu__item {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 0.9rem;
    flex: 0 0 auto; }
    .button-group .button:last-child, .button-group .header-menu__item:last-child {
      margin-right: 0; }
  .button-group.tiny .button, .button-group.tiny .header-menu__item {
    font-size: 0.6rem; }
  .button-group.small .button, .button-group.small .header-menu__item {
    font-size: 0.75rem; }
  .button-group.large .button, .button-group.large .header-menu__item {
    font-size: 1.25rem; }
  .button-group.expanded .button, .button-group.expanded .header-menu__item {
    flex: 1 1 0px; }
  .button-group.primary .button, .button-group.primary .header-menu__item {
    background-color: #3999b4;
    color: #0a0a0a; }
    .button-group.primary .button:hover, .button-group.primary .header-menu__item:hover, .button-group.primary .button:focus, .button-group.primary .header-menu__item:focus {
      background-color: #2e7a90;
      color: #0a0a0a; }
  .button-group.secondary .button, .button-group.secondary .header-menu__item {
    background-color: #d85a31;
    color: #0a0a0a; }
    .button-group.secondary .button:hover, .button-group.secondary .header-menu__item:hover, .button-group.secondary .button:focus, .button-group.secondary .header-menu__item:focus {
      background-color: #b24522;
      color: #0a0a0a; }
  .button-group.success .button, .button-group.success .header-menu__item {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button-group.success .button:hover, .button-group.success .header-menu__item:hover, .button-group.success .button:focus, .button-group.success .header-menu__item:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button-group.warning .button, .button-group.warning .header-menu__item {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button-group.warning .button:hover, .button-group.warning .header-menu__item:hover, .button-group.warning .button:focus, .button-group.warning .header-menu__item:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button-group.alert .button, .button-group.alert .header-menu__item {
    background-color: #cc4b37;
    color: #0a0a0a; }
    .button-group.alert .button:hover, .button-group.alert .header-menu__item:hover, .button-group.alert .button:focus, .button-group.alert .header-menu__item:focus {
      background-color: #a53b2a;
      color: #0a0a0a; }
  .button-group.stacked, .button-group.stacked-for-small, .button-group.stacked-for-medium {
    flex-wrap: wrap; }
    .button-group.stacked .button, .button-group.stacked .header-menu__item, .button-group.stacked-for-small .button, .button-group.stacked-for-small .header-menu__item, .button-group.stacked-for-medium .button, .button-group.stacked-for-medium .header-menu__item {
      flex: 0 0 100%; }
      .button-group.stacked .button:last-child, .button-group.stacked .header-menu__item:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-small .header-menu__item:last-child, .button-group.stacked-for-medium .button:last-child, .button-group.stacked-for-medium .header-menu__item:last-child {
        margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .button-group.stacked-for-small .button, .button-group.stacked-for-small .header-menu__item {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 64em) {
    .button-group.stacked-for-medium .button, .button-group.stacked-for-medium .header-menu__item {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media screen and (max-width: 39.9375em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button, .button-group.stacked-for-small.expanded .header-menu__item {
        display: block;
        margin-right: 0; } }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(10, 10, 10, 0.25);
  border-radius: 0;
  background-color: white;
  color: #0a0a0a; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #e0f0f5;
    color: #0a0a0a; }
  .callout.secondary {
    background-color: #f9e6e0;
    color: #0a0a0a; }
  .callout.success {
    background-color: #e1faea;
    color: #0a0a0a; }
  .callout.warning {
    background-color: #fff3d9;
    color: #0a0a0a; }
  .callout.alert {
    background-color: #f7e4e1;
    color: #0a0a0a; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  background: #fefefe;
  box-shadow: none;
  overflow: hidden;
  color: #0a0a0a; }
  .card > :last-child {
    margin-bottom: 0; }

.card-divider {
  flex: 0 1 auto;
  display: flex;
  padding: 1rem;
  background: #e6e6e6; }
  .card-divider > :last-child {
    margin-bottom: 0; }

.card-section {
  flex: 1 0 auto;
  padding: 1rem; }
  .card-section > :last-child {
    margin-bottom: 0; }

.card-image {
  min-height: 1px; }

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #0a0a0a; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  [data-whatinput='mouse'] .menu li {
    outline: 0; }
  .menu a,
  .menu .button,
  .menu .header-menu__item {
    line-height: 1;
    text-decoration: none;
    display: block;
    padding: 0.7rem 1rem; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu input {
    display: inline-block; }
  .menu, .menu.horizontal {
    flex-wrap: wrap;
    flex-direction: row; }
  .menu.vertical {
    flex-wrap: nowrap;
    flex-direction: column; }
  .menu.expanded li {
    flex: 1 1 0px; }
  .menu.simple {
    align-items: center; }
    .menu.simple li + li {
      margin-left: 1rem; }
    .menu.simple a {
      padding: 0; }
  @media print, screen and (min-width: 40em) {
    .menu.medium-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.medium-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.medium-expanded li {
      flex: 1 1 0px; }
    .menu.medium-simple li {
      flex: 1 1 0px; } }
  @media print, screen and (min-width: 64em) {
    .menu.large-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.large-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.large-expanded li {
      flex: 1 1 0px; }
    .menu.large-simple li {
      flex: 1 1 0px; } }
  .menu.nested {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.icons a {
    display: flex; }
  .menu.icon-top a, .menu.icon-right a, .menu.icon-bottom a, .menu.icon-left a {
    display: flex; }
  .menu.icon-left li a {
    flex-flow: row nowrap; }
    .menu.icon-left li a img,
    .menu.icon-left li a i,
    .menu.icon-left li a svg {
      margin-right: 0.25rem; }
  .menu.icon-right li a {
    flex-flow: row nowrap; }
    .menu.icon-right li a img,
    .menu.icon-right li a i,
    .menu.icon-right li a svg {
      margin-left: 0.25rem; }
  .menu.icon-top li a {
    flex-flow: column nowrap; }
    .menu.icon-top li a img,
    .menu.icon-top li a i,
    .menu.icon-top li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu.icon-bottom li a {
    flex-flow: column nowrap; }
    .menu.icon-bottom li a img,
    .menu.icon-bottom li a i,
    .menu.icon-bottom li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu .is-active > a {
    background: #3999b4;
    color: #fefefe; }
  .menu .active > a {
    background: #3999b4;
    color: #fefefe; }
  .menu.align-left {
    justify-content: flex-start; }
  .menu.align-right li {
    display: flex;
    justify-content: flex-end; }
    .menu.align-right li .submenu li {
      justify-content: flex-start; }
  .menu.align-right.vertical li {
    display: block;
    text-align: right; }
    .menu.align-right.vertical li .submenu li {
      text-align: right; }
  .menu.align-right .nested {
    margin-right: 1rem;
    margin-left: 0; }
  .menu.align-center li {
    display: flex;
    justify-content: center; }
    .menu.align-center li .submenu li {
      justify-content: flex-start; }
  .menu .menu-text {
    padding: 0.7rem 1rem;
    font-weight: bold;
    line-height: 1;
    color: inherit; }

.menu-centered > .menu {
  justify-content: center; }
  .menu-centered > .menu li {
    display: flex;
    justify-content: center; }
    .menu-centered > .menu li .submenu li {
      justify-content: flex-start; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #fefefe;
    box-shadow: 0 7px 0 #fefefe, 0 14px 0 #fefefe;
    content: ''; }
  .menu-icon:hover::after {
    background: #cacaca;
    box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #0a0a0a;
    box-shadow: 0 7px 0 #0a0a0a, 0 14px 0 #0a0a0a;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #8a8a8a;
    box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a; }

.is-drilldown {
  position: relative;
  overflow: hidden; }
  .is-drilldown li {
    display: block; }
  .is-drilldown.animate-height {
    transition: height 0.5s; }

.drilldown a {
  padding: 0.7rem 1rem;
  background: #fefefe; }

.drilldown .is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #fefefe;
  transition: transform 0.15s linear; }
  .drilldown .is-drilldown-submenu.is-active {
    z-index: 1;
    display: block;
    transform: translateX(-100%); }
  .drilldown .is-drilldown-submenu.is-closing {
    transform: translateX(100%); }
  .drilldown .is-drilldown-submenu a {
    padding: 0.7rem 1rem; }

.drilldown .nested.is-drilldown-submenu {
  margin-right: 0;
  margin-left: 0; }

.drilldown .drilldown-submenu-cover-previous {
  min-height: 100%; }

.drilldown .is-drilldown-submenu-parent > a {
  position: relative; }
  .drilldown .is-drilldown-submenu-parent > a::after {
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #3999b4; }

.drilldown.align-left .is-drilldown-submenu-parent > a::after {
  left: auto;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #3999b4; }

.drilldown.align-right .is-drilldown-submenu-parent > a::after {
  right: auto;
  left: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #3999b4 transparent transparent; }

.drilldown .js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #3999b4 transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  display: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-size: 1rem; }
  .dropdown-pane.is-opening {
    display: block; }
  .dropdown-pane.is-open {
    visibility: visible;
    display: block; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #3999b4 transparent transparent;
  right: 5px;
  left: auto;
  margin-top: -3px; }

.dropdown.menu a {
  padding: 0.7rem 1rem; }
  [data-whatinput='mouse'] .dropdown.menu a {
    outline: 0; }

.dropdown.menu .is-active > a {
  background: transparent;
  color: #3999b4; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #3999b4 transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #3999b4; }

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #3999b4 transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #3999b4 transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #3999b4; } }

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #3999b4 transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #3999b4 transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #3999b4; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    left: auto;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #fefefe; }
  .dropdown .is-dropdown-submenu a {
    padding: 0.7rem 1rem; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #3999b4 transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #3999b4; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1.06667rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video,
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen,
  .flex-video.widescreen {
    padding-bottom: 56.25%; }

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #3999b4;
  color: #fefefe; }
  .label.primary {
    background: #3999b4;
    color: #0a0a0a; }
  .label.secondary {
    background: #d85a31;
    color: #0a0a0a; }
  .label.success {
    background: #3adb76;
    color: #0a0a0a; }
  .label.warning {
    background: #ffae00;
    color: #0a0a0a; }
  .label.alert {
    background: #cc4b37;
    color: #fefefe; }

.media-object {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: nowrap; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small {
      flex-wrap: wrap; } }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small .media-object-section {
      padding: 0;
      padding-bottom: 1rem;
      flex-basis: 100%;
      max-width: 100%; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  flex: 0 1 auto; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.main-section {
    flex: 1 1 0px; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(254, 254, 254, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-push {
    z-index: 12; }
  .off-canvas.is-closed {
    visibility: hidden; }
  .off-canvas.is-transition-overlap {
    z-index: 13; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas.is-open {
    transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-push {
    z-index: 12; }
  .off-canvas-absolute.is-closed {
    visibility: hidden; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 13; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas-absolute.is-open {
    transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(-250px); }
  .off-canvas-content .off-canvas.position-left {
    transform: translateX(-250px); }
    .off-canvas-content .off-canvas.position-left.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-left.has-transition-push {
    transform: translateX(250px); }
  .position-left.is-transition-push {
    box-shadow: inset -13px 0 20px -13px rgba(10, 10, 10, 0.25); }

.position-right {
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(250px); }
  .off-canvas-content .off-canvas.position-right {
    transform: translateX(250px); }
    .off-canvas-content .off-canvas.position-right.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-right.has-transition-push {
    transform: translateX(-250px); }
  .position-right.is-transition-push {
    box-shadow: inset 13px 0 20px -13px rgba(10, 10, 10, 0.25); }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(-250px); }
  .off-canvas-content .off-canvas.position-top {
    transform: translateY(-250px); }
    .off-canvas-content .off-canvas.position-top.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-top.has-transition-push {
    transform: translateY(250px); }
  .position-top.is-transition-push {
    box-shadow: inset 0 -13px 20px -13px rgba(10, 10, 10, 0.25); }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(250px); }
  .off-canvas-content .off-canvas.position-bottom {
    transform: translateY(250px); }
    .off-canvas-content .off-canvas.position-bottom.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-bottom.has-transition-push {
    transform: translateY(-250px); }
  .position-bottom.is-transition-push {
    box-shadow: inset 0 13px 20px -13px rgba(10, 10, 10, 0.25); }

.off-canvas-content {
  transform: none;
  transition: transform 0.5s ease;
  backface-visibility: hidden; }
  .off-canvas-content.has-transition-push {
    transform: translate(0, 0); }
  .off-canvas-content .off-canvas.is-open {
    transform: translate(0, 0); }

@media print, screen and (min-width: 40em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 40em) {
  .off-canvas.in-canvas-for-medium {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-medium.position-left, .off-canvas.in-canvas-for-medium.position-right, .off-canvas.in-canvas-for-medium.position-top, .off-canvas.in-canvas-for-medium.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-medium .close-button {
      display: none; } }

@media print, screen and (min-width: 64em) {
  .off-canvas.in-canvas-for-large {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-large.position-left, .off-canvas.in-canvas-for-large.position-right, .off-canvas.in-canvas-for-large.position-top, .off-canvas.in-canvas-for-large.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-large .close-button {
      display: none; } }

.orbit {
  position: relative; }

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden; }

.orbit-slide {
  width: 100%; }
  .orbit-slide.no-motionui.is-active {
    top: 0;
    left: 0; }

.orbit-figure {
  margin: 0; }

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0; }

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(10, 10, 10, 0.5);
  color: #fefefe; }

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 0.66667rem;
  color: #fefefe; }
  [data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
    outline: 0; }
  .orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
    background-color: rgba(254, 254, 254, 0.5); }

.orbit-previous {
  left: 0; }

.orbit-next {
  left: auto;
  right: 0; }

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center; }
  [data-whatinput='mouse'] .orbit-bullets {
    outline: 0; }
  .orbit-bullets button {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.2rem;
    border-radius: 50%;
    background-color: #fefefe; }
    .orbit-bullets button:hover {
      background-color: #3999b4; }
    .orbit-bullets button.is-active {
      background-color: #3999b4; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .pagination::after {
    clear: both; }
  .pagination li {
    margin-right: 0.06667rem;
    border-radius: 0;
    font-size: 1rem;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media print, screen and (min-width: 40em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    display: block;
    padding: 0 0.66667rem;
    border-radius: 0;
    color: #3999b4; }
    .pagination a:hover,
    .pagination button:hover {
      background: transparent; }
  .pagination .current {
    padding: 0 0.66667rem;
    background: transparent;
    color: #0a0a0a;
    cursor: default; }
  .pagination .disabled {
    padding: 0 0.66667rem;
    color: #cacaca;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    padding: 0 0.66667rem;
    content: '\2026';
    color: #0a0a0a; }

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #cacaca; }
  .progress.primary .progress-meter {
    background-color: #3999b4; }
  .progress.secondary .progress-meter {
    background-color: #d85a31; }
  .progress.success .progress-meter {
    background-color: #3adb76; }
  .progress.warning .progress-meter {
    background-color: #ffae00; }
  .progress.alert .progress-meter {
    background-color: #cc4b37; }

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #3999b4; }

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #fefefe;
  white-space: nowrap; }

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e6e6e6;
  cursor: pointer;
  user-select: none;
  touch-action: none; }

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #cacaca;
  transition: all 0.2s ease-in-out; }
  .slider-fill.is-dragging {
    transition: all 0s linear; }

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #3999b4;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation; }
  [data-whatinput='mouse'] .slider-handle {
    outline: 0; }
  .slider-handle:hover {
    background-color: #308299; }
  .slider-handle.is-dragging {
    transition: all 0s linear; }

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed; }

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1); }
  .slider.vertical .slider-fill {
    top: 0;
    width: 0.5rem;
    max-height: 100%; }
  .slider.vertical .slider-handle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1.4rem;
    height: 1.4rem;
    transform: translateX(-50%); }

.sticky-container {
  position: relative; }

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed;
  z-index: 5;
  width: 100%; }
  .sticky.is-stuck.is-at-top {
    top: 0; }
  .sticky.is-stuck.is-at-bottom {
    bottom: 0; }

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto; }
  .sticky.is-anchored.is-at-bottom {
    bottom: 0; }

html.is-reveal-open {
  position: fixed;
  width: 100%;
  overflow-y: scroll; }
  html.is-reveal-open body {
    overflow: hidden; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      min-height: 0; } }
  .reveal .column {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      width: 600px;
      max-width: 80rem; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal.tiny {
      width: 30%;
      max-width: 80rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.small {
      width: 50%;
      max-width: 80rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.large {
      width: 90%;
      max-width: 80rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 39.9375em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.93333rem;
  font-weight: bold;
  color: #fefefe;
  user-select: none; }

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0; }

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #cacaca;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer; }
  input + .switch-paddle {
    margin: 0; }
  .switch-paddle::after {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate3d(0, 0, 0);
    border-radius: 0;
    background: #fefefe;
    transition: all 0.25s ease-out;
    content: ''; }
  input:checked ~ .switch-paddle {
    background: #3999b4; }
    input:checked ~ .switch-paddle::after {
      left: 2.25rem; }
  [data-whatinput='mouse'] input:focus ~ .switch-paddle {
    outline: 0; }

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.switch-active {
  left: 8%;
  display: none; }
  input:checked + label > .switch-active {
    display: block; }

.switch-inactive {
  right: 15%; }
  input:checked + label > .switch-inactive {
    display: none; }

.switch.tiny {
  height: 1.5rem; }
  .switch.tiny .switch-paddle {
    width: 3rem;
    height: 1.5rem;
    font-size: 0.66667rem; }
  .switch.tiny .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1rem;
    height: 1rem; }
  .switch.tiny input:checked ~ .switch-paddle::after {
    left: 1.75rem; }

.switch.small {
  height: 1.75rem; }
  .switch.small .switch-paddle {
    width: 3.5rem;
    height: 1.75rem;
    font-size: 0.8rem; }
  .switch.small .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1.25rem;
    height: 1.25rem; }
  .switch.small input:checked ~ .switch-paddle::after {
    left: 2rem; }

.switch.large {
  height: 2.5rem; }
  .switch.large .switch-paddle {
    width: 5rem;
    height: 2.5rem;
    font-size: 1.06667rem; }
  .switch.large .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 2rem;
    height: 2rem; }
  .switch.large input:checked ~ .switch-paddle::after {
    left: 2.75rem; }

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0; }
  thead,
  tbody,
  tfoot {
    border: 1px solid #f1f1f1;
    background-color: #fefefe; }
  caption {
    padding: 0.53333rem 0.66667rem 0.66667rem;
    font-weight: bold; }
  thead {
    background: #f8f8f8;
    color: #0a0a0a; }
  tfoot {
    background: #f1f1f1;
    color: #0a0a0a; }
  thead tr,
  tfoot tr {
    background: transparent; }
  thead th,
  thead td,
  tfoot th,
  tfoot td {
    padding: 0.53333rem 0.66667rem 0.66667rem;
    font-weight: bold;
    text-align: left; }
  tbody th,
  tbody td {
    padding: 0.53333rem 0.66667rem 0.66667rem; }
  tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: #f1f1f1; }
  table.unstriped tbody {
    background-color: #fefefe; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 1px solid #f1f1f1;
      background-color: #fefefe; }

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #f3f3f3; }

table.hover tfoot tr:hover {
  background-color: #ececec; }

table.hover tbody tr:hover {
  background-color: #f9f9f9; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ececec; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.tabs {
  margin: 0;
  border: 1px solid #e6e6e6;
  background: #fefefe;
  list-style-type: none; }
  .tabs::before, .tabs::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .tabs::after {
    clear: both; }

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto; }

.tabs.simple > li > a {
  padding: 0; }
  .tabs.simple > li > a:hover {
    background: transparent; }

.tabs.primary {
  background: #3999b4; }
  .tabs.primary > li > a {
    color: #0a0a0a; }
    .tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
      background: #3691ab; }

.tabs-title {
  float: left; }
  .tabs-title > a {
    display: block;
    padding: 1.25rem 1.5rem;
    font-size: 0.8rem;
    line-height: 1;
    color: #3999b4; }
    .tabs-title > a:hover {
      background: #fefefe;
      color: #31849b; }
    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
      background: #e6e6e6;
      color: #3999b4; }

.tabs-content {
  border: 1px solid #e6e6e6;
  border-top: 0;
  background: #fefefe;
  color: #0a0a0a;
  transition: all 0.5s ease; }

.tabs-content.vertical {
  border: 1px solid #e6e6e6;
  border-left: 0; }

.tabs-panel {
  display: none;
  padding: 1rem; }
  .tabs-panel.is-active {
    display: block; }

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #fefefe;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
  line-height: 0; }

a.thumbnail {
  transition: box-shadow 200ms ease-out; }
  a.thumbnail:hover, a.thumbnail:focus {
    box-shadow: 0 0 6px 1px rgba(57, 153, 180, 0.5); }
  a.thumbnail image {
    box-shadow: none; }

.title-bar {
  padding: 0.5rem;
  background: #0a0a0a;
  color: #fefefe;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left,
.title-bar-right {
  flex: 1 1 0px; }

.title-bar-right {
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: bold;
  cursor: help; }

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #0a0a0a;
  font-size: 80%;
  color: #fefefe; }
  .tooltip::before {
    position: absolute; }
  .tooltip.bottom::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent #0a0a0a;
    bottom: 100%; }
  .tooltip.bottom.align-center::before {
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.top::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #0a0a0a transparent transparent;
    top: 100%;
    bottom: auto; }
  .tooltip.top.align-center::before {
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.left::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0a0a0a;
    left: 100%; }
  .tooltip.left.align-center::before {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%); }
  .tooltip.right::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #0a0a0a transparent transparent;
    right: 100%;
    left: auto; }
  .tooltip.right.align-center::before {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%); }
  .tooltip.align-top::before {
    bottom: auto;
    top: 10%; }
  .tooltip.align-bottom::before {
    bottom: 10%;
    top: auto; }
  .tooltip.align-left::before {
    left: 10%;
    right: auto; }
  .tooltip.align-right::before {
    left: auto;
    right: 10%; }

.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  flex-wrap: wrap; }
  .top-bar,
  .top-bar ul {
    background-color: transparent; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button, .top-bar input.header-menu__item {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%; }
  @media print, screen and (min-width: 40em) {
    .top-bar {
      flex-wrap: nowrap; }
      .top-bar .top-bar-left {
        flex: 1 1 auto;
        margin-right: auto; }
      .top-bar .top-bar-right {
        flex: 0 1 auto;
        margin-left: auto; } }
  @media screen and (max-width: 63.9375em) {
    .top-bar.stacked-for-medium {
      flex-wrap: wrap; }
      .top-bar.stacked-for-medium .top-bar-left,
      .top-bar.stacked-for-medium .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  @media screen and (max-width: 74.9375em) {
    .top-bar.stacked-for-large {
      flex-wrap: wrap; }
      .top-bar.stacked-for-large .top-bar-left,
      .top-bar.stacked-for-large .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }

.top-bar-title {
  flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0; }

.top-bar-left,
.top-bar-right {
  flex: 0 0 auto; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1; }

.clearfix::after {
  clear: both; }

.align-left {
  justify-content: flex-start; }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-left.vertical.menu > li > a {
  justify-content: flex-start; }

.align-right.vertical.menu > li > a {
  justify-content: flex-end; }

.align-center.vertical.menu > li > a {
  justify-content: center; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

.accessibility-bar {
  background: #fefefe;
  display: none;
  padding: 0.66667rem 0; }

button:focus {
  outline: auto; }

.button, .header-menu__item {
  border: 2px solid #3999b4;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 1em;
  text-transform: uppercase; }
  @media screen and (max-width: 39.9375em) {
    .button, .header-menu__item {
      letter-spacing: 1.5px;
      padding: 1.33333rem; } }
  .button:focus, .header-menu__item:focus, .button:hover, .header-menu__item:hover {
    color: #fefefe; }
  .button span, .header-menu__item span,
  .button i,
  .header-menu__item i,
  .button svg,
  .header-menu__item svg {
    margin-right: 0.33333rem; }
  .button--large {
    padding: 1.33333rem 4.66667rem; }
  .button.white, .header-menu__item {
    border-color: #fefefe;
    color: #fefefe; }
    .button.white:hover, .header-menu__item:hover {
      background-color: #fefefe;
      color: #3999b4; }
      .button.white:hover path, .header-menu__item:hover path {
        fill: #3999b4 !important; }
  .button.no-margin, .no-margin.header-menu__item {
    margin-bottom: 0; }
  .button.main, .main.header-menu__item {
    display: flex;
    align-items: center;
    gap: 5px; }
  .button--secondary {
    border: 2px solid #ff5c14;
    background-color: #ff5c14;
    color: white; }
    .button--secondary:hover {
      background-color: white;
      color: #ff5c14; }
    .button--secondary:focus {
      background-color: #ff5c14;
      color: white; }
  .button--large {
    font-size: 22px;
    padding: 0.66667rem 2rem;
    letter-spacing: 2px; }

.cookie-bar {
  background: #fefefe;
  bottom: 0;
  display: none;
  left: 0;
  padding: 1rem 0;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 100; }
  .cookie-bar a {
    color: #0a0a0a;
    text-decoration: underline; }
  .cookie-bar__cta.button, .cookie-bar__cta.header-menu__item {
    cursor: pointer;
    margin: 0 5px;
    padding: 0.66667rem; }
    @media screen and (max-width: 63.9375em) {
      .cookie-bar__cta.button, .cookie-bar__cta.header-menu__item {
        margin: 10px 5px 0 5px; } }

/* sass-lint:disable no-duplicate-properties */
/**
* OPEN SANS
**/
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/OpenSans-Light.eot");
  src: url("../fonts/OpenSans-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Light.woff2") format("woff2"), url("../fonts/OpenSans-Light.woff") format("woff"), url("../fonts/OpenSans-Light.ttf") format("truetype"), url("../fonts/OpenSans-Light.svg#OpenSans-Light") format("svg"); }

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/OpenSans-Bold.eot");
  src: url("../fonts/OpenSans-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Bold.woff2") format("woff2"), url("../fonts/OpenSans-Bold.woff") format("woff"), url("../fonts/OpenSans-Bold.ttf") format("truetype"), url("../fonts/OpenSans-Bold.svg#OpenSans-Bold") format("svg"); }

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/OpenSans.eot");
  src: url("../fonts/OpenSans.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans.woff2") format("woff2"), url("../fonts/OpenSans.woff") format("woff"), url("../fonts/OpenSans.ttf") format("truetype"), url("../fonts/OpenSans.svg#OpenSans") format("svg"); }

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/OpenSans-Semibold.eot");
  src: url("../fonts/OpenSans-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Semibold.woff2") format("woff2"), url("../fonts/OpenSans-Semibold.woff") format("woff"), url("../fonts/OpenSans-Semibold.ttf") format("truetype"), url("../fonts/OpenSans-Semibold.svg#OpenSans-Semibold") format("svg"); }

form input:-webkit-autofill,
form input:-webkit-autofill:hover,
form input:-webkit-autofill:focus,
form input:-webkit-autofill,
form textarea:-webkit-autofill,
form textarea:-webkit-autofill:hover,
form textarea:-webkit-autofill:focus,
form select:-webkit-autofill,
form select:-webkit-autofill:hover,
form select:-webkit-autofill:focus {
  -webkit-text-fill-color: #0a0a0a;
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

form [type='text'],
form [type='password'],
form [type='date'],
form [type='datetime'],
form [type='datetime-local'],
form [type='month'],
form [type='week'],
form [type='email'],
form [type='number'],
form [type='search'],
form [type='tel'],
form [type='time'],
form [type='url'],
form [type='color'],
form select,
form textarea {
  box-shadow: none;
  display: block;
  font-size: 0.93333rem;
  margin-bottom: 0; }

form .custom-radio,
form .custom-checkbox {
  display: inline-block;
  margin-left: 1rem; }
  form .custom-radio .outer,
  form .custom-checkbox .outer {
    background: #fefefe;
    border: 1px solid #e6e6e6;
    display: inline-block;
    height: 2.26667rem;
    margin-right: 0.66667rem;
    padding: 0.2rem;
    vertical-align: middle;
    width: 2.26667rem; }
  form .custom-radio .inner,
  form .custom-checkbox .inner {
    background: #3999b4;
    display: inline-block;
    height: 1.73333rem;
    opacity: 0;
    transition: opacity 300ms ease;
    width: 1.73333rem; }
  form .custom-radio input,
  form .custom-checkbox input {
    opacity: 0;
    position: absolute;
    text-indent: -9999px; }
    form .custom-radio input:checked ~ label .outer .inner,
    form .custom-checkbox input:checked ~ label .outer .inner {
      opacity: 1; }
    form .custom-radio input:focus ~ label .outer,
    form .custom-checkbox input:focus ~ label .outer {
      box-shadow: 0 0 10px #3999b4; }
  form .custom-radio label,
  form .custom-checkbox label {
    display: inline-block;
    margin: 0;
    vertical-align: middle; }
  form .custom-radio.small .outer,
  form .custom-checkbox.small .outer {
    height: 1.6rem;
    padding: 0.06667rem 0.2rem;
    width: 1.6rem; }
    form .custom-radio.small .outer .inner,
    form .custom-checkbox.small .outer .inner {
      height: 1.06667rem;
      width: 1.06667rem; }

form .custom-radio .outer,
form .custom-radio .inner {
  border-radius: 2rem; }

form .custom-radio .outer {
  height: 1.6rem;
  padding: 0.13333rem;
  width: 1.6rem; }

form .custom-radio .inner {
  height: 1.2rem;
  width: 1.2rem; }

form .form-row {
  margin-bottom: 0.66667rem; }
  @media screen and (max-width: 39.9375em) {
    form .form-row > div > .text-right {
      text-align: left; } }
  form .form-row input[type='text'], form .form-row input[type='email'], form .form-row input[type='tel'], form .form-row input[type='password'] {
    height: 2.13333rem; }
  form .form-row label {
    margin-right: 0.66667rem; }
  form .form-row > .custom-checkbox {
    margin: 0.66667rem 0; }
  form .form-row .custom-select {
    position: relative; }
    form .form-row .custom-select::after {
      background: #3999b4 no-repeat 50% 50%;
      background-image: url("../images/layout/arrow-down-white.svg");
      background-size: 1.33333rem;
      content: "";
      height: 2.13333rem;
      position: absolute;
      right: 0;
      top: 0;
      width: 2.13333rem;
      pointer-events: none; }
    form .form-row .custom-select select {
      background: #fefefe;
      border-radius: 0;
      height: 2.13333rem;
      margin-bottom: 0;
      padding: 0 2.4rem 0 0.66667rem; }
    form .form-row .custom-select dt {
      border: 1px solid #e1e1e1;
      font-weight: normal;
      height: 2.13333rem;
      line-height: 2.13333rem;
      margin-bottom: 0;
      padding-left: 0.66667rem; }
      form .form-row .custom-select dt .multiSel {
        color: #0a0a0a; }
    form .form-row .custom-select .dropdown {
      margin-bottom: 0; }
    form .form-row .custom-select .dropdown dd .slide {
      background-color: #fefefe;
      border-bottom: 1px solid #e1e1e1;
      border-left: 1px solid #e1e1e1;
      border-right: 1px solid #e1e1e1;
      display: none;
      overflow: auto;
      padding: 2px 15px 2px 5px;
      position: absolute;
      width: 100%;
      z-index: 10; }
      form .form-row .custom-select .dropdown dd .slide .custom-checkbox {
        display: block;
        margin-left: 0; }
    form .form-row .custom-select .custom-checkbox {
      margin-left: 0; }
  form .form-row textarea {
    box-shadow: none;
    margin-bottom: 0; }

form .form-filters select {
  background-image: url("../images/layout/arrow-down.svg");
  background-position: calc(100% + 26px) 55%;
  background-size: 1.33333rem;
  height: 3.33333rem;
  padding: 0.66667rem 2.4rem 0.66667rem 1.33333rem; }

form .button-group {
  display: table;
  margin: 0;
  table-layout: fixed;
  width: calc(100%); }
  form .button-group::after, form .button-group::before {
    content: none; }
  form .button-group input {
    display: none; }
  form .button-group > div {
    display: table-cell;
    vertical-align: middle; }
    form .button-group > div:first-child {
      padding-right: 0.13333rem; }
    form .button-group > div:last-child {
      padding-left: 0.13333rem; }
  form .button-group label {
    border: 1px solid #e1e1e1;
    color: #0a0a0a;
    font-weight: normal;
    letter-spacing: 0;
    margin: 0;
    padding: 0.6rem 0.66667rem;
    text-transform: none;
    width: 100%; }
    form .button-group label:last-of-type {
      margin-right: 0; }
  form .button-group input:checked + label, form .button-group input:checked + label:active {
    background-color: #3999b4;
    color: #fefefe; }

form .form-recaptcha {
  margin: 2rem 0 1rem;
  text-align: center; }
  form .form-recaptcha .g-recaptcha {
    display: inline-block; }

form .form-submit {
  text-align: center; }
  form .form-submit .button, form .form-submit .header-menu__item {
    width: 20.26667rem; }

form .form-error {
  display: block;
  margin-bottom: 0; }
  form .form-error ul {
    list-style: none;
    margin: 0;
    padding: 0.66667rem 0; }

body,
html {
  height: auto;
  min-height: 100%; }

a {
  transition: color 300ms ease-out; }

main {
  position: relative; }

.diagonal {
  overflow: hidden;
  position: relative; }
  .diagonal::before, .diagonal::after {
    background: #f0f8fc;
    box-shadow: 0 0 30px #0a0a0a;
    height: 20vw;
    left: -25%;
    margin-top: -10vw;
    position: absolute;
    transform-origin: 0 0;
    width: 150%;
    z-index: 10; }
  .diagonal--bottom {
    padding-bottom: 12vw; }
    .diagonal--bottom::before {
      content: '';
      top: 100%;
      transform: rotate(3deg); }
  .diagonal--top {
    padding-top: 12vw; }
    .diagonal--top::after {
      content: '';
      top: 0;
      transform: rotate(-3deg); }
  .diagonal--nopadding {
    padding: 0; }
  .diagonal--white::before, .diagonal--white::after {
    background: #fefefe; }
  .diagonal--top-blue::after {
    background: #f0f8fc; }
  .diagonal--top-white::after {
    background: #fefefe; }
  .diagonal--bottom-blue::before {
    background: #f0f8fc; }
  .diagonal--bottom-white::before {
    background: #fefefe; }

.title-centered {
  margin: 2rem 0; }
  .title-centered hr {
    background: #3999b4;
    border: 0;
    height: 1px;
    margin: 0;
    padding: 0; }
  .title-centered .shrink {
    color: #3999b4;
    font-weight: bold;
    padding: 0 1rem; }

.section__main-title {
  height: 219px;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media screen and (min-width: 40em) {
    .section__main-title {
      height: 320px; } }

.section__title {
  color: #3999b4;
  display: block;
  font-size: 2.8rem;
  font-weight: 300;
  margin-bottom: 3rem;
  text-align: left; }
  .section__title.orange {
    color: #d85a31; }
  @media screen and (max-width: 39.9375em) {
    .section__title {
      font-size: 1.73333rem; } }
  .section__title > span {
    display: block;
    line-height: 1em; }
  .section__title .small {
    font-size: 2.13333rem;
    font-weight: 300; }
  .section__title .big {
    font-size: 4.66667rem;
    margin-bottom: 0.66667rem; }
    @media screen and (max-width: 89.9375em) {
      .section__title .big {
        font-size: 3.33333rem; } }
    @media screen and (max-width: 39.9375em) {
      .section__title .big {
        font-size: 2.66667rem; } }
  @media screen and (min-width: 40em) {
    .section__title .padding-left {
      padding-left: 5.33333rem; } }

.primary-color {
  color: #0a0a0a; }

.secondary-color {
  color: #0a0a0a; }

.tertiary-color {
  color: #275d6c; }

.error {
  color: #ffae00; }

.bg-blue {
  background-color: #f0f8fc; }

.bg-white {
  background-color: #fefefe; }

@font-face {
  font-family: "font-icon";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/font-icon.eot");
  src: url("../fonts/font-icon.eot?#iefix") format("eot"), url("../fonts/font-icon.woff") format("woff"), url("../fonts/font-icon.ttf") format("truetype"), url("../fonts/font-icon.svg#font-icon") format("svg"); }

.icon,
[class^="icon-"],
[class*=" icon-"] {
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  /* End Better Font Rendering ======= */
  display: inline-block;
  font-family: "font-icon" !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  position: relative;
  speak: none;
  text-transform: none;
  vertical-align: middle; }

.round-icon {
  background-color: #3999b4;
  border-radius: 1.6rem;
  color: #d85a31;
  display: inline-block;
  font-size: 1.33333rem;
  height: 3.2rem;
  line-height: 3.2rem;
  text-align: center;
  transition: background-color 300ms ease-out, border-color 300ms ease-out;
  width: 3.2rem; }
  .round-icon i {
    color: #d85a31;
    line-height: 0.2rem;
    vertical-align: middle; }

.circle-icon {
  background-color: transparent;
  border-radius: 24px;
  color: inherit;
  display: inline-block;
  font-size: 1.33333rem;
  height: 48px;
  line-height: 48px;
  text-align: center;
  transition: background-color 300ms ease-out, border-color 300ms ease-out;
  width: 48px;
  background-color: transparent;
  border: 2px solid #d85a31; }
  .circle-icon i {
    color: inherit;
    line-height: 45px;
    vertical-align: middle; }

a.link-icon {
  text-decoration: none; }
  a.link-icon.circle-icon {
    background-color: transparent;
    border-radius: 24px;
    color: inherit;
    display: inline-block;
    font-size: 1.33333rem;
    height: 48px;
    line-height: 48px;
    text-align: center;
    transition: background-color 300ms ease-out, border-color 300ms ease-out;
    width: 48px;
    background-color: transparent;
    border: 2px solid #3999b4; }
    a.link-icon.circle-icon i {
      color: inherit;
      line-height: 45px;
      vertical-align: middle; }
    a.link-icon.circle-icon:hover {
      background-color: #3999b4;
      border-radius: 24px;
      color: #3999b4;
      display: inline-block;
      font-size: 1.33333rem;
      height: 48px;
      line-height: 48px;
      text-align: center;
      transition: background-color 300ms ease-out, border-color 300ms ease-out;
      width: 48px;
      text-decoration: none; }
      a.link-icon.circle-icon:hover i {
        color: #3999b4;
        line-height: 45px;
        vertical-align: middle; }
  a.link-icon.round-icon:hover {
    background-color: #3999b4;
    border-radius: 24px;
    color: #3999b4;
    display: inline-block;
    font-size: 1.33333rem;
    height: 48px;
    line-height: 48px;
    text-align: center;
    transition: background-color 300ms ease-out, border-color 300ms ease-out;
    width: 48px;
    text-decoration: none; }
    a.link-icon.round-icon:hover i {
      color: #3999b4;
      line-height: 45px;
      vertical-align: middle; }

.icon-lg {
  font-size: 1.3333333333333333em;
  line-height: .75em;
  vertical-align: -15%; }

.icon-2x {
  font-size: 2em; }

.icon-3x {
  font-size: 3em; }

.icon-4x {
  font-size: 4em; }

.icon-5x {
  font-size: 5em; }

.icon-fw {
  text-align: center;
  width: 1.2857142857142858em; }

.icon-arrow-left::before {
  content: "\EA01"; }

.icon-arrow-right::before {
  content: "\EA02"; }

.icon-bell::before {
  content: "\EA03"; }

.icon-blind::before {
  content: "\EA04"; }

.icon-facebook::before {
  content: "\EA05"; }

.icon-file::before {
  content: "\EA06"; }

.icon-mail::before {
  content: "\EA07"; }

.icon-home::before {
  content: "\EA08"; }

.icon-hourray::before {
  content: "\EA09"; }

.icon-idcard::before {
  content: "\EA0A"; }

.icon-linkeding::before {
  content: "\EA0B"; }

.icon-marker::before {
  content: "\EA0C"; }

.icon-twitter::before {
  content: "\EA0D"; }

.icon-magnifier::before {
  content: "\EA0E"; }

.icon-chevron::before {
  content: "\EA0F"; }

.icon-instagram::before {
  content: "\EA10"; }

.icon-vimeo::before {
  content: "\EA11"; }

.icon-youtube::before {
  content: "\EA1A"; }

.orbit {
  position: relative; }
  .orbit button:focus {
    cursor: pointer;
    outline: auto; }
  .orbit-bullets {
    bottom: 5.33333rem;
    left: 0;
    position: absolute;
    width: 100%; }
    .orbit-bullets button {
      border: 3px solid #fefefe;
      box-shadow: 0 0 1px #e6e6e6; }
  .orbit-controls button {
    background: rgba(254, 254, 254, 0.5);
    color: #3999b4;
    font-size: 4rem;
    line-height: 0; }

.pagination {
  margin: 2rem 0 4rem;
  text-align: center; }
  .pagination-previous,
  .pagination-previous a, .pagination-next,
  .pagination-next a {
    font-weight: bold;
    text-transform: uppercase; }
  .pagination-previous a, .pagination-next a {
    color: #3999b4; }
    .pagination-previous a:hover, .pagination-next a:hover {
      color: #d85a31; }
  .pagination-previous {
    float: left; }
  .pagination-next {
    float: right; }
  .pagination-item {
    border-right: 1px solid #0a0a0a;
    line-height: 1em; }
    .pagination-item.last {
      border: 0; }
    .pagination-item a {
      color: #0a0a0a; }
    .pagination-item.current {
      font-weight: bold; }

.thumb {
  background: #fefefe;
  height: calc(100% - 50px);
  margin-bottom: 3.33333rem; }
  .thumb--shadow {
    box-shadow: 0 0 120px -30px #0a0a0a; }
  .thumb a {
    color: #0a0a0a; }
  .thumb__picture {
    background: #0a0a0a;
    position: relative; }
    .thumb__picture img {
      opacity: .7;
      transition: opacity 300ms ease-out; }
  .thumb__meta {
    color: #3999b4;
    font-size: 0.8rem;
    margin: 0.66667rem 1rem; }
  .thumb__title {
    bottom: 0;
    color: #fefefe;
    font-size: 1.33333rem;
    font-weight: bold;
    left: 0;
    line-height: 1.2em;
    padding: 0.33333rem;
    position: absolute; }
    @media screen and (max-width: 39.9375em) {
      .thumb__title {
        font-size: 1.2rem; } }
    .thumb--shadow .thumb__title {
      color: #0a0a0a;
      font-size: 1.6rem;
      line-height: 1.1em;
      margin: 0.66667rem 1rem;
      padding: 0;
      position: relative; }
      @media screen and (max-width: 39.9375em) {
        .thumb--shadow .thumb__title {
          font-size: 1.33333rem; } }
  .thumb__excerpt {
    padding: 1rem; }
    @media screen and (max-width: 39.9375em) {
      .thumb__excerpt {
        font-size: 0.86667rem; } }
    .thumb--shadow .thumb__excerpt {
      margin: 0.66667rem 1rem;
      padding: 0; }
  .thumb__cta {
    color: #3b8da5;
    font-weight: bold;
    padding: 0 0 2rem;
    text-align: center;
    text-transform: uppercase; }
    .thumb--shadow .thumb__cta {
      padding: 1rem 0 2rem; }
    .thumb__cta i {
      font-size: 0.8rem; }
  .thumb:hover .thumb__cta {
    text-decoration: underline; }
  .thumb:hover img {
    opacity: 1; }

body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline; }

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Open Sans", Helvetica, Roboto, Arial, sans-serif;
  font-weight: 300; }

.wysiwyg-content figure,
.wysiwyg-content ul,
.wysiwyg-content p {
  margin-bottom: 2rem; }

.wysiwyg-content h2 {
  font-size: 2.66667rem;
  font-weight: bold;
  line-height: 1em;
  margin: 2.66667rem 0; }

.wysiwyg-content h3 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1em;
  margin: 2rem 0; }

.wysiwyg-content h4 {
  font-size: 1.33333rem;
  font-weight: bold;
  line-height: 1em;
  margin: 1.33333rem 0; }

.wysiwyg-content .aligncenter {
  display: block;
  margin: auto; }

.wysiwyg-content .alignleft {
  float: left;
  margin-bottom: 0.2rem;
  margin-right: 0.66667rem; }

.wysiwyg-content blockquote p {
  color: #fefefe; }

.wysiwyg-content .wp-caption-text {
  color: #3999b4;
  font-size: 0.93333rem;
  font-style: italic;
  margin: 1.66667rem 0; }

.contact-index__header {
  background: #8a8a8a url("../images/contact/bg-contact.jpg") no-repeat 50% 50%;
  background-size: cover;
  color: #fefefe;
  padding: 10rem 0 6.66667rem;
  text-align: center; }
  .contact-index__header .section__title {
    color: #fefefe;
    display: inline-block;
    padding: 0 1rem; }

.contact-index__content {
  padding: 1.66667rem 0 3.33333rem; }

.contact-index__social {
  margin: 1.66667rem 0; }
  .contact-index__social hr {
    background-color: #3999b4;
    border: 0;
    height: 0.06667rem;
    margin: 0;
    padding: 0; }
  .contact-index__social .menu {
    padding: 0 0.66667rem; }
    .contact-index__social .menu a {
      padding: 0 0.66667rem; }
    .contact-index__social .menu i {
      font-size: 2.93333rem; }

.contact-index__bottom {
  background: #f0f8fc;
  margin: 3rem 0;
  padding: 3.33333rem 2rem;
  text-align: center; }
  @media screen and (min-width: 40em) {
    .contact-index__bottom--block {
      padding: 0 3rem; } }
  .contact-index__bottom--title {
    color: #3999b4;
    font-size: 2.8rem;
    font-weight: 300;
    margin-bottom: 2rem; }

.contact-index .tabs {
  border: 0;
  margin: 2rem 0;
  text-align: center; }
  .contact-index .tabs-title {
    display: inline-block;
    float: none;
    margin: 0 1rem; }
  .contact-index .tabs-content {
    border: 0; }
  .contact-index .tabs-panel {
    padding: 2rem 0; }

.contact-index .tabs-title > a:focus, .contact-index .tabs-title > a[aria-selected='true'] {
  background: #3999b4;
  color: #fefefe; }

.contact-index__contact-form textarea {
  height: 14rem; }

.contact-index__contact-form .form-newsletter {
  margin-top: 3rem; }
  .contact-index__contact-form .form-newsletter .custom-radio {
    margin-left: 0; }

.contact-index__admission-form form {
  margin-top: 2rem; }

.contact-index__admission-form textarea {
  height: 14rem; }

.contact-index__admission-form .custom-radio {
  display: block;
  margin-bottom: 0.66667rem;
  margin-left: 0; }

.contact-index__required {
  font-size: 0.8rem;
  margin-bottom: 2rem; }

.contact-index__rgpd {
  font-size: 0.86667rem; }

.contact-index__notification {
  color: #3999b4;
  font-weight: bold;
  margin-top: 1rem; }
  .contact-index__notification i {
    font-size: 3.33333rem;
    margin-right: 1.33333rem; }

.politique-cookie {
  margin-bottom: 3rem; }

.block-carousel {
  padding: 1.33333rem 0; }
  .block-carousel .orbit-container {
    max-height: 70rem; }

.block-highlight {
  background-size: cover;
  background-position: center;
  padding: 18.66667rem 0; }
  @media screen and (max-width: 63.9375em) {
    .block-highlight {
      padding: 11.66667rem 0; } }
  @media screen and (max-width: 39.9375em) {
    .block-highlight {
      padding: 8.33333rem 0; } }
  .block-highlight__overlay {
    content: "";
    background-color: #ff5c14;
    opacity: 0.5;
    filter: contrast(175%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }
  .block-highlight__content {
    position: relative;
    z-index: 2; }
  .block-highlight__text {
    font-size: 3.2rem;
    font-weight: 300;
    color: #fefefe;
    text-align: center; }
    @media screen and (max-width: 63.9375em) {
      .block-highlight__text {
        font-size: 2.4rem; } }
    @media screen and (max-width: 39.9375em) {
      .block-highlight__text {
        font-size: 2.13333rem; } }

.block-text {
  padding-bottom: 3rem; }
  .block-text a {
    text-decoration: underline; }

.content-page__header {
  background: #8a8a8a no-repeat 50% 50%;
  background-size: cover;
  color: #fefefe;
  margin-bottom: 2rem;
  padding: 10rem 0 6.66667rem;
  position: relative;
  text-align: center; }
  @media screen and (max-width: 89.9375em) {
    .content-page__header {
      padding: 6.66667rem 0 3.33333rem; } }
  .content-page__header::after {
    background: rgba(10, 10, 10, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9; }
  .content-page__header .section__title {
    color: #fefefe;
    display: inline-block;
    padding: 0 1rem;
    position: relative;
    z-index: 10;
    text-align: center; }
  @media screen and (max-width: 63.9375em) {
    .content-page__header--with-menu {
      padding: 3.33333rem 0 0; } }

.cultureh-index__header {
  background: #8a8a8a url("../images/culture_h/bg-header.jpg") no-repeat 50% 50%;
  background-size: cover;
  color: #fefefe;
  padding-top: 8rem; }
  .cultureh-index__header::before {
    padding-bottom: 16vw;
    transform: rotate(-3deg); }
  .cultureh-index__header .grid-container {
    margin-bottom: 4rem; }
  .cultureh-index__header .section__title {
    color: #fefefe;
    margin-top: 1rem;
    padding: 0 1rem; }

.cultureh-index__mecenat {
  background: #f0f8fc;
  color: #398195;
  margin-top: -6vw;
  position: relative;
  z-index: 1000; }
  .cultureh-index__mecenat .title-mecene {
    color: #3999b4;
    font-weight: bold;
    margin: 1rem 0; }
  .cultureh-index__mecenat img {
    margin: 2rem 0; }
  .cultureh-index__mecenat h3 {
    color: #398195;
    margin-top: 2rem; }

.cultureh-index__bottom {
  background: url("../images/culture_h/bg-gift.jpg") no-repeat 50% 100%;
  background-color: #398195;
  background-size: 100% auto;
  box-shadow: inset 0 -25px 25px -25px #2b6170;
  color: #fefefe;
  padding: 13.33333rem 0 6.66667rem; }
  .cultureh-index__bottom::after {
    transform: rotate(3deg); }
  .cultureh-index__bottom .section__title {
    color: #fefefe; }

.cultureh-index__project .odd {
  margin-top: 2rem; }

.cultureh-index__gift h3 {
  font-weight: bold;
  margin-bottom: 0.66667rem; }

.cultureh-index__gift a:not(.button):not(.header-menu__item) {
  color: #fefefe;
  text-decoration: underline; }

/* Mouse Icon */
@keyframes scroll-ani {
  0% {
    opacity: 0;
    top: 30%; }
  15% {
    opacity: 1;
    top: 30%; }
  30% {
    opacity: 1;
    top: 60%; }
  45% {
    opacity: 0;
    top: 60%; }
  100% {
    opacity: 0;
    top: 30%; } }

.mouse-scroll {
  color: #fefefe;
  display: inline-block;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.86667rem;
  font-weight: normal;
  letter-spacing: 0.13333rem;
  line-height: 1.2rem;
  margin-top: 2rem;
  overflow: hidden;
  text-decoration: none; }

.mouse-scroll .mouse {
  border: 2px solid #fefefe;
  border-radius: 0.8rem;
  box-sizing: border-box;
  display: block;
  height: 2.46667rem;
  margin: 0 auto 0.66667rem;
  position: relative;
  width: 1.6rem; }

.mouse-scroll .mouse .mouse-movement {
  animation: scroll-ani 4s linear infinite;
  background: #fefefe;
  border-radius: 100%;
  display: block;
  height: 0.26667rem;
  left: 50%;
  margin: -0.2rem 0 0 -0.13333rem;
  position: absolute;
  top: 29%;
  width: 0.26667rem; }

.cell-logo {
  border-radius: 50%;
  overflow: hidden;
  margin: 2rem 0; }
  .cell-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: white;
    margin: 0; }

.donate-index {
  display: flex;
  padding-top: 2.33333rem; }

.home {
  /* Search form */
  /* Numbers */
  /* News */ }
  .home .diagonal::after, .home .diagonal::before {
    background: #fefefe; }
  .home-form .section__title {
    color: #d85a31; }
    @media screen and (max-width: 63.9375em) {
      .home-form .section__title {
        margin-bottom: 2rem; } }
  .home-form .form-label {
    color: #3999b4;
    font-size: 1.2rem;
    margin-bottom: 0.33333rem;
    padding-left: 1.33333rem; }
  .home-form__submit button {
    color: #d85a31;
    cursor: pointer;
    font-weight: bold;
    line-height: 2.13333rem;
    margin: 2rem 0;
    text-transform: uppercase; }
    .home-form__submit button i {
      font-size: 2.13333rem;
      margin-right: 0.66667rem;
      vertical-align: middle; }
  .home-numbers {
    background: #d85a31 url("../images/home/bg-numbers.jpg") no-repeat 50% 50%;
    background-size: cover;
    color: #fefefe; }
  .home-news {
    padding: 0 0; }
    @media screen and (max-width: 63.9375em) {
      .home-news--desktop {
        display: none; } }
    @media screen and (min-width: 64em) {
      .home-news--mobile {
        display: none; } }
    .home-news .odd {
      margin-top: 2rem; }
    .home-news__cta {
      padding: 2rem 1rem 3rem;
      text-align: center; }
    @media screen and (max-width: 63.9375em) {
      .home-news .thumb {
        margin: 4rem 1rem;
        width: 250px; } }
    @media screen and (max-width: 63.9375em) {
      .home-news .section__title {
        margin-bottom: 0; } }
    .home-news__mobile {
      margin: 0;
      width: 100%; }

.reveal-overlay {
  background-color: rgba(10, 10, 10, 0.75);
  z-index: 9999; }

.modal {
  padding: 8rem 3.33333rem 9.33333rem;
  width: auto;
  height: auto;
  max-width: 53.33333rem;
  max-height: 40rem;
  overflow: hidden; }
  .modal:focus {
    outline: none; }
  @media screen and (max-width: 39.9375em) {
    .modal {
      min-height: auto; } }
  .modal::before {
    content: "";
    position: absolute;
    width: 200%;
    height: 200%;
    top: -1%;
    z-index: -1;
    background: url("../images/home/modal.png") 0 0 no-repeat; }
    @media screen and (max-width: 50em) {
      .modal::before {
        left: -30%; } }
    @media screen and (max-width: 39.9375em) {
      .modal::before {
        left: -65%; } }
    @media screen and (max-width: 29.0625em) {
      .modal::before {
        left: -75%; } }
  .modal__title {
    font-size: 2.26667rem;
    font-weight: 700;
    color: #123f90; }
  .modal__text {
    margin: 0;
    color: #123f90;
    font-size: 2.6rem;
    line-height: 1.2; }
    .modal__text--bold {
      font-weight: bold; }
  .modal__image {
    position: absolute;
    bottom: 0;
    right: -4.66667rem;
    z-index: -1; }
  .modal__icon {
    font-size: 1rem;
    margin-right: 0 !important; }
  .modal__close-button {
    border-radius: 50%;
    background-color: #3f3f3f;
    color: white;
    width: 30px; }
    .modal__close-button:hover {
      color: white; }
  .modal__button {
    background-color: #ea5156;
    border: 2px solid #ea5156; }
    .modal__button:hover {
      color: #ea5156; }

@media screen and (min-width: 64em) {
  .numbers {
    padding-top: 10rem; } }

.numbers__container {
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.3); }

.numbers__number {
  font-size: 5rem;
  font-weight: bold;
  line-height: 1em; }
  @media screen and (max-width: 63.9375em) {
    .numbers__number {
      font-size: 3.33333rem; } }

.numbers__label {
  font-size: 2rem;
  line-height: 1em; }
  @media screen and (max-width: 63.9375em) {
    .numbers__label {
      font-size: 1.33333rem; } }

.numbers__cta {
  margin-top: 2rem;
  text-align: center; }

.numbers .media-object {
  display: table;
  margin-bottom: 0; }
  @media screen and (max-width: 63.9375em) {
    .numbers .media-object {
      display: block;
      text-align: center; } }
  .numbers .media-object.align-right {
    direction: rtl;
    text-align: right;
    width: 100%; }
    @media screen and (max-width: 63.9375em) {
      .numbers .media-object.align-right {
        float: left;
        text-align: center; } }
  .numbers .media-object-section {
    direction: ltr;
    display: table-cell;
    vertical-align: middle; }
    @media screen and (max-width: 63.9375em) {
      .numbers .media-object-section {
        display: block; } }
    .numbers .media-object-section > img {
      display: inline-block;
      margin: 1rem;
      max-width: 100%; }

.home-slideshow .slick-slider {
  margin: 0; }

.home-slideshow .home-slide {
  background: no-repeat 50% 50%;
  background-color: #8a8a8a;
  background-size: cover;
  padding-bottom: 4vw; }
  .home-slideshow .home-slide::before {
    background: #fefefe; }
  .home-slideshow .home-slide__container {
    margin: 0; }
  .home-slideshow .home-slide__content {
    color: #fefefe;
    height: 41.2rem; }
    @media screen and (max-width: 63.9375em) {
      .home-slideshow .home-slide__content {
        height: auto;
        padding: 3.33333rem 1rem; } }
  .home-slideshow .home-slide__cell {
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.3); }
    .home-slideshow .home-slide__cell--covid19 {
      position: absolute;
      top: 11rem;
      left: 5.33333rem; }
      @media screen and (max-width: 63.9375em) {
        .home-slideshow .home-slide__cell--covid19 {
          position: relative;
          top: 1rem;
          left: 0; } }
      @media screen and (min-width: 64em) {
        .home-slideshow .home-slide__cell--covid19 {
          left: 8rem; } }
      @media screen and (min-width: 84.375em) {
        .home-slideshow .home-slide__cell--covid19 {
          left: 10.66667rem; } }
      @media screen and (min-width: 93.75em) {
        .home-slideshow .home-slide__cell--covid19 {
          left: 12rem; } }
  .home-slideshow .home-slide__title {
    font-size: 3.33333rem;
    font-weight: 700;
    line-height: 1.2em; }
    @media screen and (max-width: 63.9375em) {
      .home-slideshow .home-slide__title {
        font-size: 2rem;
        text-align: center; } }
    .home-slideshow .home-slide__title .padding-text {
      padding-left: 4rem; }
      @media screen and (max-width: 63.9375em) {
        .home-slideshow .home-slide__title .padding-text {
          padding: 0; } }
  .home-slideshow .home-slide__logo {
    padding-left: 5rem; }
  .home-slideshow .home-slide__intro {
    padding: 1.66667rem 3.33333rem 1.66667rem 4rem; }
    @media screen and (max-width: 63.9375em) {
      .home-slideshow .home-slide__intro {
        padding: 1rem;
        text-align: center; } }
    .home-slideshow .home-slide__intro--covid19 {
      padding: 1rem 0;
      max-width: 16.66667rem; }
  .home-slideshow .home-slide__cta {
    padding-left: 4rem; }
    @media screen and (max-width: 63.9375em) {
      .home-slideshow .home-slide__cta {
        padding: 0;
        text-align: center; } }

.home-slideshow .orbit-container {
  overflow: visible; }

.home-slideshow .orbit-bullets {
  bottom: 1.33333rem;
  left: inherit;
  text-align: right;
  z-index: 20; }
  @media screen and (max-width: 63.9375em) {
    .home-slideshow .orbit-bullets {
      display: none; } }
  .home-slideshow .orbit-bullets button {
    background: none;
    border: 0;
    border-radius: 0;
    box-shadow: 0 10px 20px rgba(10, 10, 10, 0.6);
    cursor: pointer;
    height: auto;
    opacity: 1;
    overflow: hidden;
    position: relative;
    width: 20.4rem; }
    .home-slideshow .orbit-bullets button span {
      color: #fefefe;
      display: block;
      font-size: 1.46667rem;
      font-weight: bold;
      left: 0;
      line-height: 2rem;
      margin-top: -1.2rem;
      position: absolute;
      text-align: center;
      top: 50%;
      width: 100%;
      z-index: 10; }

.institutions__header {
  background: #8a8a8a url("../images/institutions/index-header.jpg") no-repeat 50% 50%;
  background-size: cover;
  color: #fefefe;
  padding: 10rem 0 8rem;
  position: relative;
  text-align: center; }
  @media screen and (max-width: 63.9375em) {
    .institutions__header {
      padding: 3.33333rem 0 0; } }
  .institutions__header .section__title {
    color: #fefefe;
    display: inline-block;
    padding: 0 1rem; }
    @media screen and (max-width: 63.9375em) {
      .institutions__header .section__title {
        margin-bottom: 6.66667rem; } }

.institutions__results {
  background: #f0f8fc;
  padding: 3.33333rem 0; }
  .institutions__results .section__title {
    color: #d85a31; }

.institution-index .home-form__submit {
  display: none; }

.tab-filters {
  bottom: 0;
  position: absolute;
  width: 100%; }
  @media screen and (max-width: 63.9375em) {
    .tab-filters {
      background: #fefefe;
      padding: 0.66667rem 0.66667rem 0;
      position: relative; } }
  .tab-filters form {
    display: inline-block;
    position: relative; }
  .tab-filters .menu-centered {
    border-left: 1px solid #fefefe; }
    @media screen and (max-width: 74.9375em) {
      .tab-filters .menu-centered {
        border: 0; }
        .tab-filters .menu-centered > .menu {
          justify-content: left; } }
    .tab-filters .menu-centered .mega-menu > button {
      border: solid #fefefe;
      border-width: 1px 1px 0 0;
      color: #fefefe;
      font-weight: bold;
      padding: 1.66667rem 2.66667rem;
      text-transform: uppercase; }
      @media screen and (max-width: 74.9375em) {
        .tab-filters .menu-centered .mega-menu > button {
          padding: 1.66667rem; } }
      @media screen and (max-width: 63.9375em) {
        .tab-filters .menu-centered .mega-menu > button {
          background: #e1e1e1;
          border: 0;
          border-radius: 4rem;
          color: #3999b4;
          font-weight: normal;
          margin: 0 0.33333rem 0.66667rem;
          padding: 0.66667rem 1rem;
          text-transform: none; } }
    @media screen and (min-width: 64em) {
      .tab-filters .menu-centered li:hover > a {
        background: #fefefe;
        color: #3999b4; } }
  .tab-filters .mega-menu.dropdown-pane {
    border: 0;
    box-shadow: 0 15px 20px #3d3d3d;
    left: 0 !important;
    text-align: left;
    width: 100%; }
    @media screen and (max-width: 63.9375em) {
      .tab-filters .mega-menu.dropdown-pane {
        height: 100%;
        left: 0 !important;
        overflow: auto;
        padding-bottom: 5.33333rem;
        position: fixed;
        top: 0 !important;
        width: 100%;
        z-index: 2200; } }
    .tab-filters .mega-menu.dropdown-pane .custom-checkbox {
      display: block;
      margin-bottom: 0.66667rem; }
      .tab-filters .mega-menu.dropdown-pane .custom-checkbox .outer {
        float: left; }
  .tab-filters__title {
    color: #d85a31;
    font-size: 2.66667rem;
    font-weight: 300;
    line-height: 1em;
    margin-bottom: 1rem; }
    @media screen and (max-width: 63.9375em) {
      .tab-filters__title {
        margin-bottom: 2rem; } }
  .tab-filters__subtitle {
    color: #3999b4;
    font-weight: bold;
    margin-bottom: 1rem; }
  .tab-filters__label {
    display: none; }
  .tab-filters .custom-checkbox {
    margin-left: 0; }
    .tab-filters .custom-checkbox .display-inline-block {
      display: inline-block; }
  .tab-filters__cta {
    padding: 1rem 0;
    text-align: center; }
    @media screen and (max-width: 63.9375em) {
      .tab-filters__cta {
        background: #fefefe;
        bottom: 0;
        box-shadow: 0 0 10px #3d3d3d;
        left: 0;
        position: fixed;
        width: 100%; } }
    .tab-filters__cta .button, .tab-filters__cta .header-menu__item {
      display: inline-block; }
  .tab-filters__close {
    background: none;
    color: #8a8a8a;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 0; }
    @media screen and (min-width: 40em) {
      .tab-filters__close {
        display: none; } }
  .tab-filters__reset {
    background: none;
    color: #8a8a8a;
    float: right; }
    @media screen and (min-width: 40em) {
      .tab-filters__reset {
        display: none; } }

@media screen and (min-width: 64em) {
  .home .tab-filters {
    background: #fefefe;
    padding: 0.66667rem 0.66667rem 0;
    position: relative; }
    .home .tab-filters form {
      display: block; }
    .home .tab-filters .menu-centered {
      border: 0; }
      .home .tab-filters .menu-centered .mega-menu > button {
        background: #e1e1e1;
        border: 0;
        border-radius: 4rem;
        color: #0a0a0a;
        font-weight: normal;
        padding: 1rem 1.33333rem;
        text-align: left;
        text-transform: none;
        width: 100%; }
        .home .tab-filters .menu-centered .mega-menu > button::before {
          color: #3999b4;
          content: "\EA02";
          display: block;
          float: right;
          font-family: 'font-icon';
          font-size: 1.2rem;
          transform: rotate(90deg); }
        .home .tab-filters .menu-centered .mega-menu > button[aria-expanded="true"] {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: 1.5rem;
          border-top-right-radius: 1.5rem; }
      .home .tab-filters .menu-centered li {
        display: block;
        margin: 0 0.66667rem 1.33333rem;
        position: relative;
        text-align: left;
        width: 30%; } }
      @media screen and (min-width: 64em) and (max-width: 74.9375em) {
        .home .tab-filters .menu-centered li {
          width: 46%; } }

@media screen and (min-width: 64em) {
        .home .tab-filters .menu-centered li:hover > a {
          background: #e1e1e1;
          color: #0a0a0a; }
    .home .tab-filters .mega-menu.dropdown-pane {
      background: #e1e1e1;
      border-bottom-left-radius: 1.5rem;
      border-bottom-right-radius: 1.5rem;
      box-shadow: none;
      padding-bottom: 1.5rem;
      z-index: 100; }
      .home .tab-filters .mega-menu.dropdown-pane .grid-x {
        max-height: 50vh;
        overflow: auto; }
      .home .tab-filters .mega-menu.dropdown-pane .cell {
        display: block;
        width: 100%; }
    .home .tab-filters__title {
      display: none; }
    .home .tab-filters__subtitle {
      display: none; }
    .home .tab-filters__label {
      color: #3999b4;
      display: block;
      font-size: 1.2rem;
      margin-bottom: 0.33333rem;
      margin-left: 1.33333rem; }
    .home .tab-filters__cta {
      display: none; } }

.contact__title {
  color: #3999b4; }

.contact__toggle {
  margin-top: 3.33333rem;
  text-align: center; }

.institution__presentation {
  background: #fefefe;
  padding: 2rem; }
  .institution__presentation li:not(:last-child) {
    margin-bottom: 1rem; }
  .institution__presentation .media-object a {
    color: #0a0a0a;
    line-height: 1.6em;
    padding: 0; }
  .institution__presentation .media-object i {
    color: #3999b4;
    font-size: 3rem;
    line-height: 1em; }
  @media screen and (min-width: 40em) {
    .institution__presentation .left-border {
      border-left: 1px solid #0a0a0a; } }
  .institution__presentation .button, .institution__presentation .header-menu__item {
    margin-top: 3rem; }
  .institution__presentation__contact {
    padding-left: 2rem; }
    @media screen and (max-width: 63.9375em) {
      .institution__presentation__contact {
        margin-top: 2rem;
        padding: 0; } }
    .institution__presentation__contact--title {
      font-size: 1.73333rem;
      font-weight: bold; }

.institution__description {
  background: #f0f8fc;
  padding: 2rem 0; }

.institution__slideshow {
  padding-bottom: 0; }

.institution__title {
  color: #d85a31;
  font-size: 3.33333rem;
  font-weight: 300;
  margin-bottom: 2rem; }

.institution__header {
  background: #f0f8fc;
  font-size: 1rem; }

.institution__contact {
  background: #fefefe; }
  .institution__contact .button.margin, .institution__contact .margin.header-menu__item {
    margin-bottom: 4rem; }
  .institution__contact .tabs {
    border: 0;
    margin: 2rem 0;
    text-align: center; }
    .institution__contact .tabs-title {
      display: inline-block;
      float: none;
      margin: 0 1rem; }
    .institution__contact .tabs-content {
      border: 0; }
    .institution__contact .tabs-panel {
      padding: 2rem 0; }
  .institution__contact .tabs-title > a:focus, .institution__contact .tabs-title > a[aria-selected='true'] {
    background: #3999b4;
    color: #fefefe; }

.institution__news {
  background: #f0f8fc;
  padding-bottom: 4rem; }
  .institution__news .odd {
    margin-top: 2rem; }
  .institution__news__cta {
    margin-top: 2rem;
    text-align: center; }

.job-index {
  background: #f0f8fc; }
  .job-index__header {
    background: #8a8a8a url("../images/job/bg-header.jpg") no-repeat 50% 50%;
    background-position: top center;
    background-size: cover;
    color: #fefefe;
    padding: 10rem 0 6.66667rem;
    text-align: center; }
    .job-index__header .section__title {
      color: #fefefe;
      display: inline-block;
      padding: 0 1rem; }
  .job-index__intro {
    padding-top: 3.33333rem; }
    .job-index__intro .section__title {
      color: #d85a31; }
  .job-index__results {
    background: #fefefe; }
    .job-index__results .custom-filters {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 40px;
      font-family: "Open Sans", sans-serif; }
      .job-index__results .custom-filters .filter-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 40px;
        margin-bottom: 20px; }
        .job-index__results .custom-filters .filter-group .filter-field {
          display: flex;
          flex-direction: column;
          min-width: 260px; }
          .job-index__results .custom-filters .filter-group .filter-field label {
            color: #3999b4;
            margin-bottom: 6px;
            font-size: 1.2rem; }
          .job-index__results .custom-filters .filter-group .filter-field select {
            border: none;
            background: #e1e1e1;
            border-radius: 30px;
            font-size: 16px;
            color: #333;
            appearance: none; }
      .job-index__results .custom-filters .filter-submit {
        text-align: center; }
        .job-index__results .custom-filters .filter-submit button {
          background: none;
          border: none;
          color: #c44c28;
          font-weight: bold;
          font-size: 14px;
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          gap: 8px;
          text-transform: uppercase;
          letter-spacing: 0.5px; }
          .job-index__results .custom-filters .filter-submit button i {
            font-size: 2.13333rem;
            margin-right: 0.66667rem;
            vertical-align: middle; }
  .job-index__items {
    margin-top: 3.33333rem; }
  .job-index__item {
    border-top: 1px solid #3999b4;
    line-height: 1.8em;
    padding: 2rem 0; }
  .job-index__spontaneous--cta {
    padding: 3.33333rem 0; }

.modal-full-width {
  width: 100% !important;
  max-width: none !important; }

.search-mobile {
  padding: 0.66667rem; }
  .search-mobile__title {
    margin: 1rem 0; }
    .search-mobile__title .section__title {
      color: #d85a31;
      margin-bottom: 0; }
  .search-mobile__field {
    color: #3999b4;
    font-weight: bold;
    margin-bottom: 1rem; }
  .search-mobile__filter {
    display: inline-block;
    margin: 0 0.33333rem 0 0; }
    .search-mobile__filter--button a {
      background: #e1e1e1;
      border: 0;
      border-radius: 2.66667rem;
      font-size: 0.93333rem;
      padding: 0.66667rem 1.33333rem; }
    .search-mobile__filter--modal {
      background: #fefefe;
      display: none;
      height: 100%;
      left: 0;
      overflow: auto;
      padding: 1rem;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 2000; }
      .search-mobile__filter--modal.active {
        display: block; }
      .search-mobile__filter--modal .custom-checkbox {
        margin-bottom: 1rem;
        margin-left: 0; }
        .search-mobile__filter--modal .custom-checkbox .outer {
          float: left; }

.job-view__content {
  padding: 3.33333rem 0 5.33333rem; }

.job-view__title .section__title {
  color: #d85a31;
  margin-bottom: 1rem; }

.job-view__date {
  color: #8a8a8a;
  margin-bottom: 2rem; }

@media screen and (min-width: 40em) {
  .job-view .main-content {
    border-right: 1px solid #3999b4;
    padding-right: 3.33333rem; } }

.job-view__paragraph {
  margin-bottom: 2rem; }
  .job-view__paragraph:last-child {
    margin-bottom: 0; }
  .job-view__paragraph h3 {
    font-weight: bold;
    margin-bottom: 2rem; }

.breadcrumb {
  background: rgba(254, 254, 254, 0.9);
  left: 0;
  margin: 0;
  padding: 0.53333rem 0;
  position: fixed;
  width: 100%;
  z-index: 100; }
  @media screen and (max-width: 74.9375em) {
    .breadcrumb {
      position: absolute; } }
  .breadcrumb li {
    font-weight: bold; }
  .breadcrumb .access-typo {
    float: right;
    line-height: 1em; }
    .breadcrumb .access-typo a {
      color: #0a0a0a;
      display: inline-block;
      font-weight: bold;
      margin: 0 0.66667rem;
      vertical-align: middle; }

.footer {
  background: linear-gradient(to left, #46a1bd, #21819d);
  color: #fefefe;
  padding: 3rem 0 0; }
  @media screen and (max-width: 39.9375em) {
    .footer {
      padding: 1rem 0 0; } }
  .footer a {
    color: #fefefe;
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase; }
    .footer a:hover {
      text-decoration: underline; }
  .footer__title {
    color: #99cfee;
    font-size: 2rem;
    font-weight: normal;
    line-height: 1.2em;
    margin-bottom: 1rem; }
    @media screen and (max-width: 74.9375em) {
      .footer__title {
        font-size: 1.46667rem; } }
    @media screen and (max-width: 39.9375em) {
      .footer__title {
        border-top: 1px solid #fefefe;
        font-size: 1.46667rem;
        margin: 1rem 0 0.66667rem;
        padding-top: 1rem; }
        .footer__title.no-border {
          border: 0; } }
    @media screen and (max-width: 39.9375em) {
      .footer__title br {
        display: none; } }
  .footer ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .footer li {
    line-height: 1em;
    margin-bottom: 0.66667rem; }
    @media screen and (max-width: 39.9375em) {
      .footer li {
        display: inline-block;
        line-height: 1em;
        margin-bottom: 0.66667rem;
        width: 48%; } }
  .footer .border-left {
    border-left: 1px solid #f0f8fc; }
  .footer hr {
    border-color: #f0f8fc;
    margin: 3.33333rem 0 2rem; }
    @media screen and (max-width: 39.9375em) {
      .footer hr {
        display: none; } }
  .footer__sub {
    background: #275d6c;
    margin-top: 5.33333rem; }
    @media screen and (max-width: 39.9375em) {
      .footer__sub {
        margin-top: 2rem;
        overflow: auto;
        padding-top: 1rem; } }
    .footer__sub__brand {
      display: block;
      height: 100%;
      position: relative;
      padding: 0.66667rem 0; }
      @media screen and (max-width: 74.9375em) {
        .footer__sub__brand {
          height: auto; } }
      .footer__sub__brand img {
        height: 100%;
        width: 7.33333rem;
        bottom: 0;
        left: 0;
        position: absolute; }
        @media screen and (max-width: 74.9375em) {
          .footer__sub__brand img {
            display: block;
            margin: 15px auto;
            position: relative;
            width: 7.33333rem; } }
    @media screen and (max-width: 74.9375em) {
      .footer__sub .logo-footer-desktop {
        display: none; } }
    .footer__sub .logo-footer-mobile {
      display: none; }
      @media screen and (max-width: 74.9375em) {
        .footer__sub .logo-footer-mobile {
          display: inline-block; } }
    .footer__sub__menu ul {
      float: right;
      margin-top: 1.2rem; }
      @media screen and (max-width: 74.9375em) {
        .footer__sub__menu ul {
          float: none;
          display: block;
          text-align: center; } }
    .footer__sub__menu li {
      display: inline-block; }
      @media screen and (max-width: 39.9375em) {
        .footer__sub__menu li {
          margin: 0;
          width: auto; }
          .footer__sub__menu li a {
            font-size: 0.73333rem;
            padding: 0.66667rem; } }
    @media screen and (max-width: 74.9375em) {
      .footer__sub__social ul {
        display: block;
        text-align: center; } }
    .footer__sub__social li {
      display: inline-block; }
      @media screen and (max-width: 39.9375em) {
        .footer__sub__social li {
          margin-bottom: 0;
          width: auto; } }
    .footer__sub__social a {
      color: #99cfee;
      font-size: 2.26667rem;
      padding-left: 0.33333rem;
      padding-right: 0.33333rem; }
      .footer__sub__social a:hover {
        color: #fefefe;
        text-decoration: underline; }
  .footer__search__form, .footer__optin__form {
    background: #347d92;
    border-radius: 1.66667rem;
    display: inline-block;
    margin-bottom: 0.66667rem;
    padding: 0.33333rem 1.33333rem 0.33333rem 0.66667rem;
    text-align: left; }
    .footer__search__form input[type='text'], .footer__search__form input[type='email'], .footer__optin__form input[type='text'], .footer__optin__form input[type='email'] {
      background: transparent;
      border: 0;
      box-shadow: none;
      color: #fefefe;
      display: inline-block;
      font-size: 1.33333rem;
      margin: 0 0.33333rem 0 0;
      width: calc(100% - 40px); }
      .footer__search__form input[type='text']::-webkit-input-placeholder, .footer__search__form input[type='email']::-webkit-input-placeholder, .footer__optin__form input[type='text']::-webkit-input-placeholder, .footer__optin__form input[type='email']::-webkit-input-placeholder {
        color: #fefefe; }
      .footer__search__form input[type='text']:-moz-placeholder, .footer__search__form input[type='email']:-moz-placeholder, .footer__optin__form input[type='text']:-moz-placeholder, .footer__optin__form input[type='email']:-moz-placeholder {
        /* Firefox 18- */
        color: #fefefe; }
      .footer__search__form input[type='text']::-moz-placeholder, .footer__search__form input[type='email']::-moz-placeholder, .footer__optin__form input[type='text']::-moz-placeholder, .footer__optin__form input[type='email']::-moz-placeholder {
        /* Firefox 19+ */
        color: #fefefe; }
      .footer__search__form input[type='text']:-ms-input-placeholder, .footer__search__form input[type='email']:-ms-input-placeholder, .footer__optin__form input[type='text']:-ms-input-placeholder, .footer__optin__form input[type='email']:-ms-input-placeholder {
        color: #fefefe; }
    .footer__search__form button, .footer__optin__form button {
      background: transparent;
      border: 0;
      box-shadow: none;
      color: #99cfee;
      display: inline-block;
      font-size: 1.46667rem;
      font-weight: normal;
      text-transform: uppercase;
      user-select: none; }
  .footer__search p.small, .footer__optin p.small {
    font-size: 0.73333rem;
    line-height: 1.2em; }
  .footer__search {
    display: none;
    text-align: right; }
    .footer__search__form input[type='text'] {
      font-size: 0.93333rem;
      font-weight: bold;
      height: 2rem;
      text-transform: uppercase;
      width: 12rem; }

.header-menu {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10; }
  @media screen and (max-width: 63.9375em) {
    .header-menu {
      flex-wrap: wrap;
      background: #fefefe;
      width: 100%;
      padding: 0.66667rem 0.66667rem 0;
      position: relative; } }
  .header-menu__item {
    font-size: 0.93333rem;
    margin: 0;
    border-width: 1px;
    border-right: 0;
    white-space: nowrap; }
    .header-menu__item:last-child {
      border-right: 1px solid #fefefe; }
    @media screen and (max-width: 63.9375em) {
      .header-menu__item {
        letter-spacing: normal;
        border: solid #fefefe;
        border-width: 1px 1px 0 0;
        color: #fefefe;
        font-weight: bold;
        padding: 1.66667rem 2.66667rem;
        text-transform: uppercase; } }
    @media screen and (max-width: 74.9375em) {
      .header-menu__item {
        padding: 1.66667rem; } }
    @media screen and (max-width: 63.9375em) {
      .header-menu__item {
        background: #e1e1e1;
        border: 0;
        border-radius: 4rem;
        color: #3999b4;
        font-weight: normal;
        margin: 0 0.33333rem 0.66667rem;
        padding: 0.66667rem 1rem;
        text-transform: none; } }
  .header-menu__burger {
    color: #fefefe;
    font-size: 1.06667rem;
    font-weight: bold;
    margin-right: 1rem; }

.title-bar {
  background: linear-gradient(to right, #46a1bd 20%, #21819d 80%);
  padding: 0; }
  .title-bar__brand {
    text-align: left; }
    .title-bar__brand a {
      display: inline-block;
      padding: 0.66667rem;
      background: #fefefe; }
      .title-bar__brand a img {
        height: 4.66667rem; }
  .title-bar .menu-icon {
    height: 2.26667rem;
    margin-right: 0.53333rem;
    width: 2.93333rem; }
    .title-bar .menu-icon::after {
      border-radius: 0.2rem;
      box-shadow: 0 14px 0 #fefefe, 0 28px 0 #fefefe;
      height: 0.33333rem; }

#mobile-menu {
  display: none; }
  #mobile-menu .accordion {
    text-align: left;
    width: 100%; }
  #mobile-menu .accordion-title::before {
    content: none; }
  #mobile-menu .accordion-content {
    padding: 0; }
    #mobile-menu .accordion-content a {
      font-size: 0.86667rem; }
  #mobile-menu a {
    font-weight: bold;
    padding: 1.33333rem 1rem; }
  #mobile-menu li li > a {
    font-weight: normal; }

header {
  color: #fefefe;
  position: relative;
  text-align: center;
  z-index: 2000; }
  @media screen and (max-width: 63.9375em) {
    header {
      max-height: 100vh;
      overflow: auto; } }

@media screen and (max-width: 74.9375em) {
  .visible-desktop {
    display: none; } }

@media screen and (min-width: 75em) {
  .visible-mobile {
    display: none; } }

.top-bar {
  position: relative;
  width: 100%;
  z-index: 100;
  background: #fefefe; }
  .top-bar__button {
    padding: 1.33333rem; }
    @media screen and (max-width: 89.9375em) {
      .top-bar__button {
        padding: 0.93333rem; } }
  .top-bar__brand {
    padding: 0.66667rem 2rem;
    background: #fefefe; }
    @media screen and (min-width: 75em) {
      .top-bar__brand {
        width: 10.66667rem; } }
  .top-bar-left {
    float: left; }
  .top-bar-right {
    background: #21819d;
    height: 7.33333rem;
    display: flex;
    align-items: center;
    flex: none !important;
    float: right;
    position: relative;
    padding: 0 30px 0 0; }
    @media screen and (max-width: 89.9375em) {
      .top-bar-right {
        height: 6rem; } }
    @media screen and (max-width: 74.9375em) {
      .top-bar-right {
        height: 5.73333rem; } }
    .top-bar-right img {
      height: 7.33333rem;
      max-width: none; }
      @media screen and (max-width: 89.9375em) {
        .top-bar-right img {
          height: 6rem; } }
      @media screen and (max-width: 74.9375em) {
        .top-bar-right img {
          height: 5.73333rem; } }
  .top-bar .menu-centered {
    background-color: #3999b4;
    background-image: linear-gradient(to right, #46a1bd 20%, #21819d 80%);
    height: 7.33333rem;
    width: 100%; }
    @media screen and (max-width: 89.9375em) {
      .top-bar .menu-centered {
        height: 6rem; } }
    @media screen and (max-width: 74.9375em) {
      .top-bar .menu-centered {
        height: 5.73333rem; } }
  .top-bar .dropdown.menu {
    position: inherit; }
    .top-bar .dropdown.menu > li {
      padding: 0 0 0 1.66667rem; }
      @media screen and (max-width: 89.9375em) {
        .top-bar .dropdown.menu > li {
          padding: 0 0 0 0.8rem; } }
      .top-bar .dropdown.menu > li::after {
        border-left: 1px solid #fefefe;
        content: "";
        height: 2rem;
        position: relative;
        margin-top: 38px;
        margin-left: 1.66667rem; }
        @media screen and (max-width: 89.9375em) {
          .top-bar .dropdown.menu > li::after {
            margin-top: 28px;
            margin-left: 0.8rem; } }
      .top-bar .dropdown.menu > li:last-child::after {
        content: none; }
      .top-bar .dropdown.menu > li > a {
        border: solid transparent;
        border-width: 0.26667rem 0 0.33333rem;
        color: #fefefe;
        font-size: 0.86667rem;
        font-weight: bold;
        letter-spacing: 2px;
        padding: 2.93333rem 0;
        text-transform: uppercase; }
        @media screen and (max-width: 89.9375em) {
          .top-bar .dropdown.menu > li > a {
            padding: 2.26667rem 0; } }
        @media screen and (max-width: 74.9375em) {
          .top-bar .dropdown.menu > li > a {
            padding: 2.13333rem 0; } }
        .top-bar .dropdown.menu > li > a:hover {
          border-bottom-color: #fefefe; }
    .top-bar .dropdown.menu .mega-menu {
      z-index: 101; }
      .top-bar .dropdown.menu .mega-menu li {
        justify-content: left;
        padding: 0;
        text-align: left; }
      .top-bar .dropdown.menu .mega-menu .button, .top-bar .dropdown.menu .mega-menu .header-menu__item {
        display: inline-block;
        margin-top: 2rem; }
  .top-bar__title {
    color: #3999b4;
    font-size: 2rem; }
  .top-bar__intro {
    font-size: 1rem;
    margin-bottom: 0.66667rem; }
  .top-bar__cta {
    margin-top: -0.66667rem;
    position: relative;
    text-align: center; }
  .top-bar .gift-link {
    bottom: 1.33333rem;
    color: #398195;
    font-size: 0.93333rem;
    font-weight: bold;
    left: 3rem;
    position: absolute;
    text-align: center;
    text-transform: uppercase; }
    @media screen and (max-width: 89.9375em) {
      .top-bar .gift-link {
        bottom: 0.66667rem;
        font-size: 0.86667rem;
        left: 2.26667rem; } }
    @media screen and (max-width: 74.9375em) {
      .top-bar .gift-link {
        bottom: 0.66667rem;
        left: 2rem; } }
    .top-bar .gift-link i {
      font-size: 0.8rem; }
    .top-bar .gift-link:hover {
      color: #d85a31; }

header .dropdown-pane {
  border: 0;
  box-shadow: 0 10px 15px -10px #0a0a0a;
  color: #0a0a0a;
  display: block !important;
  left: 0 !important;
  margin: 0 auto !important;
  padding: 2rem 0;
  right: 0 !important;
  text-align: left;
  width: 100%;
  z-index: 100; }
  header .dropdown-pane .menu a {
    color: #0a0a0a;
    font-size: 0.86667rem;
    font-weight: bold;
    line-height: 1.2em;
    padding: 0.66667rem 0;
    text-transform: uppercase; }
    header .dropdown-pane .menu a:hover {
      text-decoration: underline; }

.news-index {
  background: #f0f8fc; }
  .news-index__header {
    background: #8a8a8a url("../images/news/slide-news.jpg") no-repeat 50% 50%;
    background-position: top center;
    background-size: cover;
    color: #fefefe;
    padding: 10rem 0 6.66667rem;
    text-align: center; }
    .news-index__header .section__title {
      color: #fefefe;
      display: inline-block;
      padding: 0 1rem;
      text-align: center; }
      .news-index__header .section__title span {
        margin-bottom: 3.33333rem; }
    .news-index__header .section__banner-text {
      max-width: 700px; }
      @media screen and (max-width: 39.9375em) {
        .news-index__header .section__banner-text {
          max-width: 350px; } }
  .news-index__form {
    padding: 2rem 0 1rem; }
    @media screen and (max-width: 39.9375em) {
      .news-index__form {
        display: block;
        padding: 0 0 1rem;
        text-align: left; } }
    .news-index__form label[for="news_filter_form_institution"] {
      display: none; }
    .news-index__form select {
      float: right;
      margin-top: 0.66667rem;
      max-width: 20rem; }
      @media screen and (max-width: 39.9375em) {
        .news-index__form select {
          float: none; } }
    .news-index__form .custom-radio {
      margin-bottom: 0.66667rem; }
      @media screen and (max-width: 39.9375em) {
        .news-index__form .custom-radio {
          display: block;
          margin: 0 0 0.66667rem; } }
  .news-index__count {
    clear: both;
    padding: 1rem 0 0.33333rem; }
    @media screen and (max-width: 39.9375em) {
      .news-index__count {
        text-align: left; } }
  .news-index__results {
    padding-top: 3.33333rem; }
    .news-index__results .section__title {
      color: #d85a31; }

.news-view__title {
  margin-top: 4rem; }
  .news-view__title .section__title {
    color: #d85a31;
    margin-bottom: 1rem; }

.news-view__date {
  display: block; }

.news-view__tags {
  display: block; }
  .news-view__tags--item {
    background: #3b8da5;
    color: #fefefe;
    margin: 0.66667rem 0.66667rem 0.66667rem 0;
    padding: 0.13333rem 0.66667rem;
    text-transform: uppercase; }

.news-view__share {
  float: right;
  margin-top: 2rem; }
  .news-view__share a {
    color: #3b8da5;
    font-size: 3rem;
    padding-left: 0.66667rem;
    padding-right: 0.66667rem; }
    .news-view__share a:hover {
      color: #d85a31; }

.news-view__picture {
  padding: 0; }
  .news-view__picture img {
    width: 100%; }
  .news-view__picture::after, .news-view__picture::before {
    background: #fefefe; }

.news-view__back {
  padding: 4rem 0; }
  .news-view__back a {
    color: #3999b4;
    font-weight: bold;
    text-transform: uppercase; }
    .news-view__back a:hover {
      color: #d85a31; }

.project-index {
  /* Numbers */ }
  .project-index__header {
    background: #8a8a8a url("../images/project/index-header.jpg") no-repeat 50% 50%;
    background-size: cover;
    color: #fefefe;
    padding: 14rem 0 9.33333rem;
    text-align: center; }
    .project-index--innovative .project-index__header {
      background-image: url("../images/project/index-innovative-header.jpg"); }
    .project-index__header .section__title {
      color: #fefefe;
      display: inline-block;
      padding: 0 1rem; }
  .project-index__intro {
    padding: 3.33333rem 0; }
    .project-index__intro .section__title {
      color: #d85a31; }
  .project-index__results {
    background: #f0f8fc;
    padding: 3.33333rem 0; }
    .project-index--innovative .project-index__results {
      padding-top: 0; }
  .project-index__numbers {
    background: #d85a31 url("../images/home/bg-numbers.jpg") no-repeat 50% 50%;
    background-size: cover;
    color: #fefefe; }
    .project-index__numbers::after {
      background-color: #fefefe; }

.project-view__title {
  color: #d85a31;
  margin-top: 3rem; }

.project-view__content {
  margin: 3rem 0; }

.project-view__back {
  padding: 4rem 0; }
  .project-view__back a {
    color: #3999b4;
    font-weight: bold;
    text-transform: uppercase; }
    .project-view__back a:hover {
      color: #d85a31; }
