.job-index {
    background: $blue-light;

    &__header {
        background:  $dark-gray  url('../images/job/bg-header.jpg') no-repeat 50% 50%;
        background-position: top center;
        background-size: cover;
        color: $white;
        padding: rem-calc(150 0 100);
        text-align: center;

        .section__title {
            color: $white;
            display: inline-block;
            padding: rem-calc(0 15);
        }
    }

    &__intro {
        padding-top: rem-calc(50);

        .section__title {
            color: $secondary-color;
        }
    }

    &__results {
      background: $white;

      .custom-filters {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;
        font-family: "Open Sans", sans-serif;

        .filter-group {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 40px;
          margin-bottom: 20px;

          .filter-field {
            display: flex;
            flex-direction: column;
            min-width: 260px;

            label {
              color: #3999b4;
              margin-bottom: 6px;
              font-size: 1.2rem;
            }

            select {
              border: none;
              background: #e1e1e1;
              border-radius: 30px;
              font-size: 16px;
              color: #333;
              appearance: none;
            }
          }
        }
        .filter-submit {
          text-align: center;

          button {
            background: none;
            border: none;
            color: #c44c28;
            font-weight: bold;
            font-size: 14px;
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            gap: 8px;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            i {
              font-size: rem-calc(32);
              margin-right: rem-calc(10);
              vertical-align: middle;
            }
          }
        }
      }
    }

    &__items {
        margin-top: rem-calc(50);
    }

    &__item {
        border-top: 1px solid $primary-color;
        line-height: 1.8em;
        padding: rem-calc(30 0);
    }

    &__spontaneous {

        &--cta {
            padding: rem-calc(50 0);
        }
    }
}

.modal-full-width {
  width: 100% !important;
  max-width: none !important;
}
