//COLORS
$light-gray: #b4b4b4;
$gray: #e1e1e1;
$medium-gray: #cacaca;
$dark-gray: #8a8a8a;
$darker-gray: #3f3f3f;
$black: #353638;
$white: #fff;

$foundation-palette: (
    primary: #3999b4,
    secondary: #d85a31,
    success: #3adb76,
    warning: #ffae00,
    alert: #cc4b37,
);

$primary-l: #298b9d;
$secondary-l: #ff5c14;

$blue-light: #f0f8fc;
$blue-medium: #99cfee;
$blue-dark: #3b8da5;
$blue-darker: #275d6c;
$blue-dd: #123f90;
$red-l: #ea5156;

$cultureh: #398195;
