.footer {
    background: linear-gradient(to left, #46a1bd, #21819d);
    color: $white;
    padding: rem-calc(45 0 0);

    @include breakpoint (small down) {
        padding: rem-calc(15 0 0);
    }

    a {
        color: $white;
        font-size: rem-calc(12);
        font-weight: bold;
        text-transform: uppercase;

        &:hover {
            text-decoration: underline;
        }
    }

    &__title {
        color: $blue-medium;
        font-size: rem-calc(30);
        font-weight: normal;
        line-height: 1.2em;
        margin-bottom: rem-calc(15);

        @include breakpoint(large down) {
            font-size: rem-calc(22);
        }

        @include breakpoint (small down) {
            border-top: 1px solid $white;
            font-size: rem-calc(22);
            margin: rem-calc(15 0 10);
            padding-top: rem-calc(15);

            &.no-border {
                border: 0;
            }
        }

        br {
            @include breakpoint (small down) {
                display: none;
            }
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        line-height: 1em;
        margin-bottom: rem-calc(10);

        @include breakpoint (small down) {
            display: inline-block;
            line-height: 1em;
            margin-bottom: rem-calc(10);
            width: 48%;
        }
    }

    .border-left {
        border-left: 1px solid $blue-light;
    }

    hr {
        border-color: $blue-light;
        margin: rem-calc(50 0 30);

        @include breakpoint (small down) {
            display: none;
        }
    }

    &__sub {
        background: $blue-darker;
        margin-top: rem-calc(80);

        @include breakpoint(small down) {
            margin-top: rem-calc(30);
            overflow: auto;
            padding-top: rem-calc(15);
        }

        &__brand {
            display: block;
            height: 100%;
            position: relative;
            padding: rem-calc(10) 0;

            @include breakpoint(large down) {
                height: auto;
            }

            img {
                height: 100%;
                width: rem-calc(110);
                bottom: 0;
                left: 0;
                position: absolute;

                @include breakpoint(large down) {
                    display: block;
                    margin: 15px auto;
                    position: relative;
                    width: rem-calc(110);
                }
            }
        }

        .logo-footer-desktop {
            @include breakpoint(large down) {
                display: none;
            }
        }

        .logo-footer-mobile {
            display: none;

            @include breakpoint(large down) {
                display: inline-block;
            }
        }

        &__menu {
            ul {
                float: right;
                margin-top: rem-calc(18);

                @include breakpoint(large down) {
                    float: none;
                    display: block;
                    text-align: center;
                }
            }

            li {
                display: inline-block;


                @include breakpoint(small down) {
                    margin: 0;
                    width: auto;

                    a {
                        font-size: rem-calc(11);
                        padding: rem-calc(10);
                    }
                }
            }
        }

        &__social {
            ul {
                @include breakpoint(large down) {
                    display: block;
                    text-align: center;
                }
            }

            li {
                display: inline-block;

                @include breakpoint(small down) {
                    margin-bottom: 0;
                    width: auto;
                }
            }

            a {
                color: $blue-medium;
                font-size: rem-calc(34);
                padding-left: rem-calc(5);
                padding-right: rem-calc(5);

                &:hover {
                    color: $white;
                    text-decoration: underline;
                }
            }
        }
    }

    &__search,
    &__optin {
        &__form {
            background: darken($blue-dark, 5%);
            border-radius: rem-calc(25);
            display: inline-block;
            margin-bottom: rem-calc(10);
            padding: rem-calc(5 20 5 10);
            text-align: left;

            input {
                &[type='text'],
                &[type='email'] {

                    @include placeholder {
                        color: $white;
                    }

                    background: transparent;
                    border: 0;
                    box-shadow: none;
                    color: $white;
                    display: inline-block;
                    font-size: rem-calc(20);
                    margin: rem-calc(0 5 0 0);
                    width: calc(100% - 40px);
                }
            }

            button {
                background: transparent;
                border: 0;
                box-shadow: none;
                color: $blue-medium;
                display: inline-block;
                font-size: rem-calc(22);
                font-weight: normal;
                text-transform: uppercase;
                user-select: none;
            }
        }

        p.small {
            font-size: rem-calc(11);
            line-height: 1.2em;
        }
    }

    &__search {
        display: none;
        text-align: right;

        &__form {
            input {
                &[type='text'] {
                    font-size: rem-calc(14);
                    font-weight: bold;
                    height: rem-calc(30);
                    text-transform: uppercase;
                    width: rem-calc(180);
                }
            }
        }
    }
}
