.home {

    .diagonal {
        &::after,
        &::before {
            background: $white;
        }
    }

    /* Search form */

    &-form {
        .section__title {
            color: $secondary-color;

            @include breakpoint(medium down) {
                margin-bottom: rem-calc(30);
            }
        }

        .form-label {
            color: $primary-color;
            font-size: rem-calc(18);
            margin-bottom: rem-calc(5);
            padding-left: rem-calc(20);
        }

        &__submit button {
            color: $secondary-color;
            cursor: pointer;
            font-weight: bold;
            line-height: rem-calc(32);
            margin: rem-calc(30 0);
            text-transform: uppercase;

            i {
                font-size: rem-calc(32);
                margin-right: rem-calc(10);
                vertical-align: middle;
            }
        }
    }

    /* Numbers */

    &-numbers {
        background: $secondary-color url('../images/home/bg-numbers.jpg') no-repeat 50% 50%;
        background-size: cover;
        color: $white;
    }

    /* News */

    &-news {
        padding: rem-calc(0 0);

        &--desktop {
            @include breakpoint(medium down) {
                display: none;
            }
        }

        &--mobile {
            @include breakpoint(large up) {
                display: none;
            }
        }

        .odd {
            margin-top: rem-calc(30);
        }

        &__cta {
            padding: rem-calc(30 15 45);
            text-align: center;
        }

        .thumb {
            @include breakpoint(medium down) {
                margin: rem-calc(60 15);
                width: 250px;
            }
        }

        .section__title {
            @include breakpoint(medium down) {
                margin-bottom: 0;
            }
        }

        &__mobile {
            margin: rem-calc(0);
            width: 100%;
        }
    }
}
