.institution {
    &__presentation {
        background: $white;
        padding: rem-calc(30);

        li {
            &:not(:last-child) {
                margin-bottom: rem-calc(15);
            }
        }

        .media-object {
            a {
                color: $black;
                line-height: 1.6em;
                padding: 0;
            }

            i {
                color: $primary-color;
                font-size: rem-calc(45);
                line-height: 1em;
            }
        }

        .left-border {
            @include breakpoint(medium up) {
                border-left: 1px solid $black;
            }
        }

        .button {
            margin-top: rem-calc(45);
        }

        &__contact {
            padding-left: rem-calc(30);

            @include breakpoint(medium down) {
                margin-top: rem-calc(30);
                padding: 0;
            }

            &--title {
                font-size: rem-calc(26);
                font-weight: bold;
            }
        }
    }

    &__description {
        background: $blue-light;
        padding: rem-calc(30 0);
    }
}
