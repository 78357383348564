.project-view {
    &__title {
        color: $secondary-color;
        margin-top: rem-calc(45);
    }

    &__content {
        margin: rem-calc(45 0);
    }

    &__back {
        padding: rem-calc(60 0);

        a {
            color: $primary-color;
            font-weight: bold;
            text-transform: uppercase;

            &:hover {
                color: $secondary-color;
            }
        }
    }
}
