@mixin background-opacity($color, $alpha) {
    $rgba: rgba($color, $alpha);
    $ie-hex-str: ie-hex-str($rgba);
    background: $color;
    background-color: $rgba;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#{$ie-hex-str},endColorstr=#{$ie-hex-str});
}

/* Works with the icon sass file generated by gulp font icon */
@mixin round-icon($size: rem-calc(48), $color: $secondary-color, $bg-color: $primary-color) {
    background-color: $bg-color;
    border-radius: $size / 2;
    color: $color;
    display: inline-block;
    font-size: rem-calc(20);
    height: $size;
    line-height: $size;
    text-align: center;
    transition: background-color 300ms ease-out, border-color 300ms ease-out;
    width: $size;

    i {
        color: $color;
        line-height: $size - 3;
        vertical-align: middle;
    }
}

@mixin circle-icon($size: 48px, $color: $secondary-color, $bg-color: transparent) {
    @include round-icon($size, inherit, $bg-color);
    background-color: transparent;
    border: 2px solid $color;
}

@mixin link-icon($size: 48px, $color: $primary-color, $bg-color: $secondary-color) {
    text-decoration: none;

    &.circle-icon {
        @include circle-icon($size, $primary-color);

        &:hover {
            @include round-icon($size, $primary-color, $anchor-color);

            text-decoration: none;
        }
    }

    &.round-icon {
        &:hover {
            @include round-icon($size, $primary-color, $anchor-color);

            text-decoration: none;
        }
    }
}

/* Shadows */

@mixin bottom-shadow {
    & {
        overflow: auto;
        position: relative;

        &::before {
            border-radius: 100%;
            box-shadow: 0 0 rem-calc(40) $black;
            content: "";
            height: rem-calc(60);
            left: 10%;
            opacity: .2;
            position: absolute;
            right: 10%;
            top: rem-calc(-60);
            width: 80%;
        }
    }
}

@mixin left-shadow {
    overflow: auto;
    position: relative;

    & {
        &::after {
            border-radius: 100%;
            bottom: 0;
            box-shadow: 0 0 rem-calc(40) $black;
            content: "";
            height: 100%;
            left: rem-calc(-30);
            opacity: .2;
            position: absolute;
            top: 0;
            width: rem-calc(30);
        }
    }
}

@mixin gutter-prop($property-name: padding) {
    #{$property-name}: rem-calc(map-get($grid-column-gutter, xxlarge) * 8);

    @include breakpoint(xxlarge down) {
        #{$property-name}: rem-calc(map-get($grid-column-gutter, xlarge) * 6);
    }

    @include breakpoint(xlarge down) {
        #{$property-name}: rem-calc(map-get($grid-column-gutter, xlarge) * 4);
    }

    @include breakpoint(large down) {
        #{$property-name}: rem-calc(map-get($grid-column-gutter, large) * 2);
    }

    @include breakpoint(medium down) {
        #{$property-name}: rem-calc(map-get($grid-column-gutter, medium));
    }
}

@function color-pick-contrast($base, $colors: ($white, $black), $tolerance: 0) {
    $contrast: color-contrast($base, nth($colors, 1));
    $best: nth($colors, 1);

    @for $i from 2 through length($colors) {
        $current-contrast: color-contrast($base, nth($colors, $i));
        @if ($current-contrast - $contrast > $tolerance) {
            $contrast: color-contrast($base, nth($colors, $i));
            $best: nth($colors, $i);
        }
    }

    @return $best;
}

@mixin placeholder {
    &::-webkit-input-placeholder { // sass-lint:disable-line no-vendor-prefixes
        @content;
    }

    &:-moz-placeholder { /* Firefox 18- */ // sass-lint:disable-line no-vendor-prefixes
        @content;
    }

    &::-moz-placeholder {  /* Firefox 19+ */ // sass-lint:disable-line no-vendor-prefixes
        @content;
    }

    &:-ms-input-placeholder { // sass-lint:disable-line no-vendor-prefixes
        @content;
    }
}

@mixin background-text($image, $fallback-color: $black) {

    //sass-lint:disable-all
    -webkit-text-fill-color: transparent;
    color: $fallback-color;
    background: -webkit-linear-gradient(transparent, transparent),
    url($image) repeat;
    background: -o-linear-gradient(transparent, transparent);
    -webkit-background-clip: text;
    //sass-lint:disable-all

    background-position: 50% 50%;
    max-width: rem-calc(980);
    overflow: visible;
    padding-top: rem-calc(20);
    position: relative;

    html:not(.ie) &,
    html.ie-14 &,
    html.ie-15 & {
        @media screen and (-webkit-min-device-pixel-ratio: 0) {
            /* CSS Statements that only apply on webkit-based browsers (Chrome, Safari, etc.) */
            background-attachment: fixed !important;
            background-size: cover;
        }
    }
}

@mixin background-black-gradient(){
    background: rgba(255,255,255,0);
    background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0)), color-stop(100%, rgba(0,0,0,1)));
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
    background: -o-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
    background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );
}


@mixin btn-scrolldown($color, $hover) {
    bottom: rem-calc(25);
    color: $color;
    font-weight: bold;
    left: 50%;
    margin-left: rem-calc(-20);
    position: absolute;
    text-transform: uppercase;
    transition: color 300ms ease;

    &:not(.show-on-mobile) {
        @include breakpoint(small down) {
            display: none;
        }
    }

    i {
        border: 1px solid $color;
        color: $color;
        height: rem-calc(37);
        line-height: rem-calc(34);
        margin-right: rem-calc(10);
        text-align: center;
        transform: rotate(180deg);
        transition: border 300ms ease, color 300ms ease;
        width: rem-calc(37);
    }

    &:hover {
        color: $hover;

        i {
            border-color: $hover;
            color: $hover;
        }
    }
}


@mixin autofill-color($color) {
    //sass-lint:disable-all
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-text-fill-color: $color;
        -webkit-box-shadow: 0 0 0 1000px transparent inset;
        transition: background-color 5000s ease-in-out 0s;
    }
    //sass-lint:enable-all
}
