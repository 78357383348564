.header-menu {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  @include breakpoint(medium down) {
    flex-wrap: wrap;
    background: $white;
    width: 100%;
    padding: rem-calc(10 10 0);
    position: relative;
  }

  &__item {
    @extend .button, .white;

    font-size: rem-calc(14);
    margin: 0;
    border-width: 1px;
    border-right: 0;
    white-space: nowrap;

    &:last-child {
      border-right: 1px solid $white;
    }

    @include breakpoint(medium down) {
      letter-spacing: normal;
      border: solid $white;
      border-width: 1px 1px 0 0;
      color: $white;
      font-weight: bold;
      padding: rem-calc(25 40);
      text-transform: uppercase;
    }

    @include breakpoint(large down) {
      padding: rem-calc(25);
    }

    @include breakpoint(medium down) {
      background: $gray;
      border: 0;
      border-radius: rem-calc(60);
      color: $primary-color;
      font-weight: normal;
      margin: rem-calc(0 5 10);
      padding: rem-calc(10 15);
      text-transform: none;
    }
  }

  &__burger {
    color: $white;
    font-size: rem-calc(16);
    font-weight: bold;
    margin-right: rem-calc(15);
  }
}
