.thumb {
    background: $white;
    height: calc(100% - 50px);
    margin-bottom: rem-calc(50);

    &--shadow {
        box-shadow: 0 0 120px -30px $black;
    }

    a {
        color: $black;
    }

    &__picture {
        background: $black;
        position: relative;

        img {
            opacity: .7;
            transition: opacity 300ms ease-out;
        }
    }

    &__meta {
        color: $primary-color;
        font-size: rem-calc(12);
        margin: rem-calc(10 15);
    }

    &__title {
        bottom: 0;
        color: $white;
        font-size: rem-calc(20);
        font-weight: bold;
        left: 0;
        line-height: 1.2em;
        padding: rem-calc(5);
        position: absolute;

        @include breakpoint(small down) {
            font-size: rem-calc(18);
        }

        .thumb--shadow & {
            color: $black;
            font-size: rem-calc(24);
            line-height: 1.1em;
            margin: rem-calc(10 15);
            padding: 0;
            position: relative;

            @include breakpoint(small down) {
                font-size: rem-calc(20);
            }
        }
    }

    &__excerpt {
        padding: rem-calc(15);

        @include breakpoint(small down) {
            font-size: rem-calc(13);
        }

        .thumb--shadow & {
            margin: rem-calc(10 15);
            padding: 0;
        }
    }

    &__cta {
        color: $blue-dark;
        font-weight: bold;
        padding: rem-calc(0 0 30);
        text-align: center;
        text-transform: uppercase;

        .thumb--shadow & {
            padding: rem-calc(15 0 30);
        }

        i {
            font-size: rem-calc(12);
        }
    }

    &:hover {
        .thumb__cta {
            text-decoration: underline;
        }

        img {
            opacity: 1;
        }
    }
}
