// sass-lint:disable-all

@font-face {
    font-family: "font-icon";
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/font-icon.eot');
    src: url('../fonts/font-icon.eot?#iefix') format('eot'),
    url('../fonts/font-icon.woff') format('woff'),
    url('../fonts/font-icon.ttf') format('truetype'),
    url('../fonts/font-icon.svg#font-icon') format('svg');
}

.icon,
[class^="icon-"],
[class*=" icon-"] {
    /* Better Font Rendering =========== */
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    /* End Better Font Rendering ======= */
    display: inline-block;
    font-family: "font-icon" !important;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    position: relative;
    speak: none;
    text-transform: none;
    vertical-align: middle;
}

.round-icon {
    @include round-icon;
}

.circle-icon {
    @include circle-icon;
}

a.link-icon {
    @include link-icon;
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: .75em;
    vertical-align: -15%;
}

.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }

.icon-fw {
    text-align: center;
    width: 1.2857142857142858em;
}

.icon-arrow-left::before { content: "\EA01"; }
.icon-arrow-right::before { content: "\EA02"; }
.icon-bell::before { content: "\EA03"; }
.icon-blind::before { content: "\EA04"; }
.icon-facebook::before { content: "\EA05"; }
.icon-file::before { content: "\EA06"; }
.icon-mail::before { content: "\EA07"; }
.icon-home::before { content: "\EA08"; }
.icon-hourray::before { content: "\EA09"; }
.icon-idcard::before { content: "\EA0A"; }
.icon-linkeding::before { content: "\EA0B"; }
.icon-marker::before { content: "\EA0C"; }
.icon-twitter::before { content: "\EA0D"; }
.icon-magnifier::before { content: "\EA0E"; }
.icon-chevron::before { content: "\EA0F"; }
.icon-instagram::before { content: "\EA10"; }
.icon-vimeo::before { content: "\EA11"; }
.icon-youtube::before { content: "\EA1A"; }
