.reveal-overlay {
  background-color: rgba(10, 10, 10, 0.75);
  z-index: 9999;
}

.modal {
  &:focus {
    outline: none;
  }

  padding: rem-calc(120 50 140);
  width: auto;
  height: auto;
  max-width: rem-calc(800);
  max-height: rem-calc(600);
  overflow: hidden;

  @include breakpoint(small down) {
    min-height: auto;
  }

  &::before {
    content: "";
    position: absolute;
    width: 200%;
    height: 200%;
    top: -1%;
    z-index: -1;
    background: url('../images/home/modal.png') 0 0 no-repeat;

    @include breakpoint(800px down) {
      left: -30%;
    }

    @include breakpoint(small down) {
      left: -65%
    }

    @include breakpoint(465px down) {
      left: -75%;
    }
  }


  &__title {
    font-size: rem-calc(34);
    font-weight: 700;
    color: $blue-dd;
  }

  &__text {
    margin: 0;
    color: $blue-dd;
    font-size: rem-calc(39);
    line-height: 1.2;

    &--bold {
      font-weight: bold;
    }
  }

  &__image {
    position: absolute;
    bottom: 0;
    right: rem-calc(-70);
    z-index: -1;
  }

  &__icon {
    font-size: rem-calc(15);
    margin-right: 0 !important;
  }

  &__close-button {
    border-radius: 50%;
    background-color: $darker-gray;
    color: white;
    width: 30px;

    &:hover {
      color: white;
    }
  }

  &__button {
    background-color: $red-l;
    border: 2px solid $red-l;

    &:hover {
      color: $red-l;
    }
  }
}
