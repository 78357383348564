.content-page {
    &__header {
        background:  $dark-gray no-repeat 50% 50%;
        background-size: cover;
        color: $white;
        margin-bottom: rem-calc(30);
        padding: rem-calc(150 0 100);
        position: relative;
        text-align: center;

        @include breakpoint(xlarge down) {
            padding: rem-calc(100 0 50);
        }

        &::after {
            background: rgba($black, .5);
            content: "";
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 9;
        }

        .section__title {
            color: $white;
            display: inline-block;
            padding: rem-calc(0 15);
            position: relative;
            z-index: 10;
            text-align: center;
        }

        &--with-menu {
            @include breakpoint(medium down) {
                padding: rem-calc(50 0 0);
            }
        }
    }
}
