.contact-index {
    &__header {
        background: $dark-gray url('../images/contact/bg-contact.jpg') no-repeat 50% 50%;
        background-size: cover;
        color: $white;
        padding: rem-calc(150 0 100);
        text-align: center;

        .section__title {
            color: $white;
            display: inline-block;
            padding: rem-calc(0 15);
        }
    }

    &__content {
        padding: rem-calc(25 0 50);
    }

    &__social {
        margin: rem-calc(25 0);

        hr {
            background-color: $primary-color;
            border: 0;
            height: rem-calc(1);
            margin: 0;
            padding: 0;
        }

        .menu {
            padding: rem-calc(0 10);

            a {
                padding: rem-calc(0 10);
            }

            i {
                font-size: rem-calc(44);
            }
        }
    }


    &__bottom {
        background: $blue-light;
        margin: rem-calc(45 0);
        padding: rem-calc(50 30);
        text-align: center;

        &--block {
            @include breakpoint(medium up) {
                padding: rem-calc(0 45);
            }
        }

        &--title {
            color: $primary-color;
            font-size: rem-calc(42);
            font-weight: 300;
            margin-bottom: rem-calc(30);
        }
    }

    .tabs {
        border: 0;
        margin: rem-calc(30 0);
        text-align: center;

        &-title {
            display: inline-block;
            float: none;
            margin: rem-calc(0 15);
        }

        &-content {
            border: 0;
        }

        &-panel {
            padding: rem-calc(30 0);
        }
    }

    .tabs-title {
        & > a {
            &:focus,
            &[aria-selected='true'] {
                background: $primary-color;
                color: $white;
            }
        }
    }

    &__contact-form {
        textarea {
            height: rem-calc(210);
        }

        .form-newsletter {
            margin-top: rem-calc(45);

            .custom-radio {
                margin-left: 0;
            }
        }
    }

    &__admission-form {
        form {
            margin-top: rem-calc(30);
        }

        textarea {
            height: rem-calc(210);
        }

        .custom-radio {
            display: block;
            margin-bottom: rem-calc(10);
            margin-left: 0;
        }
    }

    &__required {
        font-size: rem-calc(12);
        margin-bottom: rem-calc(30);
    }

    &__rgpd {
        font-size: rem-calc(13);
    }

    &__notification {
        color: $primary-color;
        font-weight: bold;
        margin-top: rem-calc(15);

        i {
            font-size: rem-calc(50);
            margin-right: rem-calc(20);
        }
    }
}
