button:focus {
    outline: auto;
}

.button {
    border: 2px solid $primary-color;
    font-size: rem-calc(15);
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 1em;
    text-transform: uppercase;

    @include breakpoint(small down) {
        letter-spacing: 1.5px;
        padding: rem-calc(20);
    }

    &:focus,
    &:hover {
        color: $white;
    }

    span,
    i,
    svg {
        margin-right: rem-calc(5);
    }

    &--large {
        padding: rem-calc(20 70);
    }

    &.white {
        border-color: $white;
        color: $white;

        &:hover {
            background-color: $white;
            color: $primary-color;

            path {
                fill: $primary-color !important;
            }
        }
    }

    &.no-margin {
        margin-bottom: 0;
    }

    &.main {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    &--secondary {
        border: 2px solid $secondary-l;
        background-color: $secondary-l;
        color: white;

        &:hover {
            background-color: white;
            color: $secondary-l;
        }

        &:focus {
            background-color: $secondary-l;
            color: white
        }
    }

    &--large {
        font-size: 22px;
        padding: rem-calc(10 30);
        letter-spacing: 2px;
    }
}
