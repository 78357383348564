.project-index {
    &__header {
        background:  $dark-gray  url('../images/project/index-header.jpg') no-repeat 50% 50%;
        background-size: cover;
        color: $white;
        padding: rem-calc(210 0 140);
        text-align: center;

        .project-index--innovative & {
            background-image: url('../images/project/index-innovative-header.jpg');
        }

        .section__title {
            color: $white;
            display: inline-block;
            padding: rem-calc(0 15);
        }
    }

    &__intro {
        padding: rem-calc(50 0);

        .section__title {
            color: $secondary-color;
        }
    }

    &__results {
        background: $blue-light;
        padding: rem-calc(50 0);

        .project-index--innovative & {
            padding-top: 0;
        }
    }

    /* Numbers */

    &__numbers {
        background: $secondary-color url('../images/home/bg-numbers.jpg') no-repeat 50% 50%;
        background-size: cover;
        color: $white;

        &::after {
            background-color: $white;
        }
    }
}
