.tab-filters {
    bottom: 0;
    position: absolute;
    width: 100%;

    @include breakpoint(medium down) {
        background: $white;
        padding: rem-calc(10 10 0);
        position: relative;
    }

    form {
        display: inline-block;
        position: relative;
    }

    .menu-centered {
        border-left: 1px solid $white;

        @include breakpoint(large down) {
            border: 0;

            & > .menu {
                justify-content: left;
            }
        }

        .mega-menu > button {
            border: solid $white;
            border-width: 1px 1px 0 0;
            color: $white;
            font-weight: bold;
            padding: rem-calc(25 40);
            text-transform: uppercase;

            @include breakpoint(large down) {
                padding: rem-calc(25);
            }

            @include breakpoint(medium down) {
                background: $gray;
                border: 0;
                border-radius: rem-calc(60);
                color: $primary-color;
                font-weight: normal;
                margin: rem-calc(0 5 10);
                padding: rem-calc(10 15);
                text-transform: none;
            }
        }

        li {
            &:hover > a {
                @include breakpoint(large up) {
                    background: $white;
                    color: $primary-color;
                }
            }
        }
    }

    .mega-menu.dropdown-pane {
        border: 0;
        box-shadow: 0 15px 20px lighten($black, 20%);
        left: 0 !important; //sass-lint:disable-line no-important
        text-align: left;
        width: 100%;

        @include breakpoint(medium down) {
            height: 100%;
            left: 0 !important; //sass-lint:disable-line no-important
            overflow: auto;
            padding-bottom: rem-calc(80);
            position: fixed;
            top: 0 !important; //sass-lint:disable-line no-important
            width: 100%;
            z-index: 2200;
        }

        .custom-checkbox {
            display: block;
            margin-bottom: rem-calc(10);

            .outer {
                float: left;
            }
        }
    }

    &__title {
        color: $secondary-color;
        font-size: rem-calc(40);
        font-weight: 300;
        line-height: 1em;
        margin-bottom: rem-calc(15);

        @include breakpoint(medium down) {
            margin-bottom: rem-calc(30);
        }
    }

    &__subtitle {
        color: $primary-color;
        font-weight: bold;
        margin-bottom: rem-calc(15);
    }

    &__label {
        display: none;
    }

    .custom-checkbox {
        margin-left: 0;

        .display-inline-block {
            display: inline-block;
        }
    }

    &__cta {
        padding: rem-calc(15 0);
        text-align: center;

        @include breakpoint(medium down) {
            background: $white;
            bottom: 0;
            box-shadow: 0 0 10px lighten($black, 20%);
            left: 0;
            position: fixed;
            width: 100%;
        }

        .button {
            display: inline-block;
        }
    }

    &__close {
        background: none;
        color: $dark-gray;
        font-size: rem-calc(24);
        font-weight: bold;
        line-height: 0;

        @include breakpoint(medium up) {
            display: none;
        }
    }

    &__reset {
        background: none;
        color: $dark-gray;
        float: right;

        @include breakpoint(medium up) {
            display: none;
        }
    }
}

.home {
    @include breakpoint(large up) {
        .tab-filters {
            background: $white;
            padding: rem-calc(10 10 0);
            position: relative;

            form {
                display: block;
            }

            .menu-centered {
                border: 0;

                .mega-menu > button {
                    background: $gray;
                    border: 0;
                    border-radius: rem-calc(60);
                    color: $black;
                    font-weight: normal;
                    padding: rem-calc(15 20);
                    text-align: left;
                    text-transform: none;
                    width: 100%;

                    &::before {
                        color: $primary-color;
                        content: "\EA02";
                        display: block;
                        float: right;
                        font-family: 'font-icon';
                        font-size: rem-calc(18);
                        transform: rotate(90deg);
                    }

                    &[aria-expanded="true"] {
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                        border-top-left-radius: rem-calc(22.5);
                        border-top-right-radius: rem-calc(22.5);
                    }
                }

                li {
                    display: block;
                    margin: rem-calc(0 10 20);
                    position: relative;
                    text-align: left;
                    width: 30%;

                    @include breakpoint(large down) {
                        width: 46%;
                    }

                    &:hover > a {
                        background: $gray;
                        color: $black;
                    }

                }
            }

            .mega-menu.dropdown-pane {
                background: $gray;
                border-bottom-left-radius: rem-calc(22.5);
                border-bottom-right-radius: rem-calc(22.5);
                box-shadow: none;
                padding-bottom: rem-calc(22.5);
                z-index: 100;

                .grid-x {
                    max-height: 50vh;
                    overflow: auto;
                }

                .cell {
                    display: block;
                    width: 100%;
                }
            }

            &__title {
                display: none;
            }

            &__subtitle {
                display: none;
            }

            &__label {
                color: $primary-color;
                display: block;
                font-size: rem-calc(18);
                margin-bottom: rem-calc(5);
                margin-left: rem-calc(20);
            }

            &__cta {
                display: none;
            }
        }
    }
}
