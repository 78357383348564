.home-slideshow {
    .slick-slider {
        margin: 0;
    }

    .home-slide {
        background: no-repeat 50% 50%;
        background-color: $dark-gray;
        background-size: cover;
        padding-bottom: 4vw;

        &::before {
            background: $white;
        }

        &__container {
            margin: 0;
        }

        &__content {
            color: $white;
            height: rem-calc(618);

            @include breakpoint (medium down) {
                height: auto;
                padding: rem-calc(50 15);
            }
        }

        &__cell {
            padding: 1rem;
            background-color: rgba(black, 0.3);

            &--covid19 {
                position: absolute;
                top: 11rem;
                left: rem-calc(80);

                @include breakpoint (medium down) {
                    position: relative;
                    top: 1rem;
                    left: 0;
                }

                @include breakpoint (large up) {
                    left: rem-calc(120);
                }

                @include breakpoint(1350px up) {
                    left: rem-calc(160);
                }

                @include breakpoint(1500px up) {
                    left: rem-calc(180);
                }
            }
        }

        &__title {
            font-size: rem-calc(50);
            font-weight: 700;
            line-height: 1.2em;

            @include breakpoint (medium down) {
                font-size: rem-calc(30);
                text-align: center;
            }

            .padding-text {
                padding-left: rem-calc(60);

                @include breakpoint (medium down) {
                    padding: 0;
                }
            }
        }

        &__logo {
            padding-left: rem-calc(75);
        }

        &__intro {
            padding: rem-calc(25 50 25 60);

            @include breakpoint (medium down) {
                padding: rem-calc(15);
                text-align: center;
            }

            &--covid19 {
                padding: 1rem 0;
                max-width: rem-calc(250);
            }
        }

        &__cta {
            padding-left: rem-calc(60);

            @include breakpoint (medium down) {
                padding: 0;
                text-align: center;
            }
        }
    }

    .orbit-container {
        overflow: visible;
    }

    .orbit-bullets {
        bottom: rem-calc(20);
        left: inherit;
        text-align: right;
        z-index: 20;

        @include breakpoint (medium down) {
            display: none;
        }

        button {
            background: none;
            border: 0;
            border-radius: 0;
            box-shadow: 0 10px 20px rgba($black, .6);
            cursor: pointer;
            height: auto;
            opacity: 1;
            overflow: hidden;
            position: relative;
            width: rem-calc(306);

            span {
                color: $white;
                display: block;
                font-size: rem-calc(22);
                font-weight: bold;
                left: 0;
                line-height: rem-calc(30);
                margin-top: rem-calc(-18);
                position: absolute;
                text-align: center;
                top: 50%;
                width: 100%;
                z-index: 10;
            }
        }
    }
}
