.numbers {
    @include breakpoint(large up) {
        padding-top: rem-calc(150);
    }

    &__container {
        padding: 1rem;
        background-color: rgba(black, 0.3);
    }

    &__number {
        font-size: rem-calc(75);
        font-weight: bold;
        line-height: 1em;

        @include breakpoint (medium down) {
            font-size: rem-calc(50);
        }
    }

    &__label {
        font-size: rem-calc(30);
        line-height: 1em;

        @include breakpoint (medium down) {
            font-size: rem-calc(20);
        }
    }

    &__cta {
        margin-top: rem-calc(30);
        text-align: center;
    }

    .media-object {
        display: table;
        margin-bottom: 0;

        @include breakpoint (medium down) {
            display: block;
            text-align: center;
        }

        &.align-right {
            direction: rtl;
            text-align: right;
            width: 100%;

            @include breakpoint (medium down) {
                float: left;
                text-align: center;
            }
        }

        &-section {
            direction: ltr;
            display: table-cell;
            vertical-align: middle;

            @include breakpoint (medium down) {
                display: block;
            }

            & > img {
                display: inline-block;
                margin: rem-calc(15);
                max-width: 100%;
            }
        }
    }
}
