.contact {
    &__title {
        color: $primary-color;
    }

    &__toggle {
        margin-top: rem-calc(50);
        text-align: center;
    }
}
