.job-view {
    &__content {
        padding: rem-calc(50 0 80);
    }

    &__title {

        .section__title {
            color: $secondary-color;
            margin-bottom: rem-calc(15);
        }
    }

    &__date {
        color: $dark-gray;
        margin-bottom: rem-calc(30);
    }

    .main-content {
        @include breakpoint(medium up) {
            border-right: 1px solid $primary-color;
            padding-right: rem-calc(50);
        }
    }

    &__paragraph {
        margin-bottom: rem-calc(30);

        &:last-child {
            margin-bottom: 0;
        }

        h3 {
            font-weight: bold;
            margin-bottom: rem-calc(30);
        }
    }
}
