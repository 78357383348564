.cookie-bar {
    background: $white;
    bottom: 0;
    display: none;
    left: 0;
    padding: rem-calc(15 0);
    position: fixed;
    text-align: center;
    width: 100%;
    z-index: 100;

    a {
        color: $black;
        text-decoration: underline;
    }

    &__cta {
        &.button {
            cursor: pointer;
            margin: 0 5px;
            padding: rem-calc(10);

            @include breakpoint(medium down) {
                margin: 10px 5px 0 5px;
            }
        }
    }
}
