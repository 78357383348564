/* sass-lint:disable no-duplicate-properties */

/**
* OPEN SANS
**/

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/OpenSans-Light.eot');
    src: url('../fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Light.woff2') format('woff2'),
    url('../fonts/OpenSans-Light.woff') format('woff'),
    url('../fonts/OpenSans-Light.ttf') format('truetype'),
    url('../fonts/OpenSans-Light.svg#OpenSans-Light') format('svg');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    src: url('../fonts/OpenSans-Bold.eot');
    src: url('../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Bold.woff2') format('woff2'),
    url('../fonts/OpenSans-Bold.woff') format('woff'),
    url('../fonts/OpenSans-Bold.ttf') format('truetype'),
    url('../fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/OpenSans.eot');
    src: url('../fonts/OpenSans.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans.woff2') format('woff2'),
    url('../fonts/OpenSans.woff') format('woff'),
    url('../fonts/OpenSans.ttf') format('truetype'),
    url('../fonts/OpenSans.svg#OpenSans') format('svg');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/OpenSans-Semibold.eot');
    src: url('../fonts/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Semibold.woff2') format('woff2'),
    url('../fonts/OpenSans-Semibold.woff') format('woff'),
    url('../fonts/OpenSans-Semibold.ttf') format('truetype'),
    url('../fonts/OpenSans-Semibold.svg#OpenSans-Semibold') format('svg');
}
