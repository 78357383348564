.title-bar {
    background: linear-gradient(to right, #46a1bd 20%, #21819d 80%);
    padding: 0;

    &__brand {
        text-align: left;

        a {
            display: inline-block;
            padding: rem-calc(10);
            background: $white;

            img {
                height: rem-calc(70);
            }
        }
    }

    & .menu-icon {
        height: rem-calc(34);
        margin-right: rem-calc(8);
        width: rem-calc(44);

        &::after {
            border-radius: rem-calc(3);
            box-shadow: 0 14px 0 #fefefe, 0 28px 0 #fefefe;
            height: rem-calc(5);
        }
    }
}

#mobile-menu {
    display: none;

    .accordion {
        text-align: left;
        width: 100%;
    }

    .accordion-title {
        &::before {
            content: none;
        }
    }

    .accordion-content {
        padding: 0;

        a {
            font-size: rem-calc(13);
        }
    }

    a {
        font-weight: bold;
        padding: $accordionmenu-padding;
    }

    li li > a {
        font-weight: normal;
    }
}
