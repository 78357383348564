header {
    color: $white;
    position: relative;
    text-align: center;
    z-index: 2000;

    @include breakpoint(medium down) {
        max-height: 100vh;
        overflow: auto;
    }
}

.visible-desktop {
    @include breakpoint(large down) {
        display: none;
    }
}

.visible-mobile {
    @include breakpoint(xlarge up) {
        display: none;
    }
}

.top-bar {
    position: relative;
    width: 100%;
    z-index: 100;
    background: $white;

    &__button {
        padding: rem-calc(20);

        @include breakpoint(xlarge down) {
            padding: rem-calc(14);
        }
    }

    &__brand {
        padding: rem-calc(10) rem-calc(30);
        background: $white;

        @include breakpoint(xlarge up) {
            width: rem-calc(160);
        }
    }

    &-left {
        float: left;

        img {
        }
    }

    &-right {
        background: #21819d;
        height: rem-calc(110);
        display: flex;
        align-items: center;
        flex: none !important;
        float: right;
        position: relative;
        padding: 0 30px 0 0;

        @include breakpoint(xlarge down) {
            height: rem-calc(90);
        }

        @include breakpoint(large down) {
            height: rem-calc(86);
        }

        img {
            height: rem-calc(110);
            max-width: none;

            @include breakpoint(xlarge down) {
                height: rem-calc(90);
            }

            @include breakpoint(large down) {
                height: rem-calc(86);
            }
        }
    }

    .menu-centered {
        background-color: $primary-color;
        background-image: linear-gradient(to right, #46a1bd 20%, #21819d 80%);
        height: rem-calc(110);
        width: 100%;

        @include breakpoint(xlarge down) {
            height: rem-calc(90);
        }

        @include breakpoint(large down) {
            height: rem-calc(86);
        }
    }

    .dropdown.menu {
        position: inherit;

        > li {
            padding: rem-calc(0 0 0 25);

            @include breakpoint(xlarge down) {
                padding: rem-calc(0 0 0 12);
            }

            &::after {
                border-left: 1px solid $white;
                content: "";
                height: rem-calc(30);
                position: relative;
                margin-top: 38px;
                margin-left: rem-calc(25);

                @include breakpoint(xlarge down) {
                    margin-top: 28px;
                    margin-left: rem-calc(12);
                }
            }

            &:last-child {
                &::after {
                    content: none;
                }
            }

            & > a {
                border: solid transparent;
                border-width: rem-calc(4px 0 5px);
                color: $white;
                font-size: rem-calc(13);
                font-weight: bold;
                letter-spacing: 2px;
                padding: rem-calc(44 0);
                text-transform: uppercase;

                @include breakpoint(xlarge down) {
                    padding: rem-calc(34 0);
                }

                @include breakpoint(large down) {
                    padding: rem-calc(32 0);
                }

                &:hover {
                    border-bottom-color: $white;
                }
            }
        }

        .mega-menu {
            z-index: 101;

            li {
                justify-content: left;
                padding: 0;
                text-align: left;
            }

            .button {
                display: inline-block;
                margin-top: rem-calc(30);
            }
        }
    }


    &__title {
        color: $primary-color;
        font-size: rem-calc(30);
    }

    &__intro {
        font-size: rem-calc(15);
        margin-bottom: rem-calc(10);
    }

    &__cta {
        margin-top: rem-calc(-10);
        position: relative;
        text-align: center;
    }

    .gift-link {
        bottom: rem-calc(20);
        color: $cultureh;
        font-size: rem-calc(14);
        font-weight: bold;
        left: rem-calc(45);
        position: absolute;
        text-align: center;
        text-transform: uppercase;

        @include breakpoint(xlarge down) {
            bottom: rem-calc(10);
            font-size: rem-calc(13);
            left: rem-calc(34);
        }

        @include breakpoint(large down) {
            bottom: rem-calc(10);
            left: rem-calc(30);
        }

        i {
            font-size: rem-calc(12);
        }

        &:hover {
            color: $secondary-color;
        }
    }
}

header {
    .dropdown-pane {
        border: 0;
        box-shadow: 0 10px 15px -10px  $black;
        color: $black;
        display: block !important; //sass-lint:disable-line no-important
        left: 0 !important; //sass-lint:disable-line no-important
        margin: 0 auto !important; //sass-lint:disable-line no-important
        padding: rem-calc(30 0);
        right: 0 !important; //sass-lint:disable-line no-important
        text-align: left;
        width: 100%;
        z-index: 100;

        .menu {
            a {
                color: $black;
                font-size: rem-calc(13);
                font-weight: bold;
                line-height: 1.2em;
                padding: rem-calc(10 0);
                text-transform: uppercase;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
