body,
html {
    height: auto;
    min-height: 100%;
}

a {
    transition: color 300ms ease-out;
}

main {
    position: relative;
}

.diagonal {
    overflow: hidden;
    position: relative;

    &::before,
    &::after {
        background: $blue-light;
        box-shadow: 0 0 30px $black;
        height: 20vw;
        left: -25%;
        margin-top: -10vw;
        position: absolute;
        transform-origin: 0 0;
        width: 150%;
        z-index: 10;
    }

    &--bottom {
        padding-bottom: 12vw;

        &::before {
            content: '';
            top: 100%;
            transform: rotate(3deg);
        }
    }

    &--top {
        padding-top: 12vw;

        &::after {
            content: '';
            top: 0;
            transform: rotate(-3deg);
        }
    }

    &--nopadding {
        padding: 0;
    }

    &--white {
        &::before,
        &::after {
            background: $white;
        }
    }

    &--top-blue {
        &::after {
            background: $blue-light;
        }
    }

    &--top-white {
        &::after {
            background: $white;
        }
    }

    &--bottom-blue {
        &::before {
            background: $blue-light;
        }
    }

    &--bottom-white {
        &::before {
            background: $white;
        }
    }
}

.title-centered {
    margin: rem-calc(30 0);

    hr {
        background: $primary-color;
        border: 0;
        height: 1px;
        margin: 0;
        padding: 0;
    }

    .shrink {
        color: $primary-color;
        font-weight: bold;
        padding: rem-calc(0 15);
    }
}

.section__main-title {
    height: 219px;

    @include breakpoint(medium up) {
        height: 320px;
    }

    display: flex;
    justify-content: center;
    align-items: center;
}

.section__title {
    color: $primary-color;
    display: block;
    font-size: rem-calc(42);
    font-weight: 300;
    margin-bottom: rem-calc(45);
    text-align: left;

    &.orange {
        color: $secondary-color;
    }

    @include breakpoint (small down) {
        font-size: rem-calc(26);
    }

    & > span {
        display: block;
        line-height: 1em;
    }

    .small {
        font-size: rem-calc(32);
        font-weight: 300;
    }

    .big {
        font-size: rem-calc(70);
        margin-bottom: rem-calc(10);

        @include breakpoint (xlarge down) {
            font-size: rem-calc(50);
        }

        @include breakpoint (small down) {
            font-size: rem-calc(40);
        }
    }

    .padding-left {
        @include breakpoint (medium up) {
            padding-left: rem-calc(80);
        }
    }
}

.primary-color {
    color: $black;
}

.secondary-color {
    color: $black;
}

.tertiary-color {
    color: $blue-darker;
}

.error {
    color: $warning-color;
}

.bg-blue {
    background-color: $blue-light;
}

.bg-white {
    background-color: $white;
}
