.search-mobile {
    padding: rem-calc(10);

    &__title {
        margin: rem-calc(15 0);

        .section__title {
            color: $secondary-color;
            margin-bottom: 0;
        }
    }

    &__field {
        color: $primary-color;
        font-weight: bold;
        margin-bottom: rem-calc(15);
    }

    &__filter {
        display: inline-block;
        margin: rem-calc(0 5 0 0);

        &--button {
            a {
                background: $gray;
                border: 0;
                border-radius: rem-calc(40);
                font-size: rem-calc(14);
                padding: rem-calc(10 20);
            }
        }

        &--modal {
            background: $white;
            display: none;
            height: 100%;
            left: 0;
            overflow: auto;
            padding: rem-calc(15);
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 2000;

            &.active {
                display: block;
            }

            .custom-checkbox {
                margin-bottom: rem-calc(15);
                margin-left: 0;

                .outer {
                    float: left;
                }
            }
        }
    }
}
