.pagination {
    margin: rem-calc(30 0 60);
    text-align: center;

    &-previous,
    &-next {
        &,
        a {
            font-weight: bold;
            text-transform: uppercase;
        }

        a {
            color: $primary-color;

            &:hover {
                color: $secondary-color;
            }
        }
    }

    &-previous {
        float: left;
    }

    &-next {
        float: right;
    }

    &-item {
        border-right: 1px solid $black;
        line-height: 1em;

        &.last {
            border: 0;
        }

        a {
            color: $black;
        }

        &.current {
            font-weight: bold;
        }
    }
}
