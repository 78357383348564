.cultureh-index {
    &__header {
        background:  $dark-gray url('../images/culture_h/bg-header.jpg') no-repeat 50% 50%;
        background-size: cover;
        color: $white;
        padding-top: rem-calc(120);

        &::before {
            padding-bottom: 16vw;
            transform: rotate(-3deg);
        }

        .grid-container {
            margin-bottom: rem-calc(60);
        }

        .section__title {
            color: $white;
            margin-top: rem-calc(15);
            padding: rem-calc(0 15);
        }
    }

    &__mecenat {
        background: $blue-light;
        color: $cultureh;
        margin-top: -6vw;
        position: relative;
        z-index: 1000;

        .title-mecene {
            color: $primary-color;
            font-weight: bold;
            margin: rem-calc(15 0);
        }

        img {
            margin: rem-calc(30 0);
        }

        h3 {
            color: $cultureh;
            margin-top: rem-calc(30);
        }
    }

    &__bottom {
        background: url('../images/culture_h/bg-gift.jpg') no-repeat 50% 100%;
        background-color: $cultureh;
        background-size: 100% auto;
        box-shadow: inset 0 -25px 25px -25px darken($cultureh, 10%);
        color: $white;
        padding: rem-calc(200 0 100);

        &::after {
            transform: rotate(3deg);
        }

        .section__title {
            color: $white;
        }
    }

    &__project {
        .odd {
            margin-top: rem-calc(30);
        }
    }

    &__gift {
        h3 {
            font-weight: bold;
            margin-bottom: rem-calc(10);
        }

        a {
            &:not(.button) {
                color: $white;
                text-decoration: underline;
            }
        }
    }
}

/* Mouse Icon */

@keyframes scroll-ani {
    0% {
        opacity: 0;
        top: 30%;
    }

    15% {
        opacity: 1;
        top: 30%;
    }

    30% {
        opacity: 1;
        top: 60%;
    }

    45% {
        opacity: 0;
        top: 60%;
    }

    100% {
        opacity: 0;
        top: 30%;
    }
}

.mouse-scroll {
    color: $white;
    display: inline-block;
    font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: rem-calc(13);
    font-weight: normal;
    letter-spacing: rem-calc(2);
    line-height: rem-calc(18);
    margin-top: rem-calc(30);
    overflow: hidden;
    text-decoration: none;
}

.mouse-scroll .mouse {
    border: 2px solid $white;
    border-radius: rem-calc(12);
    box-sizing: border-box;
    display: block;
    height: rem-calc(37);
    margin: 0 auto rem-calc(10);
    position: relative;
    width: rem-calc(24);
}

.mouse-scroll .mouse .mouse-movement {
    animation: scroll-ani 4s linear infinite;
    background: $white;
    border-radius: 100%;
    display: block;
    height: rem-calc(4);
    left: 50%;
    margin: rem-calc(-3 0 0 -2);
    position: absolute;
    top: 29%;
    width: rem-calc(4);
}

.cell-logo {
    border-radius: 50%;
    overflow: hidden;
    margin: rem-calc(30 0);

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        background-color: white;
        margin: 0;
    }
}
