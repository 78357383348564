.block-highlight {
  background-size: cover;
  background-position: center;
  padding: rem-calc(280) 0;

  @include breakpoint(medium down) {
    padding: rem-calc(175) 0;
  }

  @include breakpoint(small down) {
    padding: rem-calc(125) 0;
  }

  &__overlay {
    content: "";
    background-color: $secondary-l;
    opacity: 0.5;
    filter: contrast(175%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__content {
    position: relative;
    z-index: 2;
  }

  &__text {
    font-size: rem-calc(48);
    font-weight: 300;
    color: $white;
    text-align: center;

    @include breakpoint(medium down) {
      font-size: rem-calc(36);
    }

    @include breakpoint(small down) {
      font-size: rem-calc(32);
    }
  }
}
