.institutions {
    &__header {
        background:  $dark-gray url('../images/institutions/index-header.jpg') no-repeat 50% 50%;
        background-size: cover;
        color: $white;
        padding: rem-calc(150 0 120);
        position: relative;
        text-align: center;

        @include breakpoint(medium down) {
            padding: rem-calc(50 0 0);
        }

        .section__title {
            color: $white;
            display: inline-block;
            padding: rem-calc(0 15);

            @include breakpoint(medium down) {
                margin-bottom: rem-calc(100);
            }
        }
    }

    &__results {
        background: $blue-light;
        padding: rem-calc(50 0);

        .section__title {
            color: $secondary-color;
        }
    }
}

.institution-index {
    .home-form__submit {
        display: none;
    }
}
