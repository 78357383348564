.news-index {
    background: $blue-light;

    &__header {
        background:  $dark-gray  url('../images/news/slide-news.jpg') no-repeat 50% 50%;
        background-position: top center;
        background-size: cover;
        color: $white;
        padding: rem-calc(150 0 100);
        text-align: center;

        .section__title {
            color: $white;
            display: inline-block;
            padding: rem-calc(0 15);
            text-align: center;

            span {
                margin-bottom: rem-calc(50);
            }
        }

        .section__banner-text {
            max-width: 700px;

            @include breakpoint(small down) {
                max-width: 350px;
            }
        }
    }

    &__form {
        padding: rem-calc(30 0 15);

        @include breakpoint(small down) {
            display: block;
            padding: rem-calc(0 0 15);
            text-align: left;
        }

        label {
            &[for="news_filter_form_institution"] {
                display: none;
            }
        }

        select {
            float: right;
            margin-top: rem-calc(10);
            max-width: rem-calc(300);

            @include breakpoint(small down) {
                float: none;
            }
        }

        .custom-radio {
            margin-bottom: rem-calc(10);

            @include breakpoint(small down) {
                display: block;
                margin: rem-calc(0 0 10);
            }
        }
    }

    &__count {
        clear: both;
        padding: rem-calc(15 0 5);

        @include breakpoint(small down) {
            text-align: left;
        }
    }

    &__results {
        padding-top: rem-calc(50);

        .section__title {
            color: $secondary-color;
        }
    }
}
