.orbit {
    position: relative;

    button {
        &:focus {
            cursor: pointer;
            outline: auto;
        }
    }

    &-bullets {
        bottom: rem-calc(80);
        left: 0;
        position: absolute;
        width: 100%;

        button {
            border: 3px solid $white;
            box-shadow: 0 0 1px $light-gray;
        }
    }

    &-controls {
        button {
            background: $orbit-control-background-hover;
            color: $primary-color;
            font-size: rem-calc(60);
            line-height: 0;
        }
    }
}
