.breadcrumb {
    background: rgba($white, .9);
    left: 0;
    margin: 0;
    padding: rem-calc(8 0);
    position: fixed;
    width: 100%;
    z-index: 100;

    @include breakpoint(large down) {
        position: absolute;
    }

    li {
        font-weight: bold;
    }

    .access-typo {
        float: right;
        line-height: 1em;

        a {
            color: $black;
            display: inline-block;
            font-weight: bold;
            margin: rem-calc(0 10);
            vertical-align: middle;
        }
    }
}
